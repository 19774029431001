import React, { useEffect, useState, useRef, useContext, useCallback, useMemo } from 'react';
import { Row, Col, Dropdown, Form, Modal, ToggleButton, ButtonGroup, Spinner, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import APIServicenew from '../utils/APIGeneralService';
import { formateDate, getCookies, setCookies } from '../utils/Helper';
import { UserContext } from '../context/UserContextProvider';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import moment from 'moment';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { defaults } from 'chart.js';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

const PujaSuggestionList = () => {
  document.title = 'Puja Recommendation ';
  const permissioncontext =usePermissionData("pujaSuggestionList")
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalChats, setTotalChats] = useState('');
  const token = getCookies('accessToken');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [allSuggestion, setAllSuggestion] = useState([]);
  const [allSuggestionDownload, setAllSuggestionDownload] = useState([]);
  const [puja, setPuja] = useState([]);
  const selectedPuja = useRef(null);
  const [notesData, setNotesData] = useState([]);
  const [userForNote, setUserForNote] = useState();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [note, setNote] = useState('');
  const [loader, setLoader] = useState(false);
  const [flag, setFlag] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(40);
  const [countData,setCountData]=useState()
  const [type, setType] = useState('');
  const handleTypeChange = e => {
    setType(e.target.value);
  };

  const [notes, setNotes] = useState('');
  const handleNotesChange = e => {
    setNotes(e.target.value);
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // const [inputState, setInputState] = useState({
  //   startdate: '',
  //   enddate: '',
  // });

  // const handleInputChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, startdate: formattedDate }));
  // };
  // const handleEndDateChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, enddate: formattedDate }));
  // };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };
  


  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };
 



  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSelectedRange("");
    setPageIndex(0);
    setOffset(0);
    setFlag(true);
    setType('');
    setNotes('');
    setSkip(0);
  };

  const handleDownload = data => {
    for (let item of data) {
      if (item.userName) {
        item[' Name'] = item.userName;
      } else {
        item[' Name'] = '_';
      }
      if (item.maskUserNumber) {
        item['Customer Number'] = item.maskUserNumber;
      } else {
        item['Customer Number'] = '_';
      }

      // if(item.maskUserNumber){
      //   item['Customer Number']=item.maskUserNumber
      // }else{
      //   item['Customer Number']= '_'
      // }
      if (item.astrologerName) {
        item['Astrologer Name'] = item.astrologerName;
      } else {
        item['Astrologer Name'] = '_';
      }
      if(item.maskAstrologerNumber){
        item['Astrologer Number']=item.maskAstrologerNumber
      }else{
        item['Astrologer Number']= "_"

      }
      if (item.pujaNames) {
        item['Puja Name'] = item.pujaNames.map(puja => puja.name).join(', ');
      }

      if (item.type) {
        item['Type'] = item.type;
      }

      if (item.createdAt) {
        item['Date '] = moment(item?.createdAt).format('DD MMM, YYYY hh:mm A');
      }

      delete item._id;
      delete item.userNumber;
      delete item.createdAt;
      delete item.userName;
      delete item.maskUserNumber;
      delete item.astrologerName;
      delete item.astrologerNumber;
      delete item.maskAstrologerNumber;
      delete item.pujaNames;
      delete item.isSend;
      delete item.userId;
      delete item.astrologerId;
      delete item.isDeleted;
      delete item.pujaId;
      delete item.orderId;
      delete item.updatedAt;
      delete item.type;
      delete item.__v;
    }
    let csv = Papa.unparse(data, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveBlob(csvData, 'UserList.csv');
    else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.download = 'PujaRecommendation.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Puja Name',
      Cell: e => {
        if (Array.isArray(e.row.original?.pujaNames)) {
          return (
            <div>
              {e.row.original.pujaNames.map((puja, index) => (
                <div key={index}>
                  <Link to={`/shared-puja?pujaId=${puja.pujaId}`} state={{permissioncontext:permissioncontext}}>{puja.name}</Link>
                </div>
              ))}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      Header: 'Name',
      Cell: e => {
        return <span>{e.row.original?.userName ?? '_'}</span>;
      },
    },
    {
      Header: 'Contact Number',
      Cell: e => {
        return <span>{e.row.original?.maskUserNumber ?? '_'}</span>;
      },
    },
    {
      Header: 'Astrologer Name',
      Cell: e => {
        return <span>{e.row.original?.astrologerName ?? '_'}</span>;
      },
    },
    {
      Header: 'Type',
      Cell: e => {
        return <span>{e.row.original?.type ?? '_'}</span>;
      },
    },
    {
      Header: 'Astrologer Number',
      Cell: e => {
        return <span>{e.row.original?.maskAstrologerNumber ?? '_'}</span>;
      },
    },
    {
      Header: 'Notes',
      Cell: e => {
        return (
          <h5>
            <input
              type='button'
              className={!permissioncontext.add?"Et-btn notes-btn disabled-btn":'Et-btn notes-btn '}
                  disabled={!permissioncontext.add}
              value='Notes'
              onClick={() => {
                setUserForNote(e.row.original);
                handleUserNotes(e.row.original.userId);
              }}
            />
          </h5>
        );
      },
    },
    {
      Header: 'Date',
      Cell: e => {
        return <span>{moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A')}</span>;
      },
    },
  ]);

  const handleNotesAdd = async () => {
    if (!note || note === '') {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.userId,
        id: userForNote?.pujaNames[0]?.pujaId,
        source: 'Marketing-Puja-Suggestion',
        name: userForNote?.pujaNames[0]?.name,
        type: adminUser?.adminType,
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      const res = await APIServices.post(`userNotes/userNoteMessage`, payload, headers);

      if (res && res?.data?.status === true) {
        toast.success('Note added successfully');

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote('');
        setLoader(false);
      } else {
        toast.error(res?.data?.message);

        setLoader(false);
      }
      setLoader(false);
    }
  };

  const handleChange = () => {
    setError(false);
  };

  const handleCloseModal = () => {
    setShow(false);
    setError(false);
  };

  const handleUserNotes = async id => {
    const res = await APIServices.get(`userNotes/getDetailById/${id}`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log('error');
      }
    }
  };

  // const fetchData = async () => {
  //   let response = await APIServices.get(`astroPooja/getAll`,headers);
  //   if (response?.status === 200) {
  //     let apiData = response?.data;
  //     if (apiData?.status) {
  //       setPuja(apiData.Listdata);
  //     }
  //   }
  // };

  const suggestionsDownload = async () => {
    const res = await APIServices.get(
      `/astroPooja/poojasuggestionList?startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&type=${type}&pujaId=&isNotes=${notes}`,
      headers
    );
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        handleDownload(resData?.Listdata);
      }
    }
  };

  useEffect(() => {
    fetchData(offset, perPage, pageIndex);
    handleCountData()
  }, [flag]);




  const handleCountData = async (id) => {
    const res = await APIServices.get(`lead/getPujaCountSuggestion`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };

  const fetchData = useCallback(
    async (offset, perPage, index) => {
      try {
        let res = await APIServices.get(
          `/astroPooja/poojasuggestionList/${offset}/${perPage}?startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&type=${type}&pujaId&isNotes=${notes}`,
          headers
        );
        let data = res?.response?.data;

        if (res && res.status === 200) {
          let resData = res.data;
          if (resData && resData.status) {
            let newData = [...resData.Listdata];

            setUsers(newData);
            setTotalChats(resData.totalCount);
            setPageCount(Math.ceil(resData?.totalCount / perPage));
            setFlag(false);
          }
        } else {
          if (data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setOffset(offset);
      setPerPage(perPage);
      setPageIndex(index);
    },
    [pageIndex, offset, startDate, endDate, type, notes]
  );

    const handleFilterClick = () => {
    if (
       
      perPage ||
      offset ||
      pageIndex ||
      startDate ||
      endDate ||
      type || notes
    ) {
      fetchData(
        offset,
        perPage,
        0,
        startDate,
        endDate,
        type,notes
        
      );
    }
  };

  return (
    <>
    {
      permissioncontext.view?
      <>
<div className='gita_quote_heading'>
        <div className='container'>
          <div className='overflow-x-auto'>
            <div className='counter-wrapper d-flex align-items-center justify-content-between'>
                <h4 className='hd-list-f font_20 left-q counter-top'>
                  Puja Recommendation <small>{totalChats}</small>
                </h4>
                <h4 className="hd-list-f font_20 left-q counter-top">
                  Today Chat/Call<small>{countData?.usersAddedToday}/{countData?.usersAddedcallToday}</small>
                  </h4>
                  <h4 className="hd-list-f font_20 left-q counter-top">
                    This Week Chat/Call <small>{countData?.usersAddedThisWeek}/{countData?.usersAddedcallThisWeek}</small>
                  </h4>
                  <h4 className="hd-list-f font_20 left-q counter-top">
                    This Month Chat/Call <small>{countData?.usersAddedThisMonth}/{countData?.callPujaThisMonth}</small>
                  </h4>
            </div>
          </div>
        </div>

      </div>
      <div className='chat_window'>
        <Row className='mlist p-20'>
          <div class='chat_search_box '>

            <Row className='align-items-end'>
              <Col md={6} lg={4} className="mb-3" >
                <Form.Label>Type</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu mt-0 text-capitalize  w-50'
                  onChange={e => {
                    handleTypeChange(e);
                  }}
                  value={type}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  <option className='text-capitalize' value='chat'>
                    Chat
                  </option>
                  <option className='text-capitalize' value='call'>
                    Call
                  </option>
                </Form.Select>
              </Col>

              {/*            
              <Col md={2}>
                <Form.Label>Puja</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={(e)=>{handlePujaChange(e); setSkip(0)}}
                  value={selectedPuja.current}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  {puja.map((item, index) => {
                    return (
                      <>
                        <option className='text-capitalize' value={item._id}>
                          {item.title}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
              </Col> */}
              {/* <Col md={3} className=''>
                <Form.Label>From Date</Form.Label>

                <DatePicker
                  placeholderText={'From date'}
                  className='form-control form-ch fil-date-pick '
                  name='startdate'
                  selected={inputState.startdate ? new Date(inputState.startdate) : null}
                  onChange={date => {
                    handleInputChange(date);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col>
              <Col md={3} className=''>
                <Form.Label>To Date</Form.Label>

                <DatePicker
                  placeholderText={'To date'}
                  className='form-control form-ch fil-date-pick '
                  name='enddate'
                  selected={inputState.enddate ? new Date(inputState.enddate) : null}
                  onChange={date => {
                    handleEndDateChange(date);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col> */}

              <Col md={6} lg={4} className="mb-3" >
              <Form.Label>Search By Recommendation Date</Form.Label>
                 <div className="date-range-wrapper">  
                    <div className='select-icon-wrapper'>   
                        <select
                        className="select-range"
                          value={selectedRange}
                          onChange={(e) => handleDateRange(e.target.value)}
                        >
                          <option value="disabled">Select Date Range</option>
                          <option value="today">Today</option>
                          <option value="yesterday">Yesterday</option>
                          <option value="thisWeek">This Week</option>
                          <option value="last7Days">Last 7 Days</option>
                          <option value="thisMonth">This Month</option>
                          <option value="lastMonth">Last Month</option>
                          <option value="customDate">Custom Date</option>
                        </select>
                        <img src='/images/down-arrow.png' className='select-icons'/>
                    </div>

                        {selectedRange === "customDate" && (
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={(update) => {
                              const [start, end] = update;
                              setStartDate(start);
                              setEndDate(end);
                            }}
                            // isClearable={true}
                            placeholderText="Select custom date range"
                            dateFormat="yyyy-MM-dd"
                          />
                        )}
                    </div>
              </Col>
              <Col md={6} lg={4} className="mb-3" >
                <Form.Label> Notes</Form.Label>

                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize mt-0 w-50 hd-cr  form-ch'
                  onChange={e => {
                    handleNotesChange(e);
                  }}
                  value={notes}
                >
                  <option className='fw-bold'> All</option>
                  <option className='text-capitalize' value='yes'>
                    Yes{' '}
                  </option>
                  {/* <option className='text-capitalize' value='no'> No </option> */}
                </Form.Select>
              </Col>

              <Col md={6} className='mb-3 mb-1 d-flex'>
                <Button
                  className='as_btn ms-1'
                  variant='primary'
                  onClick={handleFilterClick}
                >
                  Filter
                </Button>
                <Button className='as_btn ms-2 reset-btn' variant='primary' onClick={handleReset}>
                  Reset
                </Button>
                {adminUser?.adminType === "super_admin" ? (
                  <Button
                    className='as_btn ms-2 download-btn'
                    variant='primary'
                    onClick={() => {
                      suggestionsDownload();
                    }}
                  >
                    Download File
                  </Button>
                ) : "" }
              </Col>
            </Row>
          </div>
          
        </Row>

        <div className='overflow-scroll puja-recommendation'>
        {users && users.length > 0 ? (
            <DynamicDataTableWithCount
              columns={columns}
              data={users}
              totalCount={pageCount}
              initialState={{
                pageIndex: pageIndex,
                pageSize: perPage,
              }}
              fetchData={fetchData}
              forcePage={offset / perPage}
            />
          ) : (
            <h2 className='no_records'>Records not Available</h2>
          )}
      </div>
      </div></>:
    <Notauthorized />
    }
      
      <Modal
      centered
        show={show}
        size='md'
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='aa d-flex'>
            <span className='me-3'> Name :- {userForNote?.userName} </span>
            <span> Contact Number :- {userForNote?.maskUserNumber} </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className='my-3' controlId='formAartiInput'>
                <Col sm='12'>
                  <Form.Control
                    as='textarea'
                    name='content'
                    placeholder='Type here...'
                    value={note}
                    style={{ height: 100 }}
                    onChange={e => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? <p className='text-danger'>Please enter your note</p> : ''}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className='py-2 px-0'>
            {loader ? (
              <Button variant='primary' className='as_btn'>
                Processing...
              </Button>
            ) : (
              <Button variant='primary' className='as_btn' onClick={handleNotesAdd}>
                Add
              </Button>
            )}
            <Button variant='secondary' className='as_btn reset-btn' onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>

          <h6 className='aa mt-2'> Notes History :-</h6>

          {notesData && notesData?.notes?.length > 0 ? (
            <>
              {notesData?.notes &&
                notesData?.notes.length > 0 &&
                notesData?.notes?.map((item, index) => (
                  <>
                    <Row>
                      {item?.source && item?.source != '' ? (
                        <Col xs={5}>
                          <span className='aa'>Source :- </span>
                          <span className='item-msg'>{item.source ? item.source : ''} </span>
                        </Col>
                      ) : (
                        ''
                      )}
{item?.source && item?.source !== "" && (
    (item.source === "Leads-Chat-Orders" || item.source === "Leads-Call-Orders") ? (
        item?.sourcename && item.sourcename !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chat-Orders") ? (
                    <span className="aa">Chat Id :- </span>
                ) : (
                    <span className="aa">Call Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Chadhawa-List" || 
         item.source === "Leads-Manokamna-List" || 
         item.source === "Marketing-Chadhawa-List" ||
         item.source === "Marketing-Manokamna-List") ? (
        item?.sourceid && item.sourceid !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chadhawa-List" || item.source === "Marketing-Chadhawa-List") ? (
                    <span className="aa">Chadawa Id :- </span>
                ) : (
                    <span className="aa">Manokmana Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourceid?.length <= 50 ? item?.sourceid : `${item?.sourceid?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Puja-Booking" || 
         item.source === "Leads-Puja-Suggestion" ||
         item.source === "Marketing-Puja-Booking" ||
         item.source === "Marketing-Puja-Feedback" ||
         item.source === "Marketing-Puja-Suggestion") ? (
        item?.sourcename && item.sourcename !== "" && item?.sourceid && (
            <Col xs={7}>
                <span className="aa">Puja Name :- </span>
                <span className="item-msg">
                    <Link to={`/shared-puja?pujaId=${item?.sourceid}`} state={{permissioncontext:permissioncontext}}>
                        {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                    </Link>
                </span>
            </Col>
        )
    ) :
    item.source === 'Marketing-Reports-Inquiry' ?
    <Col xs={7}>
          <span className='aa'>Reports Name :- </span>
          <span className='item-msg'>
            
              {item?.sourcename?.length <= 50
                ? item?.sourcename
                : `${item?.sourcename?.substring(0, 40)}...`}
          </span>
        </Col>: null
)}

                    </Row>
                    <Row className='notes-item'>
                      <Col xs={9}>
                        <span className='item-name aa'>Note :-</span>
                        <span className='item-msg'>{item?.message}</span>
                      </Col>
                      <Col xs={3}>
                        <div className='text-end d-flex flex-column'>
                          <span className='text-primary item-name aa'>{item?.responderName || item?.adminName}</span>
                          <span className='item-text'>
                            {item?.createdAt ? moment(item?.createdAt).format('DD MMM, YYYY hh:mm A') : ''}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </>
                ))}
            </>
          ) : (
            <>
              <h4 className='text-center text-secondary'>Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default PujaSuggestionList;
