import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Card, Container, Form, Button, Row, Col, Tab, Nav, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import { UserContext } from '../../context/UserContextProvider';
import moment from 'moment';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies } from '../../utils/Helper';
import bimg from '../../imgs/default.jpg';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import usePermissionData from '../../components/Customhook/custompermission';
import Notauthorized from '../../components/Notauthorized';

const ReportList = () => {
  document.title = 'Report List';
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const permissioncontext =usePermissionData("reportsListing")
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [price, setPrice] = useState('');
  const [sort, setSort] = useState('');
  const [slug, setSlug] = useState('');

  const [showdt,setShowdt ] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [contentType, setContentType] = useState('english');

  const handleCloseDt = () => setShowdt(false);
  const handleShowDt = id => {
    setSelectedId(id?._id);
    setShowdt(true);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  const getEditaData = async (data) => {
    if(contentType === "hindi"){
    navigate(`/reportsAddEdit?editDataId=${data?.parentId}`);
    }else{
      navigate(`/reportsAddEdit?editDataId=${data?._id}`);
    }
  };

  const handleDelete = async () => {
    const response = await APIServices.delete(`reportservices/findByIdAndremove/${selectedId}`, headers);
    if (response?.data?.status === true) {
      toast.success(response?.data.message);
      setShowdt(false);
      handleCloseDt()
      fetchReportList()
      
    } else {
      console.log("Something went Wrong")
    }
  };

  const handleReset = () => {
    setTitle('');
    setSlug('');
    setStatus('');
    setPrice('')
    setSort('');
    setContentType('english')
    setFlag(true)
  };

  const handleStatusUpdate = async item => {
    const formData = new FormData();
    var datas = item?.status === true ? false : true;
    let id = item?._id;
    formData.append('status', datas);

   const res = await APIServices.put(`reportservices/updateStatus/${id}`, formData, headers)
   if(res?.data?.status === true){
     fetchReportList();
   }
   else{
     console.log("error..")
   }
  };
 

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Image',
      Cell: e => {
        return (
          <img
            src={`${e.row.original.image ? e.row.original.image : bimg}`}
            alt='img'
            className='quotes_table_preview_image'
          />
        );
      },
    },
    {
      Header: 'Title',
      Cell: e => {
        return <span>{e.row.original.title ?? '_'}</span>;
      },
    },
    {
      Header: 'Heading',
      Cell: e => {
        return <span>{e.row.original.heading ?? '_'}</span>;
      },
    },
    {
      Header: 'INR Price',
      Cell: e => {
        return <span>{"₹"}{e.row.original.inr_price ?? '_'}</span>;
      },
    },
    {
      Header: 'USD Price',
      Cell: e => {
        return <span>{"$"}{e.row.original.usd_price ?? '_'}</span>;
      },
    },
    {
      Header: 'Status',
      Cell: e => {
        if (e.row.original.status === true) {
          return (
            <h5>
              <input type='button' className={!permissioncontext.edit?"_active disabled-btn":'_active '} disabled={!permissioncontext.edit} value='Active' onClick={() => handleStatusUpdate(e.row.original)} />
            </h5>
          );
        } else {
          return (
            <h5>
              <input type='button'  className={!permissioncontext.edit?"_inactive disabled-btn":'_inactive '}  disabled={!permissioncontext.edit} value='Inactive' onClick={() => handleStatusUpdate(e.row.original)} />
            </h5>
          );
        }
      },
    },
    {
      Header: 'Created At',
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>
          </>
        );
      },
    },
    {
      Header: 'Action',
      Cell: e => {
        if (e.row.original._id) {
          
          return (
            <>
            <div className='d-flex'>
                <Button type="button" disabled={!permissioncontext.edit} className='Et-btn edit-btn1' onClick={()=>{getEditaData(e.row.original)}}>Edit</Button>
                <Button type="button" disabled={!permissioncontext.delete} className='ms-2 Dt-btn' onClick={() => { handleShowDt(e.row.original)}}>Delete</Button>
            </div>
            </>
          );
        }
      },
    },
   
  ]);

  useEffect(() => {
    fetchReportList(skip, limit, pageIndex);
  }, [flag ]);

  const fetchReportList = useCallback(
    async (skip, limit, index) => {
      try {
        const response = await APIServices.get(`reportservices/allreportlist/${contentType}/${skip}/${limit}?status=${status}&title=${title}&slug=${slug}` ,headers);
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount)
          setTableData(response.data?.Listdata);
          setFlag(false)
          window.scrollTo({ top: 0 });
        }else{
          if(response?.response?.data?.status == 4){
            logout()
           }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, title, status, sort, price,contentType, slug]
  );

  const handleFilterClick = () => {
    if (title || status || price || sort || slug || contentType ) {
      fetchReportList(skip, limit, 0, title, status, price,contentType, sort, slug);
    }
  };

  return (
    <div>
      {
        permissioncontext.view?
        <div className='App'>
        <div className='gita_quote_heading'>
          <div className='container'>
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q '>
                {' '}
                Report List <small>({allDataCount})</small>
              </h4>
              <button className='as_btn' disabled={!permissioncontext.add} onClick={()=> navigate("/reportsAddEdit")}>
                Add Report
              </button>
            </div>
          </div>
        </div>

        <Container className='' fluid>
          <div className='feed_area box-shadow my-3 p-20 br-6'>
            <div className='chat_search_box py-0'>
              <div className='chat-search-field'>
                <Row>
                    <Form>
                      <Form.Group as={Row} className='align-items-end' controlId=''>
                        <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className=''>Title</Form.Label>
                          <Form.Control
                            className='form-ch'
                            name='title'
                            type='search'
                            placeholder='Search By Title'
                            value={title}
                            onChange={(e) => {setTitle(e.target.value);setSkip(0)}}
                          />
                        </Col>
                        {/* <Col lg={3} md={6} className='mb-3'>
                        <Form.Label className=''>Slug</Form.Label>
                          <Form.Control
                            className='form-ch'
                            name='slug'
                            type='search'
                            placeholder='Search By Slug'
                            value={slug}
                            onChange={(e) => {setSlug(e.target.value);setSkip(0)}}
                          />
                        </Col> */}
                        <Col lg={3} md={6} className='mb-3'>
                        <Form.Label className=''>Status</Form.Label>
                          <Form.Select
                            aria-label='categories'
                            className='astroWatch-select-hover mt-0 hd-cr form-ch'
                            value={status}
                            onChange={e => {
                              setStatus(e.target.value);
                              setSkip(0)
                            }}
                          >
                            <option>Select Status</option>
                            <option value='active'>Active</option>
                            <option value='inActive'>Inactive</option>
                          </Form.Select>
                        </Col>
                        <Col lg={3} md={6} className='mb-3'>
                              <Form.Label className=''>Language</Form.Label>
                              <Form.Select
                                aria-label='categories'
                                className=' w-100 astroWatch-select-hover mt-0 hd-cr'
                                value={contentType}
                                onChange={e => {
                                  setContentType(e.target.value);
                                  setSkip(0)
                                }}
                              >
                                <option value='hindi'>Hindi</option>
                                <option value='english'>English</option>
                              </Form.Select>
                              </Col>
                        <Col lg={3} md={6} className='mb-3'>
                          <Button
                            variant='secondary'
                            className='as_btn ms-1'
                            onClick={() => {
                              handleFilterClick();
                            }}
                          >
                            Filter
                          </Button>
                          <Button
                            variant='secondary'
                            className='as_btn reset-btn'
                            onClick={() => {
                              handleReset();
                            }}
                          >
                            Reset
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>

                </Row>
              </div>
            </div>
          </div>
        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={fetchReportList}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className='no_records'>Records not Available</h2>
        )}
        </Container>

        <Modal
        centered
          show={showdt}
          size='sm'
          aria-labelledby='example-modal-sizes-title-sm'
          backdrop='static'
          onHide={handleCloseDt}
        >
          <Modal.Header className='modal-header-hd'>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
              Cancel
            </Button>
            <Button variant='primary' className='success_btn' onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>:
     <Notauthorized />
      }
      
    </div>
  );
};

export default ReportList;
