import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import { ToastContainer, toast } from 'react-toastify';
import bimg from '../imgs/banner_background.png';
import Cookies from 'universal-cookie';
import APIServicenew from '../utils/APIGeneralService';
import { SlugifyStringWithDash, setCookies, formateDate } from '../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../context/UserContextProvider';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

const CompletePujaList = () => {
  document.title = 'Puja';
  const permissioncontext =usePermissionData("completePujaList")
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [showdt, setShowDt] = useState(false);
  const [showct, setShowCt] = useState(false);
  const [type, setType] = useState('english');
  const [status, setStatus] = useState('');
  const [contentType, setContentType] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(9);
  const [pageCount, setPageCount] = useState(0);
  const [len, setLen] = useState();
  const [titlect, setTitleCt] = useState('');
  const [slugct, setSlugCt] = useState('');
  const [cloneId, setCloneId] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [pujaDate, setPujaDate] = useState('');
  const [flag, setFlag] = useState(false);
  const today = new Date();
  const Navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get('astroUserId');
  const { refreshToken } = useContext(UserContext);
  const [allDataCount, setAllDataCount] = useState();
  const [expensesData,setExpensesData] = useState([])
  const [expensesModal,setExpensesModal] = useState(false)
  const [totalBookingPrice ,setTotalBookingPrice] = useState(null)

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    Navigate('/');
  };
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const handleCloseDt = () => setShowDt(false);
  const handleCloseCt = () => setShowCt(false);

  const handleShow = async item => {
    if (item.contentLangHi === true) {
      Navigate(`/addEditPoojaForm?pujaId=${item?.pujaId}&language=${type}`);
    } else if (item.contentLangEn === true) {
      Navigate(`/addEditPoojaForm?pujaId=${item?._id}&language=${type}`);
    } else {
      Navigate(`/addEditPoojaForm`);
    }
  };

  const handleShowClone = async (item, show) => {
    if (show === 'showHi') {
      Navigate(`/addEditPoojaForm?pujaId=${item?._id}&language=hindi`);
    } else {
      Navigate(`/addEditPoojaForm?pujaId=${item?.pujaId}&language=english`);
    }
  };

  // const handleStartDate = date => {
  //   const formattedDate = moment(date).format('MM-DD-YYYY');
  //   setStartDate(formattedDate);
  // };
  
  // const handleEndDate = date => {
  //   const formattedDate = moment(date).format('MM-DD-YYYY');
  //   setEndDate(formattedDate);
  // };
  

  const handlePujaDate = date => {
    const formattedDate = moment(date).format('MM-DD-YYYY');
    setPujaDate(formattedDate);
  };

   // select pandit by id --

   const [dropdownData, setDropdownData] = useState([]);
   const [selectedOption, setSelectedOption] = useState('');
 
   useEffect(() => {
     titleDropdown();
   }, []);
 
   const titleDropdown = async () => {
     const response = await APIServices.get(`pandit/getPanditList`, headers);
     if (response?.status === 200) {
       setDropdownData(response?.data?.Listdata);
     }
   };
   const getPujaBookingTotalPrice = async (id) => {
    let pujaBookingTotal = await APIServices.get(`astroPooja/pujaBookingTotalPrice/${id}`, headers);
    if (pujaBookingTotal?.status === 200) {
      if (pujaBookingTotal?.data?.totalPriceOfPujaBookings) {
        setTotalBookingPrice(pujaBookingTotal?.data?.totalPriceOfPujaBookings)
      }else{
        console.log("Somthing went wrong");
        
      }
    }
  };
   const handleSelectChange = selected => {
     setSelectedOption(selected);
   };
 
   const options = dropdownData.map(item => ({
     value: item._id,
     label: item.name,
   }));

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Puja Image',
      Cell: e => {
        return (
          <img
            src={`${e.row.original.posterImage ? e.row.original.posterImage : bimg}`}
            alt='img'
            className='quotes_table_preview_image'
          />
        );
      },
    },
    {
      Header: 'Puja Name',
      Cell: e => {
        return <span>{e.row.original.title ?? '_'}</span>;
      },
    },
    {
      Header: 'Address',
      Cell: e => {
        return <span>{e.row.original.address ?? '_'}</span>;
      },
    },
    {
      Header: 'Puja Date',
      Cell: e => {
        return (
          <>
            <span>{e.row.original.pujaDate ? moment(e.row.original.pujaDate).format('DD MMM, YYYY hh:mm A') : '_'}</span>
          </>
        );
      },
    },
    {
      Header: 'Manage Puja',
      Cell: e => {
        return (
          <>
            <h5>
              <input
                type='button'
                className='Et-btn'
                value='Manage Puja'
                onClick={() => {
                  e.row.original.contentLangHi === true
                    ? Navigate(`/shared-puja?pujaId=${e.row.original?.pujaId}` , {
                      state: { permissioncontext },
                    })
                    : Navigate(`/shared-puja?pujaId=${e.row.original?._id}`, {
                      state: { permissioncontext },
                    });
                }}
              />
            </h5>
          </>
        );
      },
    },
    {
      Header: 'Expenses',
      Cell: e => {
        return (
          <>
          {(e.row.original?.pujaExpencese || e.row.original?.pujaPandit )? (
             <h5>
                <input
                  type='button'
                  className='Et-btn notes-btn'
                  value='View Expense'
                  onClick={() => {
                    setExpensesData(e.row.original);
                    setExpensesModal(true);
                    getPujaBookingTotalPrice(e.row.original._id);
                  }}
                  
                />
          </h5>

          ): "_"}
          </>
        );
      },
    },
    {
      Header: ` Create Clone `,
      Cell: e => {
        if (e.row.original.status === false && e.row.original.contentLangEn === true) {
          return (
            <span>
              <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-edit'>Clone</Tooltip>}>
                <FaCopy
                  className={permissioncontext.add?'me-1 hd-cr':'me-1 hd-cr disabled-btn'}
                  title='Clone'
                  size={20}
                  onClick={() => {
                    if(permissioncontext.add){

                      handleCloneCt(e.row.original?._id);
                    }
                  }}
                />
              </OverlayTrigger>
            </span>
          );
        } else {
          return <span className='ms-4'>{'_'}</span>;
        }
      },
    },
    {
      Header: 'Total Booking',
      Cell: e => {
        return <span>{e.row.original.bookingCount}</span>;
      },
    },
    // {
    //     Header: 'Action',
    //     Cell: e => {
    //       if (e.row.original.status === true) {
    //         return (
    //           <h5>
    //             <input
    //               type='button'
    //               className='_active'
    //               value='Active'
    //               onClick={() =>
    //                 handleclick(e.row.original )
    //               }
    //             />
    //           </h5>
    //         );
    //       } else {
    //         return (
    //           <h5>
    //             <input
    //               type='button'
    //               className='_inactive'
    //               value='Inactive'
    //               onClick={() =>
    //                 handleclick(
    //                   e.row.original
    //                 )
    //               }
    //             />
    //           </h5>
    //         );
    //       }
    //     },
    //   },

    // {
    //   Header: 'Completed Date',
    //   Cell: e => {
    //     return <span>{moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A')}</span>;
    //   },
    // },
    {
      Header: 'Completed Date',
      Cell: e => {
        return <span>{e.row.original ? moment(e.row.original?.updatedAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>;
      },
    },
  ]);
  const handleShowDt = item => {
    if (item?.contentLangEn === true) {
      setContentType('english');
    } else if (item?.contentLangHi === true) {
      setContentType('hindi');
    }
    setSelectedId(item?._id);
    setShowDt(true);
  };

  const fetchData = useCallback(
    async (offset, perPage, index) => {
      setLoading(true);
      try {
        let response;
        let selectedOptionId = selectedOption?.value === undefined ? '' : selectedOption?.value;
        if (type === 'english') {
          response = await APIServices.get(
            `astroPooja/pujaCompletedList/${offset}/${perPage}?status=${status}&panditId=${selectedOptionId}&title=${name}&address=${address}&pujaDate=${pujaDate}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}`,
            headers
          );
        } 
        // else if (type === 'hindi') {
        //   response = await APIServices.get(
        //     `astroPooja/pujaCompletedList/hi/${offset}/${perPage}?status=${status}&title=${name}&address=${address}&pujaDate=${pujaDate}&startDate=${startDate}&endDate=${endDate}`,
        //     headers
        //   );
        // }
        let data = response?.response?.data;

        if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status) {
            setData(apiData?.Listdata);
            setPageCount(Math.ceil(apiData.totalCount / perPage));
            setAllDataCount(apiData.totalCount);
            setFlag(false);
            setLoading(false);
          }
        } else {
          if (data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setOffset(offset);
      setPerPage(perPage);
      setPageIndex(index);
    },
    [pageIndex, offset, status, type, name, address, pujaDate, startDate, endDate,selectedOption?.value]
  );

  const handlePageClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setOffset(newOffset);
  };

  async function updateFile(showToast) {
    let res = await APIServices.get(`astroPooja/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
          toast.success(apiData?.message);
        }
        fetchData(offset, perPage, pageIndex, status);
      }
    }
  }

  const handledelete = async () => {
    let formData = { languageType: contentType };
    let res = await APIServices.delete(`astroPooja/findByIdAndremove/${selectedId}`, headers, formData);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        setShowDt(false);
        updateFile();
      }
    }
  };

  const handleclick = async item => {
    const formData = new FormData();
    var datas = item?.status === true ? false : true;
    let id = item?._id;
    formData.append('status', datas);

    if (item?.contentLangEn === true) {
      formData.append('languageType', 'english');
    } else if (item?.contentLangHi === true) {
      formData.append('languageType', 'hindi');
    }

    await APIServices.put(`astroPooja/updateStatus/${id}`, formData, headers)
      .then(res => {
        updateFile();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handlePin = async item => {
    const formData = new FormData();
    var datas = item?.isPin === true ? false : true;
    let id = item?._id;
    formData.append('isPin', datas);

    if (item?.contentLangEn === true) {
      formData.append('languageType', 'english');
    } else if (item?.contentLangHi === true) {
      formData.append('languageType', 'hindi');
    }
    await APIServices.put(`astroPooja/updatePin/${id}`, formData, headers)
      .then(res => {
        updateFile();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleCloneCt = id => {
    setTitleCt('');
    setSlugCt('');
    setCloneId(id);
    setShowCt(true);
  };

  const handleClone = async () => {
    if (titlect === '') {
      toast.error('Please fill the field !!');
    } else {
      let payload = {
        title: titlect,
        slug: slugct,
        userId: astroUserId,
      };
      const res = await APIServices.post(`astroPooja/cloneById/${cloneId}`, payload, headers);
      if (!res || (res && res?.status !== 200)) {
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const data = res?.data;
      if (data && data.status && data.message) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowCt(false);
        updateFile();
      }
    }
  };

  const handleCloneHindi = async id => {
    const res = await APIServices.post(`astroPooja/hi/cloneById/${id}`, { userId: astroUserId }, headers);
    if (!res || (res && res.status !== 200)) {
      toast.error('Something went wrong. Try Again!!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const data = res?.data;
    if (data && data.status === false) {
      toast.warn(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (data && data.status && data.message) {
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      updateFile();
    }
  };
  const resetData = () => {
    setPageIndex(0);
    setType('english');
    setStatus('');
    setPujaDate('');
    setAddress('');
    setOffset(0);
    setName('');
    setStartDate('');
    setEndDate('');
    setSelectedRange('');
    setSelectedOption('')
    setFlag(true);
  };

  useEffect(() => {
    fetchData(offset, perPage, pageIndex);
  }, [flag]);

  const handleFilterClick = () => {
    if (name || startDate || endDate || perPage || offset || pageIndex || type || status || pujaDate || address || selectedOption?.value) {
      fetchData(offset, perPage, 0, type, status, name, pujaDate, address, startDate, endDate,selectedOption?.value);
    }
  };

  return (
    <>
    {
      permissioncontext.view?
<>
        <div className='main aarti_section'>
          <>
            <div className='gita_quote_heading'>
              <div className='container d-flex align-items-center justify-content-between'>
                <h4 className='hd-list-f left-q'>
                  {' '}
                 Completed Puja <small>({allDataCount})</small>
                </h4>
               
              </div>
            </div>

            <div className='aarti_inner'>
              <>
               <div className='user-list-table box-shadow br-6 p-20 my-3'>
               
                    <div className='feed_area pt-0'>
                      <div className='chat_search_box user-search'>
                        <div className='chat-search-field'>
                          
                            <Form>
                                <Form.Group as={Row} className='mb-3 align-items-end' controlId=''>
                                  <Col lg={3} md={6}>
                                    <Form.Label className='label-f-w-cd'>Puja Name</Form.Label>

                                    <Form.Control
                                      className=' mt-0 form-ch'
                                      name='name'
                                      type='search'
                                      placeholder='Search By Puja Name'
                                      value={name}
                                      onChange={e => {
                                        setName(e.target.value);
                                        setOffset(0);
                                      }}
                                    />
                                  </Col>
                                  <Col lg={3} md={6}>
                                    <Form.Label class='label-f-w-cd'>
                                      Select Pandit
                                    </Form.Label>
                                    <Select
                                    className=' mt-0'
                                      classNamePrefix='react-select'
                                      options={options}
                                      value={selectedOption}
                                      onChange={handleSelectChange}
                                      placeholder='Search by Pandit...'
                                      isClearable
                                    />
                                  </Col>
                                  <Col lg={3} md={6}>
                                    <Form.Label className='label-f-w-cd'>Address</Form.Label>

                                    <Form.Control
                                      className=' mt-0 form-ch'
                                      name='address'
                                      type='search'
                                      placeholder='Search By Address'
                                      value={address}
                                      onChange={e => {
                                        setAddress(e.target.value);
                                        setOffset(0);
                                      }}
                                    />
                                  </Col>
                                  <Col lg={3} md={6}>
                                    <Form.Label className='label-f-w-cd'>Puja Date</Form.Label>
                                    <DatePicker
                                      placeholderText={'Select a puja date'}
                                      className='form-control  form-ch fil-date-pick mt-0'
                                      name='date'
                                      selected={pujaDate ? new Date(pujaDate) : null}
                                      onChange={date => {
                                        handlePujaDate(date);
                                        setOffset(0);
                                      }}
                                      dateFormat='yyyy-MM-dd'
                                    />
                                  </Col>
                                  <Col lg={3} md={6}>
                                      <Form.Label className='label-f-w-cd'>Search By Date</Form.Label>
                                        <div className="date-range-wrapper"> 
                                            <div className='select-icon-wrapper'>
                                                <select
                                                className="select-range"
                                                    value={selectedRange}
                                                    onChange={(e) => handleDateRange(e.target.value)}
                                                  >
                                                    <option value="disabled">Select Date Range</option>
                                                    <option value="today">Today</option>
                                                    <option value="yesterday">Yesterday</option>
                                                    <option value="thisWeek">This Week</option>
                                                    <option value="last7Days">Last 7 Days</option>
                                                    <option value="thisMonth">This Month</option>
                                                    <option value="lastMonth">Last Month</option>
                                                    <option value="customDate">Custom Date</option>
                                                </select>
                                              <img src='/images/down-arrow.png' className='select-icons'/>
                                            </div>

                                              {selectedRange === "customDate" && (
                                                <DatePicker
                                                  selectsRange={true}
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  maxDate={new Date()}
                                                  onChange={(update) => {
                                                    const [start, end] = update;
                                                    setStartDate(start);
                                                    setEndDate(end);
                                                  }}
                                                  // isClearable={true}
                                                  placeholderText="Select custom date range"
                                                  dateFormat="yyyy-MM-dd"
                                                />
                                              )}
                                            </div>
                                          {/* <Row>    
                                            <Col md={6}>
                                              <Form.Label className='label-f-w-cd  mt-0 '>Search By Completed Date</Form.Label>

                                      
                                              <DatePicker
                                                placeholderText={'From date'}
                                                className='form-control form-ch fil-date-pick mt-0'
                                                name='date'
                                                selected={startDate ? new Date(startDate) : null}
                                                onChange={date => {
                                                  handleStartDate(date);
                                                  setOffset(0);
                                                }}
                                                dateFormat='yyyy-MM-dd'
                                              />
                                            </Col>
                                            <Col md={6}>

                                              <DatePicker
                                                placeholderText={'To date'}
                                                className='form-control form-ch fil-date-pick mt-5'
                                                name='date'
                                                selected={endDate ? new Date(endDate) : null}
                                                onChange={date => {
                                                  handleEndDate(date);
                                                  setOffset(0);
                                                }}
                                                dateFormat='yyyy-MM-dd'
                                              />

                                            </Col>
                                          </Row> */}
                                   </Col>

                                  <Col lg={3} md={6}>
                                      <Button
                                        variant='secondary'
                                        className='as_btn ms-1 mt-4'
                                        onClick={() => {
                                          handleFilterClick();
                                          setOffset(0);
                                        }}
                                      >
                                        Filter
                                      </Button>
                                      <Button
                                        variant='secondary'
                                        className='as_btn reset-btn mt-4'
                                        onClick={() => {
                                          resetData();
                                        }}
                                      >
                                        Reset
                                      </Button>
                                  </Col>
                                </Form.Group>

                             {/* <Row>
                                <Form.Group as={Row} className='mb-3' controlId=''>
                                 
                                     <Col md={3}>
                                      <Form.Label className='label-f-w-cd  mt-2 '>Language</Form.Label>

                                      <Form.Select
                                        aria-label='label-selected-menu'
                                        className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                                        value={type}
                                        onChange={e => {
                                          setType(e.target.value);
                                          setOffset(0);
                                        }}
                                      >
                                        <option value='english'>English</option>
                                        <option value='hindi'>Hindi</option>
                                      </Form.Select>
                                    </Col> 
                                     <Col md={3}>
                                      <Form.Label className='label-f-w-cd  mt-2 '> Status</Form.Label>

                                      <Form.Select
                                        aria-label='label-selected-menu'
                                        className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                                        value={status}
                                        onChange={e => {
                                          setStatus(e.target.value);
                                          setOffset(0);
                                          setPageIndex(0);
                                        }}
                                      >
                                        <option value=''>All</option>
                                        <option value='active'>Active</option>
                                        <option value='inActive'>InActive</option>
                                        <option value='globel'>Globel</option>
                                      </Form.Select>
                                    </Col> 

                                  

                                  
                                </Form.Group>
                              </Row>*/}
                            </Form>
                        
                        </div>
                      </div>
                    </div>
          
                </div>
                <div className='overflow-scroll'>
                  {data && data.length > 0 ? (
                    <DynamicDataTableWithCount
                      columns={columns}
                      data={data}
                      totalCount={pageCount}
                      initialState={{
                        pageIndex: pageIndex,
                        pageSize: perPage,
                      }}
                      fetchData={fetchData}
                      forcePage={offset / perPage}
                    />
                  ) : (
                    <h2 className='no_records'>Records not Available</h2>
                  )}
                </div>
              </>
             
            </div>
          </>
        </div>
      </>:
      <Notauthorized />
    }
      

      <Modal
        show={showdt}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
      scrollable
      centered
        show={showct}
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseCt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Create title and slug for clone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='4'>
                Title
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='title'
                  defaultValue={titlect}
                  className='form-control '
                  onChange={e => {
                    setTitleCt(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label column sm='4'>
                Slug
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Slug...'
                  name='slug'
                  value={slugct}
                  className='form-control '
                  onChange={e => {
                    setSlugCt(e.target.value);
                  }}
                  onBlur={e => setSlugCt(SlugifyStringWithDash(e.target.value))}
                />
                <small>
                  If you enter slug it will create new puja with same content.<br></br> If not then cloned existing and
                  inactive this one.
                </small>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' className='as_btn' onClick={handleClone}>
            create
          </Button>
          <Button variant='secondary' className='as_btn' onClick={handleCloseCt}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      {/* expenses modal */}
      
      <Modal
        centered
        scrollable
        show={expensesModal}
        size='lg'
        // size={`${pujaData?.pujaExpencese === null} ? 'xl : 'lg`}
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={() => {
          setExpensesModal(false)
        }}
      >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title>Puja Expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
               <div className=''>
                  <ol className='puja-expenses-wrapper'>
                    <li className='d-flex justify-content-between'>
                      <p className='my-1 fw-semibold'>Expenses</p>
                      <p className='mx-4  fw-semibold'>Price</p>
                    </li>
                    <hr/>
                    <li className='d-flex justify-content-between'>
                        <p className='my-1 fw-semibold'>{expensesData?.pujaExpencese?.panditName || expensesData?.pujaPandit?.name} (Pandit Name)</p>
                        <p className='mx-4'>₹{expensesData?.pujaExpencese?.panditPrice || expensesData?.pujaPandit?.price}.00</p>
                    </li>
                    {expensesData &&
                      expensesData?.pujaExpencese?.expenceseItem?.map((data, ind) => {
                        return (
                          <>
                            <li className='d-flex justify-content-between' key={data?._id}>
                              <div className='d-flex align-items-center'>
                                  {ind + 1}.
                                  <p className='my-1 ms-1'>{data?.name}</p>
                                </div>
                              <p className='mx-4'>₹{data?.price}.00</p>
                            </li>
                          </>
                        );
                      })}
                      
                      <hr/>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-semibold my-1'>Expenses Total Price</p>
                        {/* <p className='mx-4'>₹{expensesData?.pujaExpencese?.totalPrice}.00</p> */}
                        <p className="mx-4">
                          ₹{expensesData?.pujaExpencese?.totalPrice 
                            ? `${expensesData.pujaExpencese.totalPrice}.00` 
                            : `${expensesData?.pujaPandit?.price}.00`}
                        </p>
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-semibold my-1'>Bookings Total Price</p>
                        <p className='mx-4'>₹{totalBookingPrice}.00</p>
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-between'>
                      <p className='fw-semibold my-1'>
                        {Number(totalBookingPrice) - Number(expensesData?.pujaExpencese?.totalPrice??expensesData?.pujaPandit?.price ) >= 0 
                          ? "Total Puja Profit" 
                          : "Total Puja Loss"}
                      </p>
                      <p className='mx-4'>
                        ₹{Math.abs(Number(totalBookingPrice) - Number(expensesData?.pujaExpencese?.totalPrice??expensesData?.pujaPandit?.price))}.00
                      </p>
                    </div>
                  </ol>
            </div>
           
        </Modal.Body>
        <Modal.Footer>
         
          <Button
            variant='secondary'
            className='reset-btn as_btn'
            onClick={() => {
              setExpensesModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default CompletePujaList;
