import React, { useEffect, useState,useContext} from "react";
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useNavigate,useParams , useLocation } from "react-router-dom";
import { FaEdit, FaTrash, FaMinus, FaPlus,FaArrowLeft, FaCopy ,FaMapPin } from "react-icons/fa";
import {BsPinFill ,BsPin} from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../components/Shared/Sidebar";
import bimg from "../../imgs/banner_background.png";
import upimg from "../../imgs/default.jpg";
import Cookies from "universal-cookie";
import APIServicenew from "../../utils/APIGeneralService";
import APIServicenewAPI from "../../utils/APIGeneralService";

import { formateDateAndTime ,SlugifyStringWithDash ,capitalizeFirstLetter ,setCookies} from "../../utils/Helper";
import PosterCrop from "./PosterCrop";
import BannerCrop from "./bannerCrop";
import Mandirdetailcrop from "./Mandirdetailcrop";
import Whatsappcrop from "./Whatsappcrop";
import Pujacrop from "./Pujacrop";
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from "react-datepicker";
import { UserContext } from "../../context/UserContextProvider";
import { PujaImgGalleyModel } from "../../components/Shared/pujaImgGalleryModal";
import Select from 'react-select';

const AddEditPoojaForm = () => {
  const  {refreshToken} = useContext(UserContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pujaId = searchParams.get('pujaId');
  const language = searchParams.get('language');
  const [file, setFile] = useState("");
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState();
  const [showImg3, setshowImg3] = useState();
  const [selectedId, setSelectedId] = useState();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [pujaFeatures ,setPujaFeatures] = useState("")
  const [titleEn ,setTitleEn] = useState("");
  const [contentType , setContentType] = useState ("")
  const [slug ,setSlug] = useState();
  const [content, setContent] = useState("");
  const [isGlobalPrice , setIsGlobalPrice] = useState(false);
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [pujaDate, setPujaDate] = useState(new Date());
  const [subContent, setSubContent] = useState("");
  const [poojaLabhTitle, setPoojaLabhTitle] = useState();
  const [poojaMethodTitle, setPoojaMethodTitle] = useState();
  const [mandirDetailsHeader, setMandirDetailsHeader] = useState();
  const [mandirDetailsTitle, setMandirDetailsTitle] = useState();
  const [mandirDetailsContent, setMandirDetailsContent] = useState();
  const [poojaSelectionTitle, setPoojaSelectionTitle] = useState();
  const [whatsappTemplateContent, setWhatsappTemplateContent] = useState();
  const [whatsappTemplateImage, setWhatsappTemplateImage] = useState();
  const [showWhatsappTemplateImage, setShowWhatsappTemplateImage] = useState();
  const [whatsappTemplateImagePreview, setWhatsappTemplateImagePreview] = useState();
  const [posterImage, setPosterImage] = useState("");
  const [posterImagePreview, setPosterImagePreview] = useState();
  // const [bannerImage, setBannerImage] = useState("")
  // const [bannerImagePreview, setBannerImagePreview] = useState();

const [prasadPrice,setPrasadPrice] = useState("51")
const [dakshinaPrice,setDakshinaPrice] = useState("101,151,201")
const [prasadPriceGlobal,setPrasadPriceGlobal] = useState("21")
const [dakshinaPriceGlobal,setDakshinaPriceGlobal] = useState("51,101,151,201")

const [prasadPriceStatusInr,setPrasadPriceStatusInr] = useState("yes")
const [dakshinaStatusInr,setDakshinaStatusInr] = useState("yes")
const [prasadPriceStatusUsd,setPrasadPriceStatusUsd] = useState("yes")
const [dakshinaStatusUsd,setDakshinaStatusUsd] = useState("yes")



  const[loader,setLoader]=useState(false)
  const [img, setImg] = useState();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState();
  const [planImgSelect ,setPlanImgSelect] = useState();
  const [modalFieldKey, setModalFieldKey] = useState(null);

  var fileObj = [];
  var arrobj = [];
  const [inputFields, setInputFields] = useState([
    {
      title: "",
      content: "",
      fileM: "",
    },
  ]);
  const [inputFieldsN, setInputFieldsN] = useState([
    {
      title: "",
      content: "",
    },
  ]);
  const [allDataCount , setAllDataCount] = useState()

  const [inputFieldsSec, setInputFieldsSec] = useState([
    {
      title: "",
      subtitle: "",
      planImage:"",
      price: "",
      members:"",
      globalPrice:"" ,
      paymentLink: "",
      isGlobal:""
      // content: "",
      
    },
  ]);

 

  const Navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  // const APIServicesAstroPuja = new APIServicenewAPI(process.env.REACT_APP_PUJA_PANEL_API_URL);

  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId");


 // chadhawaList dropdown

 const [ chadhawaList, setChadhawaList ] = useState([])

 const [selectedOptions, setSelectedOptions] = useState([]);

 useEffect(() => {
  getChadhawaList();
 }, []);

 const getChadhawaList = async () => {
   const response = await APIServices.get(`marketingTempleList/getAll`,headers);

   if (response?.status === 200) {
       setChadhawaList(response?.data?.list);
   } else {
     setChadhawaList([]);
   }
 };

 const options = chadhawaList?.map(item => ({
   value: item?.id,
   label: item?.temple_name,
 }));

 const handleChangeChadhawaSamagri = selectedOptions => {
   setSelectedOptions(selectedOptions ? selectedOptions.map(option => option.value) : []);

  //  if (selectedOptions && selectedOptions.length > 0) {
  //   const newErrors = { ...chadhawaError };
  //   delete newErrors.selectedOptions;
  //   setChadhawaError(newErrors);
  // }
 };


  const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
  const [clearSel ,setClearSel] =  useState(false)
  const handleOpenModal = (fieldKey ,index) => {
    setClearSel(true)
    setModalFieldKey(fieldKey);
    setRindex(index);
    setShowImageGalleyModel(true);
  };


  const handleSetImageUrl = (urls, fieldKey) => { 
    let extractedPath = extractPaths(urls);
    setClearSel(false)
    if(fieldKey === "posterImage"){
    setPosterImagePreview(urls);
    setPosterImage(extractedPath);
    setShowImageGalleyModel(false);
    }
     if (fieldKey === "multiImg"){
    setFile1(extractedPath);
    setFormData(urls);
    }
    if (fieldKey === "mandirDetails"){
      setshowImg3(urls);
      setFile(extractedPath);
    }
    if (fieldKey === 'pujaLabhImg') {
      const updatedFields = inputFields.map((field, index) =>
        index === rindex ? { ...field, fileM: extractedPath, preview_url: urls } : field
      );
      setInputFields(updatedFields);
    }
  };

;
const extractPaths = (url) => {
  
  // Helper function to extract path from a single URL
  const extractPath = (singleUrl) => {
    const parts = singleUrl.split("cloudfront.net/");
    if (parts.length > 1) {
      return parts[1];
    }
    return singleUrl; // Return the original URL if it doesn't contain "cloudfront.net/"
  };
  
  // Check if the input is an array
  if (Array.isArray(url)) {
    return url.map(singleUrl => extractPath(singleUrl));
  } else if (typeof url === 'string') {
    return extractPath(url);
  }
  
  // Return the input as is if it's neither an array nor a string
  return url;
};


  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        title: "",
        content: "",
        fileM: "",
      },
    ]);
  };

  const addInputFieldN = () => {
    setInputFieldsN([
      ...inputFieldsN,
      {
        title: "",
        content: "",
      },
    ]);
  };

  const addInputFieldSec = () => {
    setInputFieldsSec([
      ...inputFieldsSec,
      {
        title: "",
        subtitle: "",
        planImage:"",
        price: "",
        members:"",
        globalPrice:"" ,
        paymentLink: "",
        isGlobal:""
        // content: "",
        
      },
    ]);
  };
  const removeInputFields = (index) => {
    let rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const removeInputFieldsN = (index) => {
    let rows = [...inputFieldsN];
    rows.splice(index, 1);
    setInputFieldsN(rows);
  };

  const removeInputFieldsSec = (index) => {
    let rows = [...inputFieldsSec];
    rows.splice(index, 1);
    setInputFieldsSec(rows);
  };


  function handleErrors(e) {
    let key, value;
    key = e?.target?.name;
    if( e === "pujaDate") key = e

    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }
  const handleChange = (index, evnt, field) => {
    let key, value;
    key = evnt?.target?.name;
    if (!!errors?.contentPujaLabh) {
      errors?.contentPujaLabh?.forEach((field, index) => {
          if (errors.contentPujaLabh[index][key]!=null || errors.contentPujaLabh[index][key]!="") {
            errors.contentPujaLabh[index][key] = null
          }
      })
    }
    const list = [...inputFields];
    if (field === "text") {
      value = evnt?.target?.value;
    } 
    // else if (field === "file") {
    //   value = evnt;
    //   list[index]["preview_url"] = URL.createObjectURL(evnt);
    // }
    list[index][key] = value;
    setInputFields(list);
  };


  const handleImage = (index, evnt, field) => {
    let key, value;
    key = evnt?.target?.name
    const list = [...inputFields];
    if (field === "file") {
      value = evnt;
      list[index]["preview_url"] = URL.createObjectURL(evnt);
    }
    list[index]["fileM"] = value;
    setInputFields(list);
  };


  const handleChangeN = (index, evnt) => {
    const { name, value } = evnt.target;
    let key
    key = evnt?.target?.name;
    if (!!errors?.contentPujaMethod) {
      errors?.contentPujaMethod?.forEach((field, index) => {
          if (errors.contentPujaMethod[index][key]!=null || errors.contentPujaMethod[index][key]!="") {
            errors.contentPujaMethod[index][key]  = null
          }
      })
    }


    const list = [...inputFieldsN];
    list[index][name] = value;
    setInputFieldsN(list);
  };

  const handleChangeSec = (index, evnt) => {
    const { name, value } = evnt.target;

    let key
    key = evnt?.target?.name;
    if (!!errors?.PujaSelectionContent) {
      errors?.PujaSelectionContent?.forEach((field, index) => {
          if (errors.PujaSelectionContent[index][key]!=null || errors.PujaSelectionContent[index][key]!="") {
            errors.PujaSelectionContent[index][key] = null
          }
      })
    }
    const list = [...inputFieldsSec];
    list[index][name] = value;
    setInputFieldsSec(list);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target?.files) {
      setFile1(event.target.files);
      fileObj.push(event.target.files);
      for (let i = 0; i < fileObj[0]?.length; i++) {
        arrobj?.push(URL.createObjectURL(fileObj[0][i]));
        setFormData(arrobj);
      }
    }

    let key, value;
    key = event?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  };
  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    Navigate("/");
  }
 useEffect(()=>{
    handleShow()
    fetchPujaSelectionImgData()
 } ,[])
  const refetchPujaDetails = async (req, res) => {
    if(pujaId){

    let [pujaDetailsRes] = await Promise.all([APIServices.get(`astroPooja/getDetailById/${pujaId}?language=${language}`, headers)]);
    if (pujaDetailsRes?.status === 200) {
      let apiData = pujaDetailsRes?.data;
      if (apiData?.status) {
        handleShow(apiData?.data)
      }
    }
  }
  };

  async function fetchPujaSelectionImgData() {
    let response = await APIServices.get(`pujaPlanSelectionImg/getAll`, headers)
    let data = response?.response?.data
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
      setPlanImgSelect(apiData?.Listdata)
      }
    }else{
      if(data.status == 4){
     logout()}
    }
  }

  useEffect(()=>{
    refetchPujaDetails()
  },[pujaId])

  const onImageChangeth = (event) => {
    setshowImg3(event);
    setFile(event);
    // if (event.target.files && event.target.files[0]) {
    //   setshowImg3(URL.createObjectURL(event.target.files[0]));
    //   setFile(event.target.files[0]);
    // }
  };

  const onImageChangePoster= (event) => {
    // setPosterImagePreview(event);
    //   setPosterImage(event);
    if (event.target.files && event.target.files[0]) {
      setPosterImagePreview(URL.createObjectURL(event.target.files[0]));
      setPosterImage(event.target.files[0]);
      
    }
  };

  // const onImageChangeBanner= (event) => {
  //   setBannerImagePreview(event);
  //     setBannerImage(event);
  //   // if (event.target.files && event.target.files[0]) {
  //   //   setPosterImagePreview(URL.createObjectURL(event.target.files[0]));
  //   //   setPosterImage(event.target.files[0]);
  //   // }
  // };

   const onImageChangeWhatsapp= (event) => {
    setShowWhatsappTemplateImage(event);
    setWhatsappTemplateImage(event);
    // if (event.target.files && event.target.files[0]) {
    //   setPosterImagePreview(URL.createObjectURL(event.target.files[0]));
    //   setPosterImage(event.target.files[0]);
    // }
  };

  const handleClose = () => {
    setErrors("")
    setInputFields([
      {
        title: "",
        content: "",
        fileM: "",
      },
    ]);
    setInputFieldsN([
      {
        title: "",
        content: "",
      },
    ]);
    setInputFieldsSec([
      {
        title: "",
        subtitle: "",
        planImage:"",
        price: "",
        members:"",
        globalPrice:"",
        paymentLink: "",
        isGlobal:""
        // content: "",
        
      },
    ]);
    setFormData([]);
    // setPosterCropImg()
    setBannerCropImg()
    setmandirCropImg()
    setwhatsappCropImg()
    setpujaCropImg()
    setFileCpujaimage()
    setRindex()   
  };



  const handleShow = async (item) => {
    // setContentType("")
    setshowImg3("");
    setFile1("");
    setFile("");
    setWhatsappTemplateImage("");
    setShowWhatsappTemplateImage("");
    setPosterImage("")
    // setBannerImage("")
    setFormData([]);
    setSelectedId(item?._id);
    setTitle(item?.title??"");
    setSubTitle(item?.subTitle??"");
    setPujaFeatures(item?.pujaFeatures??"")
    // setTitleEn(item?.titleEn)
    setIsGlobalPrice(item?.isGlobalPrice??false)
     if (item?.contentLangEn === true) {
    setContentType("english")
    }else if( item?.contentLangHi === true){
    setContentType("hindi")
    }else{
      setContentType("english")
    }
    setSlug(item?.slug??"")
    setContent(item?.content?? "");
    setAddress(item?.address?? "");
    setDate(item?.date ??"");
    setPujaDate( item?.pujaDate ? new Date(item?.pujaDate ):null)
    setFile2(item?.poojaImages);
    setSubContent(item?.subContent??"");
    setPoojaLabhTitle(item?.poojaLabhTitle??"");
    setInputFields(item?.poojaLabhContents??[
      {
        title: "",
        content: "",
        fileM: "",
      },
    ]);
    // setPoojaMethodTitle(item?.poojaMethodTitle ??"");
    // setInputFieldsN(item?.poojaMethodContents??[
    //   {
    //     title: "",
    //     content: "",
    //   },
    // ]);
    setMandirDetailsHeader(item?.mandirDetailsHeader??"");
    setMandirDetailsTitle(item?.mandirDetailsTitle??"");
    setMandirDetailsContent(item?.mandirDetailsContent??"");
    setPoojaSelectionTitle(item?.poojaSelectionTitle??"");

    setInputFieldsSec(item?.poojaSelectionOptions??[
      {
        title: "",
        subtitle: "",
        planImage:"",
        price: "",
        members:"",
        globalPrice:"",
        paymentLink: "",
        isGlobal:""
        // content: "",
     
      },
    ]);
    setImg(item?.mandirDetailsImage??"");
    setWhatsappTemplateImagePreview(item?.whatsappTemplateImage??"")
    setWhatsappTemplateContent(item?.whatsappTemplateContent??"");
    setPosterImagePreview(item?.posterImage??"")
    // setBannerImagePreview(item?.bannerImage??"")
    setPrasadPrice(item?.prasadPrice === 0 || item?.prasadPrice === undefined || item?.prasadPrice === null  ? "51" : item?.prasadPrice)
    setPrasadPriceGlobal(item?.prasadPriceGlobal === 0 || item?.prasadPriceGlobal === undefined || item?.prasadPriceGlobal === null  ? "21" : item?.prasadPriceGlobal )
    setDakshinaPrice(item?.dakshinaPrice[0] === 0 || item?.dakshinaPrice[0] === undefined || item?.dakshinaPrice[0] === null ? "101,151,201" : item?.dakshinaPrice);
    setDakshinaPriceGlobal(item?.dakshinaPriceGlobal[0] === 0 || item?.dakshinaPriceGlobal === undefined || item?.dakshinaPriceGlobal === null ? "51,101,151,201" : item?.dakshinaPriceGlobal)
    setPrasadPriceStatusInr(item?.IsPrasadPriceInr ?? "yes")
    setDakshinaStatusInr(item?.IsDakshinaPriceInr ?? "yes")
    setPrasadPriceStatusUsd(item?.IsPrasadPriceUsd ?? "yes")
    setDakshinaStatusUsd(item?.IsDakshinaPriceUsd ?? "yes")
    setSelectedOptions(item?.chadhawaSamgriIDs ?? [])
  };


  
  async function handleEdit() {
    const formData = new FormData();
    // if (typeof file === "object") {
    //   formData.append("mandirDetailsImage", file);
    // }
    // if (typeof whatsappTemplateImage === "object") {
    //   formData.append("whatsappTemplateImage", whatsappTemplateImage);
    // }
    // if (typeof posterImage === "object") {
    //   formData.append("posterImage", posterImage);
    // }

    if (file) {
      formData.append("mandirDetailsImage", file);
    }
    if ( posterImage ) {
      formData.append("posterImage", posterImage);
    }
    // if (typeof bannerImage === "object") {
    //   formData.append("bannerImage", bannerImage);
    // }
    formData.append("title", title);
    formData.append("subTitle", subTitle);
    formData.append("pujaFeatures", pujaFeatures);

    // formData.append("titleEn", titleEn);
    // formData.append("slug", slug);
    formData.append("isGlobalPrice" , isGlobalPrice);
    //  if (contentType === "english") {
    //   formData.append("contentLangEn", true);
    //   formData.append("contentLangHi", false);

    // } else if (contentType === "hindi") {
    //   formData.append("contentLangHi", true);
    //   formData.append("contentLangEn", false);
    // }
    formData.append("content", content);
    formData.append("address", address);
    formData.append("date", date);
    const pujaD = formateDateAndTime(pujaDate)
    formData.append("pujaDate", pujaD);
    if (typeof file1 === "object") {
      for (const key of Object.keys(file1)) {
        formData.append("poojaImages", file1[key]);
      }
    }
    formData.append("subContent", subContent);
    formData.append("poojaLabhTitle", poojaLabhTitle);
    inputFields.forEach((element, i) => {
      formData.append(`poojaLabhContents[${i}][title]`, element.title);
      formData.append(`poojaLabhContents[${i}][content]`, element.content);
      formData.append(`poojaLabhContents[${i}][fileM]`, element.fileM);
    });
    // formData.append("poojaMethodTitle", poojaMethodTitle);
    // formData.append("poojaMethodContents", JSON.stringify(inputFieldsN));
    formData.append("mandirDetailsHeader", mandirDetailsHeader);
    formData.append("mandirDetailsTitle", mandirDetailsTitle);
    formData.append("mandirDetailsContent", mandirDetailsContent);
    // formData.append("whatsappTemplateContent", whatsappTemplateContent);
    formData.append("poojaSelectionTitle", poojaSelectionTitle);
    inputFieldsSec.forEach((el ,i ) =>{
      formData.append(`poojaSelectionOptions[${i}][title]`, el.title);
      formData.append(`poojaSelectionOptions[${i}][subtitle]`, el.subtitle);
      formData.append(`poojaSelectionOptions[${i}][planImage]`, el.planImage);
      formData.append(`poojaSelectionOptions[${i}][price]`, el.price);
      formData.append(`poojaSelectionOptions[${i}][members]`, el.members);
      formData.append(`poojaSelectionOptions[${i}][globalPrice]`,el.globalPrice);
      formData.append(`poojaSelectionOptions[${i}][paymentLink]`, el.paymentLink);
      formData.append(`poojaSelectionOptions[${i}][isGlobal]`, el.isGlobal);
     

      // formData.append(`poojaSelectionOptions[${i}][content]`, el.content);
      
    })
    
    formData.append("prasadPrice", prasadPrice);
    formData.append("dakshinaPrice", dakshinaPrice);
    formData.append("prasadPriceGlobal", prasadPriceGlobal);
    formData.append("dakshinaPriceGlobal", dakshinaPriceGlobal);

    formData.append("IsPrasadPriceInr", prasadPriceStatusInr);
    formData.append("IsDakshinaPriceInr", dakshinaStatusInr);
    formData.append("IsPrasadPriceUsd", prasadPriceStatusUsd);
    formData.append("IsDakshinaPriceUsd", dakshinaStatusUsd);

    formData.append("chadhawaSamgriIDs", JSON.stringify(selectedOptions));

    
    formData.append("languageType", contentType);
    formData.append("userId", astroUserId);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };

      const newErrors = validateInputs();
      // const newChadhawaError = validateChadhawaInput()
      setLoader(true)
      // || Object.keys(newChadhawaError).length === 0
      if(Object.keys(newErrors).length > 0 ) {
        setErrors(newErrors);
        // setChadhawaError(newChadhawaError)
        setLoader(false)
      }else{
        let response = await APIServices.put(
        `astroPooja/editOne/${selectedId}`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status) {
          toast.success(apiData?.message);
          updateFile()
          // setPosterCropImg()
          setBannerCropImg()
          setmandirCropImg()
          setwhatsappCropImg()
          setpujaCropImg()
          setFileCpujaimage()
          setRindex()
          setLoader(false)
          Navigate('/puja')
        }else {
          let errData = response?.data;
          if(errData && errData.message){
            toast.error(errData?.message);
          }
          setLoader(false)
        }
      }else if(response?.status === 400){
        let errData = response?.data;
        if(errData && errData.message){
          toast.error(errData?.message);
        }
      }
      setLoader(false)
    }
  }
 

  const validateInputs = () => {
    let isValid = false;
    let isLocalCantentValid=false;

    const newErrors = {};
    const inputFieldsErrors = [];

    inputFields?.forEach((field, index) => {
      let errorMsgs = {
        title:"",
        content:"",
        image:"",
      };
      Object.keys(field).forEach((key) => {

        if (field[key] === "") {
        isLocalCantentValid=true;
          let errMsg = `Please add Puja Labh ${
            key === "fileM" ? "image" : key
          }.`;
          errorMsgs[ key === "fileM" ? "image":key] = (errMsg);
        }
      });
      inputFieldsErrors.push(errorMsgs);

    });
 
    // const inputFieldsErrorsN = [];

    // inputFieldsN?.forEach((field, index) => {
    //   let errorMsgs = {
    //     title:"" ,
    //     content:"",
    //   };

    //   Object.keys(field).forEach((key) => {
    //     if (field[key] === "") {

    //     isLocalCantentValid=true;
    //       let errMsg = `Please add puja Method ${key}.`;
    //       errorMsgs[key] = (errMsg);
    //     }
    //   });
    //   inputFieldsErrorsN.push(errorMsgs);
      
    // });

    const inputFieldsErrorsSec = [];
    inputFieldsSec?.forEach((field, index) => {
      let errorMsgs =  {
        title: "",
        subtitle: "",
        planImage:"",
        price: "",
        prasad_price:"",
        dakshina_price:"",
        members:"",
        globalPrice:"",
        isGlobal:""
        

        // paymentLink: "",
        // content: "",
      };

       if (field?.paymentLink || field?.paymentLink == "") {
        delete field?.paymentLink
       }

      Object.keys(field).forEach((key) => {
        if (field[key] === "" || field[key] === 'undefined') {
        isLocalCantentValid=true;
        let errMsg = `Please add puja selection ${key === "globalPrice" ? "global Price" : key === "subtitle"? "sub title":key === "planImage"? "plan Image": key === "isGlobal"? "Global Plan": key}.`;
        //  if (isGlobalPrice === false && key === "globalPrice") {
        //    errMsg = '';
        //  }
          errorMsgs[key]=(errMsg);
        }
      });
      inputFieldsErrorsSec.push(errorMsgs);
      
    });

    if (!title ||title === "") 
    newErrors.title = "Please enter title.";

    if (!subTitle ||subTitle === "") 
    newErrors.subTitle = "Please enter Sub Title.";

    // if (pujaFeatures && pujaFeatures === 0) 
    if(!pujaFeatures || pujaFeatures === "")
    newErrors.pujaFeatures = "Please enter Puja Features.";

    // if (!titleEn ||titleEn === "") 
    // newErrors.titleEn = "Please enter titleEn.";

    if (!slug ||slug === "") 
    newErrors.slug = "Please enter slug.";

    //  if (contentType === "") 
    // newErrors.contentType = "Please select content type.";

    if (posterImage === "" && (!posterImagePreview || posterImagePreview === "")) 
    newErrors.posterImage = "Please add puja poster image.";

    if (content === "") 
    newErrors.content = "Please enter content.";

    if (address === "") 
    newErrors.address = "Please enter address.";
 
    if (date === "") 
    newErrors.date = "Please enter day.";

    if (!pujaDate || pujaDate === null)
    newErrors.pujaDate = "Please select puja date."

    if (file1 === "" && (!file2 || (file2 && file2.length === 0))) 
    newErrors.pujaImages = "Please add puja Images.";

    let maxLength = 6
    if (formData && formData.length > maxLength) 
    newErrors.pujaImages = "Images can't be more then 6";

    if (subContent === "") 
    newErrors.subContent = "Please enter sub content.";

    if (poojaLabhTitle === "") 
    newErrors.poojaLabhTitle = "Please enter puja labh header.";

    if (isLocalCantentValid && inputFieldsErrors?.length > 0) 
    newErrors.contentPujaLabh = inputFieldsErrors

    
    // if (poojaMethodTitle === "") 
    // newErrors.poojaMethodTitle = "Please enter puja method header.";
  
    // if (isLocalCantentValid && inputFieldsErrorsN?.length > 0) 
    // newErrors.contentPujaMethod = inputFieldsErrorsN


    if (mandirDetailsHeader === "") 
    newErrors.mandirDetailsHeader = "Please enter mandir details header.";
  
    
    if (mandirDetailsTitle === "")
    newErrors.mandirDetailsTitle = "Please enter mandir details title.";

   if (mandirDetailsContent === "")
    newErrors.mandirDetailsContent = "Please enter mandir details content.";
   
   if (file === "" && !img)
   newErrors.mandirDetailsImage = "Please enter mandir details image.";
   
   if (poojaSelectionTitle === "") 
   newErrors.poojaSelectionTitle = "Please enter puja selection header.";

    if (isLocalCantentValid && inputFieldsErrorsSec.length > 0) 
    newErrors.PujaSelectionContent= inputFieldsErrorsSec

    if (prasadPrice === "") 
    newErrors.prasadPrice = "Please enter prasadPrice.";

    if (dakshinaPrice === "") 
    newErrors.dakshinaPrice = "Please enter dakshinaPrice.";

    if (prasadPriceGlobal === "") 
    newErrors.prasadPriceGlobal = "Please enter prasadPriceGlobal.";

    if (dakshinaPriceGlobal === "") 
    newErrors.dakshinaPriceGlobal = "Please enter dakshinaPriceGlobal.";

    if (prasadPriceStatusInr === "") 
    newErrors.prasadPriceStatusInr = "Please enter Prasad Price Status.";

    if (dakshinaStatusInr === "") 
    newErrors.dakshinaStatusInr = "Please enter Dakshina Status.";

    if (prasadPriceStatusUsd === "") 
    newErrors.prasadPriceStatusUsd = "Please enter Prasad Price Status in Usd.";

    if (dakshinaStatusUsd === "") {
      newErrors.dakshinaStatusUsd = "Please enter Dakshina Status in Usd.";
    }

    // if (selectedOptions.length === 0 ||  selectedOptions === null || selectedOptions === undefined) 
    // newErrors.selectedOptions = "Please select Chadhawa samagri.";


  //   if (whatsappTemplateImage === "" && (!whatsappTemplateImagePreview || whatsappTemplateImagePreview === ""))
  // newErrors.whatsappTemplateImage = "Please add whatsapp template image.";
    

  // if (whatsappTemplateContent === "")
  // newErrors.whatsappTemplateContent = "Please enter whatsapp template content.";
  
    return newErrors;
  }

  async function handleCreate() {

    const formData = new FormData();
    formData.append("title", title);
    formData.append("subTitle", subTitle);
    formData.append("pujaFeatures", pujaFeatures);
    // formData.append("titleEn", titleEn);
    formData.append("slug", slug);
    formData.append("isGlobalPrice" , isGlobalPrice);
    //  if (contentType === "english") {
    //   formData.append("contentLangEn", true);
    // } else if (contentType === "hindi") {
    //   formData.append("contentLangHi", true);
    // }
    formData.append("content", content);
    formData.append("address", address);
    formData.append("date", date);
    const pujaD = formateDateAndTime(pujaDate)

    formData.append("pujaDate", pujaD);

    if (typeof file1 === "object") {
      for (const key of Object.keys(file1)) {
        formData.append("poojaImages", file1[key]);
      }
    }
    formData.append("subContent", subContent);
    formData.append("poojaLabhTitle", poojaLabhTitle);
    inputFields?.forEach((element, i) => {
      formData.append(`poojaLabhContents[${i}][title]`, element.title);
      formData.append(`poojaLabhContents[${i}][content]`, element.content);
      formData.append(`poojaLabhContents[${i}][fileM]`, element.fileM);
    });
    // formData.append("poojaMethodTitle", poojaMethodTitle);
    // formData.append("poojaMethodContents", JSON.stringify(inputFieldsN));
    formData.append("mandirDetailsHeader", mandirDetailsHeader);
    formData.append("mandirDetailsTitle", mandirDetailsTitle);
    formData.append("mandirDetailsContent", mandirDetailsContent);
    // formData.append("whatsappTemplateContent", whatsappTemplateContent);
    formData.append("mandirDetailsImage", file);
    // formData.append("whatsappTemplateImage", whatsappTemplateImage);
    formData.append("posterImage", posterImage);
    // if (typeof bannerImage === "object") {
    //   formData.append("bannerImage", bannerImage);
    // }
    formData.append("poojaSelectionTitle", poojaSelectionTitle);
    // formData.append("poojaSelectionOptions", JSON.stringify(inputFieldsSec));
    inputFieldsSec?.forEach((el ,i ) =>{
      formData.append(`poojaSelectionOptions[${i}][title]`, el.title);
      formData.append(`poojaSelectionOptions[${i}][subtitle]`, el.subtitle);
      formData.append(`poojaSelectionOptions[${i}][planImage]`, el.planImage);
      formData.append(`poojaSelectionOptions[${i}][price]`, el.price);
      formData.append(`poojaSelectionOptions[${i}][members]`, el.members);
      formData.append(`poojaSelectionOptions[${i}][globalPrice]`,el.globalPrice);
      formData.append(`poojaSelectionOptions[${i}][paymentLink]`, el.paymentLink);
      formData.append(`poojaSelectionOptions[${i}][isGlobal]`, el.isGlobal);

      // formData.append(`poojaSelectionOptions[${i}][content]`, el.content);
   
    })
    formData.append("prasadPrice", prasadPrice ?? "51");
    formData.append("dakshinaPrice", dakshinaPrice ?? "101,151,201");
    formData.append("prasadPriceGlobal", prasadPriceGlobal ?? "21");
    formData.append("dakshinaPriceGlobal", dakshinaPriceGlobal ?? "51,101,151,201");

    formData.append("IsPrasadPriceInr", prasadPriceStatusInr);
    formData.append("IsDakshinaPriceInr", dakshinaStatusInr);
    formData.append("IsPrasadPriceUsd", prasadPriceStatusUsd);
    formData.append("IsDakshinaPriceUsd", dakshinaStatusUsd);

    formData.append("chadhawaSamgriIDs", JSON.stringify(selectedOptions));

    formData.append("userId", astroUserId);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const newErrors = validateInputs();
    // const newChadhawaError = validateChadhawaInput()
    setLoader(true)
    // || Object.keys(newChadhawaError).length === 0
    if(Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors);
      // setChadhawaError(newChadhawaError)
      setLoader(false)
    }else{
      let response = await APIServices.post(
        `astroPooja/upload`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status) {
          toast.success(apiData?.message);
          updateFile()
          // setPosterCropImg()
          setBannerCropImg()
          setmandirCropImg()
          setFileCpujaimage()
          setwhatsappCropImg()
          setpujaCropImg()
          setRindex()
          setLoader(false)
          setSelectedOptions([])
          setPrasadPriceStatusInr('')
          setDakshinaStatusInr('')
          setPrasadPriceStatusUsd('')
          setDakshinaStatusUsd('')
          Navigate('/puja')
        }else{
          let errData = response?.data;
          setLoader(false)
          if(errData && errData.message){
            toast.error(errData?.message);
          }
        }
      }else if(response?.status === 400){
        let errData = response?.data;
        if(errData && errData.message){
          toast.error(errData?.message);
        }
      }
      setLoader(false)
    }
  }

  async function updateFile() {
    let res = await APIServices.get(`astroPooja/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        console.log("🚀 ~ updateFile ")
        
      }
    }
  }
 


  // const[showcropposter,setShowCropposter]=useState(false)
  // const[postercropimg,setPosterCropImg]=useState()
  const[cposterfileimage,setFileCposterimage]=useState()

  // useEffect(()=>{
  //   if(cposterfileimage){
  //   onImageChangePoster(cposterfileimage)
  //   }
  // },[postercropimg,cposterfileimage])

  const[showcropbanner,setShowCropbanner]=useState(false)
  const[bannercropimg,setBannerCropImg]=useState()
  const[cbannerfileimage,setFileCbannerimage]=useState()

  // useEffect(()=>{
  //   if(cbannerfileimage){
  //   onImageChangeBanner(cbannerfileimage)
  //   }
  // },[bannercropimg,cbannerfileimage])



  const[showcropmandir,setShowCropmandir]=useState(false)
  const[mandircropimg,setmandirCropImg]=useState()
  const[cmandirfileimage,setFileCmandirimage]=useState()

  useEffect(()=>{
    if(cmandirfileimage){
    onImageChangeth(cmandirfileimage)
    }
  },[mandircropimg,cmandirfileimage])

  const[showcropwhatsapp,setShowCropwhatsapp]=useState(false)
  const[whatsappcropimg,setwhatsappCropImg]=useState()
  const[cwhatsappfileimage,setFileCwhatsappimage]=useState()

  useEffect(()=>{
    if(cwhatsappfileimage){
      onImageChangeWhatsapp(cwhatsappfileimage)
    }
  },[whatsappcropimg,cwhatsappfileimage])

  const[showcroppuja,setShowCroppuja]=useState(false)
  const[pujacropimg,setpujaCropImg]=useState()
  const[cpujafileimage,setFileCpujaimage]=useState()
  const[rindex,setRindex]=useState()

  
  useEffect(()=>{
    if(cpujafileimage){
      handleImage(rindex, cpujafileimage, "file")
    }
    
  },[pujacropimg,cpujafileimage])

  return (
    <>
      <>
        <div className="main aarti_section">
          <>
            <div className="gita_quote_heading">
              <div className="container d-flex align-items-center justify-content-between">
                <h4 className="hd-list-f left-q">    
                 <FaArrowLeft
                 className="hd-cr "
                 style={{marginRight: "15px", width:"20px"}}
                 size={25}
                 onClick={() => Navigate(-1)}/>
                 {`${selectedId ? `Edit/ ${capitalizeFirstLetter(language)}` : 'Add/English'} Puja`}</h4>
                
                <div className="_btn">
            
                </div>
              </div>
            </div>

            {/* <div className="aarti_inner pt-5">
              <div className="container">
                <div className="row">
                 
                </div>
              </div>
            </div> */}
          </>
        </div>
      </>
      <div className="container">
            <div className="notifiction_form box-shadow br-6 p-20 my-4">
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="required" column sm="4">
                Title
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="Title..."
                  name='title'
                  defaultValue={title}
                  isInvalid={!!errors?.title}
                  className="form-control "
                  onChange={(e) => {
                    setTitle(e.target.value);handleErrors(e)
                  }}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.title}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="">
              <Form.Label className="required" column sm="4">
                Sub Title
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="Sub Title..."
                  name='subTitle'
                  defaultValue={subTitle}
                  isInvalid={!!errors?.subTitle}
                  className="form-control "
                  onChange={(e) => {
                    setSubTitle(e.target.value);handleErrors(e)
                  }}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.subTitle}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Puja Features (Comma-separated points)
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        as="textarea"
                        style={{ height: 100 }}
                        defaultValue={pujaFeatures}
                        isInvalid={!!errors?.pujaFeatures}
                        onChange={(e) => {
                          setPujaFeatures(e.target.value);handleErrors(e)
                        }}
                        name="pujaFeatures"
                        className="form-control"
                        placeholder="Puja Features..."
                      />
                       <Form.Control.Feedback type="invalid">
                    {errors?.pujaFeatures}
                  </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
             {selectedId? 
              <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="" column sm="4">
                Slug
              </Form.Label>
              <Col sm="8">
              {slug}
              </Col>
            </Form.Group>
              :<>
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Slug
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name='slug'
                  placeholder="Slug..."
                  className="form-control "
                  isInvalid={!!errors?.slug} 
                  onChange={(e) => {setSlug(e.target.value) ; handleErrors(e)}}
                  value={slug}
                  onBlur={(e) => { setSlug(SlugifyStringWithDash(e.target.value)) ; handleErrors(e)}}

                />
                  <Form.Control.Feedback type="invalid">
                  {errors?.slug}
                </Form.Control.Feedback>
                <small>Slug only created one time not editable.</small>
              </Col>
            </Form.Group></>}
           

            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="" column sm="4">
                Is Global Price
              </Form.Label>
              <Col sm="8">
              <Form.Group as={Col} size="md" style={{ marginTop: "8px" }}>
                  <input
                    class="form-check-input"
                    // value={isGlobalPrice}
                    checked= {isGlobalPrice}
                    type="radio"
                    onChange={(e) => setIsGlobalPrice(true)}
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    style={{ marginRight: "10px" }}
                  />
                  <Form.Label class="form-check-label" for="flexRadioDefault1">
                   True
                  </Form.Label>
                  <input
                    class="form-check-input"
                    // value={isGlobalPrice}
                    checked= {!isGlobalPrice}
                    type="radio"
                    onChange={(e) => setIsGlobalPrice(false)}
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                  />
                  <Form.Label class="form-check-label" for="flexRadioDefault2">
                    False
                  </Form.Label>
                </Form.Group>
              </Col>
            </Form.Group>
            {/* <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="" column sm="4">
                Banner Image
              </Form.Label>
              <Col sm="8">
              <label id="labimg">
              
              {bannercropimg?<img 
                // crossOrigin="anonymous" 
              className="imgs" 
              src={bannercropimg}
              onClick={()=>setBannerCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={ bannerImage
                ? bannerImage : bannerImagePreview ? bannerImagePreview : upimg}
              width="100%" alt="" />}

                <Form.Control
                // type="file"
                name="bannerImage"
                className="form-control"
                onClick={(e)=>{setShowCropbanner(true);handleErrors(e)}}
               
                size="40"
                accept="image/png, image/gif, image/jpeg"
                id="inputds"
              />
                </label>
              </Col>
            </Form.Group> */}
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Poster Image
              </Form.Label>
              <Col sm="8">
              <label id="labimg">
          
              <img 
              className="imgs" 
              src={ posterImagePreview ? posterImagePreview : upimg}
               
              width="100%" alt="" />

                <Form.Control
                type="button"
                name="posterImage"
                isInvalid={!!errors?.posterImage}
                // className="form-control"
                // onChange={(e)=>{onImageChangePoster(e);handleErrors(e)}}
                onClick={(e)=>{handleOpenModal('posterImage');handleErrors(e)}}               
                // size="40"
                // accept="image/png, image/gif, image/jpeg"
                // id="inputds"
              />
               <Form.Control.Feedback type="invalid">
            {errors?.posterImage}
           </Form.Control.Feedback>
                </label>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="">
              <Form.Label className="required" column sm="4">
                Content
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  as="textarea"
                  style={{ height: 100 }}
                  name='content'
                  defaultValue={content}
                  isInvalid={!!errors?.content}
                  placeholder="Content..."
                  className="form-control "
                  onChange={(e) => {setContent(e.target.value); handleErrors(e)}}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.content}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="">
              <Form.Label className="required" column sm="4">
                Address
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name='address'
                  defaultValue={address}
                  isInvalid={!!errors?.address}
                  placeholder="Address..."
                  className="form-control "
                  onChange={(e) =>{ setAddress(e.target.value);handleErrors(e)}}
                />
                   <Form.Control.Feedback type="invalid">
                {errors?.address}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
             Day
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="Day..."
                  name='date'
                  defaultValue={date}
                  isInvalid={!!errors?.date}
                  className="form-control "
                  onChange={(e) =>{ setDate(e.target.value); handleErrors(e)}}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.date}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3"  controlId= "">
                  <Form.Label className="required" column sm="4">Puja Date</Form.Label>
              <Col sm="8">
              <DatePicker
                    showTimeSelect
                  
                    placeholderText={'Please select a date'} 
                    className='form-control react-datepicker__input-container'
                    selected={pujaDate}
                    onChange={(date) => {setPujaDate(date);handleErrors("pujaDate")}}
                    dateFormat="MMMM d, yyyy h:mmaa"
       
             />
               <p className="errorVal">{errors?.pujaDate}</p>
                  {/* <Form.Control
                    type="date"
                    name="pujaDate"
                    placeholder="puja date"
                    isInvalid={!!errors?.pujaDate}
                    style={{cursor:"pointer"}}
                    min={formateDate(today)}
                    value={pujaDate}
                    onChange={(e) => {setPujaDate(e.target.value);handleErrors(e)}}
                  />
                    <Form.Control.Feedback type="invalid">
                   {errors?.pujaDate}
                  </Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
            {file2
                    ? 
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="" column sm="4">
                Previous
              </Form.Label>
              <Col sm="8">
                <div class="gallery">
                 {file2?.map((item, key) => (
                        <img
                          // crossOrigin="anonymous"
                          src={`${item}`}
                          width="50px"
                          alt=""
                        />
                      ))}
                </div>
              </Col>
            </Form.Group>
                    : ""}

            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Upload images
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  // type="file"
                  type="button"
                  value="Select Images"
                  title="Select Images" 
                  name="pujaImages"
                  isInvalid={!!errors?.pujaImages}
                  // className="form-control "
                  // onChange={onImageChange}
                  onClick={(e)=>{handleOpenModal('multiImg');handleErrors(e)}}   
                  size="40"
                  // accept="image/png, image/gif, image/jpeg"
                  // multiple
                />
                {formData?.length==0?"":
                <div class="gallery">
                {formData
                  ? formData?.map((item, key) => (
                      <img width="60px" height="60px" src={item} alt="" />
                    ))
                  : ""}
                </div>}
                <small>Maximum Limit 6</small>
                <Form.Control.Feedback type="invalid">
            {errors?.pujaImages}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Sub Content
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name='subContent'
                  defaultValue={subContent}
                  isInvalid={!!errors?.subContent}
                  as="textarea"
                  style={{ height: 100 }}
                  placeholder="Sub Content..."
                  className="form-control "
                  onChange={(e) =>{ setSubContent(e.target.value);handleErrors(e)}}
                />
                  <Form.Control.Feedback type="invalid">
                {errors?.subContent}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <hr style={{ color: "brown", margin:"1.5rem 0px" }} />
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Puja Labh Header
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name='poojaLabhTitle'
                  defaultValue={poojaLabhTitle}
                  isInvalid={!!errors?.poojaLabhTitle}
                  placeholder="Puja Labh Header..."
                  className="form-control "
                  onChange={(e) => {setPoojaLabhTitle(e.target.value);handleErrors(e)}}
                />
                   <Form.Control.Feedback type="invalid">
                {errors?.poojaLabhTitle}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            {inputFields?.map((data, index) => {
              const { title, content,fileM, preview_url } = data;
              return (
                <div className=" my-3" key={index}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Title
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        onChange={(evnt) => handleChange(index, evnt, "text")}
                        name="title"
                        isInvalid={!!errors?.contentPujaLabh?.[index]?.title}
                        value={title}
                        className="form-control"
                        placeholder="Title..."
                      />
                    <Form.Control.Feedback type="invalid">
                    {errors?.contentPujaLabh?.[index]?.title}
                  </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Content
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        as="textarea"
                        onChange={(evnt) => handleChange(index, evnt, "text")}
                        style={{ height: 100 }}
                        name="content"
                        isInvalid={!!errors?.contentPujaLabh?.[index]?.content}
                        value={content}
                        className="form-control"
                        placeholder="Content..."
                      />
                      <Form.Control.Feedback type="invalid">
                    {errors?.contentPujaLabh?.[index]?.content}
                  </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Puja Labh Image
                    </Form.Label>
                    <Col sm="8">
                      <label id="labimg">
                       <Form.Control
                        // type="file"
                        className="form-control "
                        // onClick={(e)=>{setShowCroppuja(true); setRindex(index) ;handleChange( index,e)}}
                         onClick={(e) => {handleOpenModal('pujaLabhImg', index);handleChange(index,e)}}
                        name="image"
                        isInvalid={!!errors?.contentPujaLabh?.[index]?.image}
                        size="40"
                        accept="image/png, image/gif, image/jpeg"
                        id="inputds"
                      />
                    
                      <img 
                      className="imgs" 
                      src={ preview_url
                        ? preview_url
                        : fileM
                        ? fileM
                        : upimg}
                      width="100%" alt="" />
                  <Form.Control.Feedback type="invalid">
                    {errors?.contentPujaLabh?.[index]?.image}
                  </Form.Control.Feedback>

                      </label>
                     
                    </Col>
                    {/* <Col sm="1"> */}
                    <div>
                      {inputFields.length !== 1 ? (
                        // <FaMinus
                        //   className="mt-3 hd-cr"
                        //   title="Remove Section"
                        //   onClick={() => removeInputFields(index)}
                        // />
                        <div style={{ display:"flex",color:"red",alignItems:"center",justifyContent:"end",cursor: 'pointer', }}> 
               
                        
                                <Button onClick={() => removeInputFields(index)} className="Dt-btn add-btn1">
                                        Remove
                                        <FaMinus
                                        className="hd-cr fw-bold"
                                        title="Remove Section"/>
                                    </Button>
         
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* </Col> */}
                  </Form.Group>
                </div>
              );
            })}
            <div style={{ display:"flex",alignItems:"center",justifyContent:"end",color:"green",cursor: 'pointer', }}> 
               
            <Button onClick={addInputField} className="Et-btn add-btn1 btn-success1">
                Add
                <FaPlus
                  className="hd-cr"
                  title="Add Section"/>
              </Button>

            </div>
            
            {/* <hr style={{ color: "brown" }} />
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Puja Method Header
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name='poojaMethodTitle'
                  defaultValue={poojaMethodTitle}
                  isInvalid={!!errors?.poojaMethodTitle}
                  className="form-control "
                  placeholder="Puja Method Header..."
                  onChange={(e) =>{ setPoojaMethodTitle(e.target.value);handleErrors(e)}}
                />
                  <Form.Control.Feedback type="invalid">
                {errors?.poojaMethodTitle}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            {inputFieldsN?.map((data, index) => {
              const { title, content } = data;
              return (
                <div className=" my-3" key={index}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Title
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        onChange={(evnt) => handleChangeN(index, evnt)}
                         value={title}
                        name="title"
                        isInvalid={!!errors?.contentPujaMethod?.[index]?.title}
                        className="form-control"
                        placeholder="Title..."
                      />
                       <Form.Control.Feedback type="invalid">
                    {errors?.contentPujaMethod?.[index]?.title}
                  </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Content
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        as="textarea"
                        style={{ height: 150 }}
                        onChange={(evnt) => handleChangeN(index, evnt)}
                         value={content}
                        isInvalid={!!errors?.contentPujaMethod?.[index]?.content}
                        name="content"
                        className="form-control"
                        placeholder="Content..."
                      />
                       <Form.Control.Feedback type="invalid">
                    {errors?.contentPujaMethod?.[index]?.content}
                  </Form.Control.Feedback>
                    </Col>
                    <div className="mrfloat">
                    {inputFieldsN.length !== 1 ? (
                      <FaMinus
                        className="mt-3 hd-cr"
                        title="Remove Section"
                        onClick={() => removeInputFieldsN(index)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  </Form.Group>
                </div>
              );
            })}
            <FaPlus
              className="mt-3 hd-cr"
              title="Add Section"
              style={{ marginLeft: "97%" }}
              onClick={addInputFieldN}
            /> */}
            <hr style={{ color: "brown", margin:"1.5rem 0px" }} />

            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Mandir Details Header
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name='mandirDetailsHeader'
                  defaultValue={mandirDetailsHeader}
                  isInvalid={!!errors?.mandirDetailsHeader}
                  placeholder="Mandir Details Header..."
                  className="form-control "
                  onChange={(e) => {setMandirDetailsHeader(e.target.value);handleErrors(e)}}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.mandirDetailsHeader}
               </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Mandir Details Title
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name='mandirDetailsTitle'
                  defaultValue={mandirDetailsTitle}
                  isInvalid={!!errors?.mandirDetailsTitle}
                  placeholder="Mandir Details Title..."
                  className="form-control "
                  onChange={(e) => {setMandirDetailsTitle(e.target.value);handleErrors(e)}}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.mandirDetailsTitle}
               </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Mandir Details Content
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  as="textarea"
                  name='mandirDetailsContent'
                  defaultValue={mandirDetailsContent}
                  isInvalid={!!errors?.mandirDetailsContent}
                  style={{ height: 100 }}
                  placeholder="Mandir Details Content..."
                  className="form-control "
                  onChange={(e) => {setMandirDetailsContent(e.target.value);handleErrors(e)}}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.mandirDetailsContent}
               </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
              Mandir Details Image
              </Form.Label>
              <label id="labimg">
               
              {mandircropimg?<img 
                // crossOrigin="anonymous" 
              className="imgs" 
              src={mandircropimg}
              onClick={()=>setmandirCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={ showImg3 ? showImg3:img?img: upimg}
              width="100%" alt="" />}
              
              <Form.Control
                // type="file"
                name="mandirDetailsImage"
                className="form-control "
                // onClick={(e)=>{setShowCropmandir(true);handleErrors(e)}}
                onClick={(e)=>{handleOpenModal('mandirDetails');handleErrors(e)}}   
                isInvalid={!!errors?.mandirDetailsImage}
                size="40"
                accept="image/png, image/gif, image/jpeg"
                id="inputds"
              />

             <Form.Control.Feedback type="invalid">
                {errors?.mandirDetailsImage}
               </Form.Control.Feedback>
              </label>
            </Form.Group>
            <hr style={{ color: "brown", margin:"1.5rem 0px" }} />
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="2">
              Puja Selection Header
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  name="poojaSelectionTitle"
                  placeholder="puja Selection Header..."
                  isInvalid={!!errors?.poojaSelectionTitle}
                  value={poojaSelectionTitle}
                  className="form-control "
                  onChange={(e) => {setPoojaSelectionTitle(e.target.value);handleErrors(e)}}
                />

              <Form.Control.Feedback type="invalid">
                {errors?.poojaSelectionTitle}
               </Form.Control.Feedback>
              </Col>
            </Form.Group>
            {inputFieldsSec?.map((data, index) => {
             
              const { title, subtitle,planImage, price, members,globalPrice, paymentLink, content, isGlobal } = data;
              return (
                <div className=" my-3" key={index}>
                  {/* <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                        <Form.Label className="required" column sm="4">
                          Title
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            value={title}
                            name="title"
                            isInvalid={!!errors?.PujaSelectionContent?.[index]?.title}
                            onChange={(evnt) => handleChangeSec(index, evnt)}
                            // value={title}
                            className="form-control"
                            placeholder="Title..."
                          />
                        <Form.Control.Feedback type="invalid">
                        {errors?.PujaSelectionContent?.[index]?.title}
                      </Form.Control.Feedback>

                        </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Sub Title
                    </Form.Label>
                    <Col sm="5">
                      <Form.Control
                        type="text"
                        name="subtitle"
                        isInvalid={!!errors?.PujaSelectionContent?.[index]?.subtitle}
                        onChange={(evnt) => handleChangeSec(index, evnt)}
                        value={subtitle}
                        className="form-control"
                        placeholder="Sub Title..."
                      />
                        <Form.Control.Feedback type="invalid">
                    {errors?.PujaSelectionContent?.[index]?.subtitle}
                  </Form.Control.Feedback>
                    </Col>
                  </Form.Group> */}
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Form.Group controlId="" as={Row}>
                        <Form.Label className="required" column sm="4">
                          Title
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            value={title}
                            name="title"
                            isInvalid={!!errors?.PujaSelectionContent?.[index]?.title}
                            onChange={(evnt) => handleChangeSec(index, evnt)}
                            className="form-control"
                            placeholder="Title..."
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.PujaSelectionContent?.[index]?.title}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col sm={6}>
                      <Form.Group controlId="" as={Row}>
                        <Form.Label className="required" column sm="4">
                          Sub Title
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            name="subtitle"
                            isInvalid={!!errors?.PujaSelectionContent?.[index]?.subtitle}
                            onChange={(evnt) => handleChangeSec(index, evnt)}
                            value={subtitle}
                            className="form-control"
                            placeholder="Sub Title..."
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.PujaSelectionContent?.[index]?.subtitle}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                      <Col sm={6}>
                          <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId= ""
                        >
                          <Form.Label className="required" column sm="4">
                            Plan Image Select
                          </Form.Label>
                          <Col sm="8">


                          <Form.Select
                        aria-label='label-selected-menu'
                        className='text-capitalize st-in-bdr-clr'
                        name="planImage"
                        isInvalid={!!errors?.PujaSelectionContent?.[index]?.planImage}
                        onChange={(evnt) => handleChangeSec(index, evnt)}
                        value={planImage}

                      >
                        <option className='' value='Select'>
                          Select
                        </option>
                        {planImgSelect && planImgSelect.map((item, index) => {
                          return (
                            <>
                              <option className='text-capitalize' value={item._id}>
                                {item.title}
                              </option>
                            </>
                          );
                        })}
                      </Form.Select>
                              <Form.Control.Feedback type="invalid">
                          {errors?.PujaSelectionContent?.[index]?.planImage}
                        </Form.Control.Feedback>
                          </Col>
                          </Form.Group>
                      </Col>
                    <Col sm={6}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId= ""
                      >
                        <Form.Label className="required" column sm="4">
                          INR Price
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            isInvalid={!!errors?.PujaSelectionContent?.[index]?.price}
                            onChange={(evnt) => handleChangeSec(index, evnt)}
                            onKeyPress={(evnt) => {
                              // Allow only numeric characters, backspace, and the decimal point
                              if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                                evnt.preventDefault();
                              }
                            }}
                            value={price}
                            name="price"
                            className="form-control"
                            placeholder="Price..."
                          />
                        <Form.Control.Feedback type="invalid">
                          {errors?.PujaSelectionContent?.[index]?.price}
                        </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                      <Col sm={6}>
                      <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Members
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        isInvalid={!!errors?.PujaSelectionContent?.[index]?.members}
                        onChange={(evnt) => handleChangeSec(index, evnt)}
                        onKeyPress={(evnt) => {
                          // Allow only numeric characters
                          if (!/^\d$/.test(evnt.key)) {
                            evnt.preventDefault();
                          }
                        }}
                        value={members}
                        name="members"
                        className="form-control"
                        placeholder="Members..."
                      />
                     <Form.Control.Feedback type="invalid">
                    {errors?.PujaSelectionContent?.[index]?.members}
                  </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                      </Col>
                      <Col sm={6}>
                          {/* { isGlobalPrice === true ?  */}
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                    Global Price
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        isInvalid={!!errors?.PujaSelectionContent?.[index]?.globalPrice}
                        onChange={(evnt) => handleChangeSec(index, evnt)}
                        onKeyPress={(evnt) => {
                          // Allow only numeric characters, backspace, and the decimal point
                          if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                            evnt.preventDefault();
                          }
                        }}
                        value={globalPrice}
                        name="globalPrice"
                        className="form-control"
                        placeholder="Global Price..."
                      />
                        <Form.Control.Feedback type="invalid">
                    {errors?.PujaSelectionContent?.[index]?.globalPrice}
                  </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  
                  {/* :""} */}
                      </Col>
                  </Row>
                  <Row className="mb-3">
                     <Col sm={6}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId= ""
                      >
                        <Form.Label className="" column sm="4">
                          Payment Link
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="url"
                            // isInvalid={!!errors?.PujaSelectionContent?.[index]?.paymentLink}
                            onChange={(evnt) => handleChangeSec(index, evnt)}
                            value={paymentLink ?? ""}
                            name="paymentLink"
                            className="form-control"
                            placeholder="Payment Link..."
                          />
                            {/* <Form.Control.Feedback type="invalid">
                        {errors?.PujaSelectionContent?.[index]?.paymentLink}
                      </Form.Control.Feedback> */}
                        </Col>
                            
                      </Form.Group>
                     </Col>
                     <Col sm={6}>
                          <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId= ""
                      >
                          <Form.Label className="required" column sm="4">
                             Show on Global
                          </Form.Label>
                      <Col sm="8">

                        <Form.Select
                              aria-label='label-selected-menu'
                              className='text-capitalize st-in-bdr-clr'
                              name="isGlobal"
                              isInvalid={!!errors?.PujaSelectionContent?.[index]?.isGlobal}
                              onChange={(evnt) => handleChangeSec(index, evnt)}
                              value={isGlobal}

                        >
                              <option className='' value='Select'>
                                 Select
                              </option>
                              <option className='text-capitalize' value="yes">
                                 Yes
                              </option>
                              <option className='text-capitalize' value="no">
                                 No
                              </option>
                      </Form.Select>
                              <Form.Control.Feedback type="invalid">
                          {errors?.PujaSelectionContent?.[index]?.isGlobal}
                        </Form.Control.Feedback>
                          </Col>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                    <Form.Group>
                            <div>
                              {inputFieldsSec.length !== 1 ? (
                                // <FaMinus
                                //   className="mt-3 hd-cr"
                                //   title="Remove Section"
                                //   onClick={() => removeInputFieldsSec(index)}
                                // />
                                <div style={{ display:"flex",alignItems:"center",justifyContent:"end",color:"red",cursor: 'pointer', }}> 
                  
                                    <Button onClick={() => removeInputFieldsSec(index)} className="Dt-btn mt-2 add-btn1">
                                        Remove
                                        <FaMinus
                                        className="hd-cr fw-bold"
                                        title="Remove Section"/>
                                    </Button>
            
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                    </Form.Group>
                  </Row>
               
                  {/* <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="required" column sm="4">
                      Content (Comma-separated points)
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        as="textarea"
                        style={{ height: 150 }}
                        isInvalid={!!errors?.PujaSelectionContent?.[index]?.content}
                        onChange={(evnt) => handleChangeSec(index, evnt)}
                        value={content}
                        name="content"
                        className="form-control"
                        placeholder="Content..."
                      />
                       <Form.Control.Feedback type="invalid">
                    {errors?.PujaSelectionContent?.[index]?.content}
                  </Form.Control.Feedback>
                    </Col>
                    
                  </Form.Group> */}
                 
                </div>
              );
            })}
            <div style={{  display:"flex",alignItems:"center",justifyContent:"end",cursor: 'pointer',color:"green" }}> 
               
              <Button onClick={addInputFieldSec} className="Et-btn add-btn1 btn-success1">
                Add
                <FaPlus
                  className="hd-cr"
                  title="Add Section"/>
              </Button>
             

            </div>
            {/* <FaPlus
              className="mt-3 hd-cr"
              title="Add Section"
              style={{ marginLeft: "97%", color:"green" }}
              onClick={addInputFieldSec}
            /> */}
           <hr style={{ color: "brown", margin:"1.5rem 0px" }} />

              <Row className="mb-3">
                <Col sm={6}>
                          <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId= ""
                      >
                          <Form.Label className="required" column sm="4">
                             Prasad(INR)
                          </Form.Label>
                      <Col sm="8">
                        <Form.Select
                              aria-label='label-selected-menu'
                              className='text-capitalize st-in-bdr-clr'
                              name="prasadPriceStatusInr"
                              isInvalid={!!errors?.prasadPriceStatusInr}
                              // onChange={(e) => {setPrasadPriceStatusInr(e.target.value)}}
                              // value={prasadPriceStatusInr}
                              onChange={(e) => {
                                const value = e.target.value;
                                setPrasadPriceStatusInr(value);
                                if (value) {
                                  setErrors((prev) => ({
                                    ...prev,
                                    prasadPriceStatusInr: null,
                                  }));
                                }
                              }}
                              value={prasadPriceStatusInr}
                        >
                              <option className='' value=''>
                                 Status
                              </option>
                              <option className='text-capitalize' value="yes">
                                 Yes
                              </option>
                              <option className='text-capitalize' value="no">
                                 No
                              </option>
                      </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                 {errors?.prasadPriceStatusInr}
                            </Form.Control.Feedback>
                          </Col>
                          </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="" column sm="3">
                      {/* Prasad Price(INR) */}
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        isInvalid={!!errors?.prasadPrice}
                        onChange={(evnt) =>{setPrasadPrice(evnt.target.value);handleErrors(evnt)}}
                        onKeyPress={(evnt) => {
                          if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                            evnt.preventDefault();
                          }
                        }}
                        value={prasadPrice}
                        name="prasadPrice"
                        className="form-control"
                        placeholder="Prasad Price..."
                      />
                     <Form.Control.Feedback type="invalid">
                       {errors?.prasadPrice}
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                
              </Row>
              <Row className="mb-3">
              <Col sm={6}>
                          <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId= ""
                      >
                          <Form.Label className="required" column sm="4">
                             Dakshina(INR)
                          </Form.Label>
                      <Col sm="8">

                        <Form.Select
                              aria-label='label-selected-menu'
                              className='text-capitalize st-in-bdr-clr'
                              name="dakshinaStatusInr"
                              isInvalid={!!errors?.dakshinaStatusInr}
                              // onChange={(e)=> setDakshinaStatusInr(e.target.value)}
                              // value={dakshinaStatusInr}
                              onChange={(e) => {
                                const value = e.target.value;
                                setDakshinaStatusInr(value);
                                if (value) {
                                  setErrors((prev) => ({
                                    ...prev,
                                    dakshinaStatusInr: null,
                                  }));
                                }
                              }}
                              value={dakshinaStatusInr}
                        >
                              <option className='' value=''>
                                 Status
                              </option>
                              <option className='text-capitalize' value="yes">
                                 Yes
                              </option>
                              <option className='text-capitalize' value="no">
                                 No
                              </option>
                      </Form.Select>
                              <Form.Control.Feedback type="invalid">
                              {errors?.dakshinaStatusInr}
                        </Form.Control.Feedback>
                          </Col>
                          </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="" column sm="3">
                    {/* Dakshina Price(INR)<br/> (Comma-separated price) */}
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        isInvalid={!!errors?.dakshinaPrice}
                        onChange={(evnt) => {setDakshinaPrice(evnt.target.value);handleErrors(evnt)}}
                        value={dakshinaPrice}
                        name="dakshinaPrice"
                        className="form-control"
                        placeholder="Dakshina Price (Comma-separated price)"
                      />
                      
                     <Form.Control.Feedback type="invalid">
                       {errors?.dakshinaPrice}
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={6}>
                          <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId= ""
                      >
                          <Form.Label className="required" column sm="4">
                          Prasad(USD)
                          </Form.Label>
                      <Col sm="8">

                        <Form.Select
                              aria-label='label-selected-menu'
                              className='text-capitalize st-in-bdr-clr'
                              name="prasadPriceStatusUsd"
                              isInvalid={!!errors?.prasadPriceStatusUsd}
                              // onChange={(e)=> setPrasadPriceStatusUsd(e.target.value)}
                              onChange={(e) => {
                                const value = e.target.value;
                                setPrasadPriceStatusUsd(value);
                                if (value) {
                                  setErrors((prev) => ({
                                    ...prev,
                                    prasadPriceStatusUsd: null,
                                  }));
                                }
                              }}
                              value={prasadPriceStatusUsd}
                              // value={prasadPriceStatusUsd}
                        >
                              <option className='' value=''>
                                 Status
                              </option>
                              <option className='text-capitalize' value="yes">
                                 Yes
                              </option>
                              <option className='text-capitalize' value="no">
                                 No
                              </option>
                           </Form.Select>
                              <Form.Control.Feedback type="invalid"> 
                              {errors?.prasadPriceStatusUsd}
                           </Form.Control.Feedback>
                          </Col>
                          </Form.Group>
                </Col>
                <Col sm={6}>
                   <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="" column sm="3">
                    {/* Prasad Price(USD) */}
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        isInvalid={!!errors?.prasadPriceGlobal}
                        onChange={(evnt) => {setPrasadPriceGlobal(evnt.target.value);handleErrors(evnt)}}
                        onKeyPress={(evnt) => {
                          // Allow only numeric characters, backspace, and the decimal point
                          if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                            evnt.preventDefault();
                          }
                        }}
                        value={prasadPriceGlobal}
                        name="prasadPriceGlobal"
                        className="form-control"
                        placeholder="Prasad Price Global..."
                      />
                     <Form.Control.Feedback type="invalid">
                       {errors?.prasadPriceGlobal}
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                
              </Row>

              <Row className="mb-3">
                   <Col sm={6}>
                          <Form.Group
                          as={Row}
                          className=""
                          controlId="formTitleInputDakshinaUSD"
                      >
                          <Form.Label className="required" column sm="4">
                          Dakshina(USD)<br/> (Comma-separated)
                          </Form.Label>
                      <Col sm="8">

                           <Form.Select
                              aria-label='label-selected-menu'
                              className='text-capitalize'
                              name="dakshinaStatusUsd"
                              isInvalid={!!errors?.dakshinaStatusUsd}
                              // onChange={(e)=> { setDakshinaStatusUsd(e.target.value); 
                              // }}
                              onChange={(e) => {
                                const value = e.target.value;
                                setDakshinaStatusUsd(value);
                                if (value) {
                                  setErrors((prev) => ({
                                    ...prev,
                                    dakshinaStatusUsd: null,
                                  }));
                                }
                              }}
                              value={dakshinaStatusUsd}
                              // value={dakshinaStatusUsd}
                            >
                              <option className='' value=''>
                                 Status
                              </option>
                              <option className='text-capitalize' value="yes">
                                 Yes
                              </option>
                              <option className='text-capitalize' value="no">
                                 No
                              </option>
                            </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                 {errors?.dakshinaStatusUsd}
                             </Form.Control.Feedback>
                          </Col>
                          </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId= ""
                  >
                    <Form.Label className="" column sm="3">
                    {/* Dakshina Price(USD)<br/> (Comma-separated price) */}
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        isInvalid={!!errors?.dakshinaPriceGlobal}
                        onChange={(evnt) => {setDakshinaPriceGlobal(evnt.target.value);handleErrors(evnt)}}
                        value={dakshinaPriceGlobal}
                        name="dakshinaPriceGlobal"
                        className="form-control"
                        placeholder="Prasad Price Global (Comma-separated price)"
                      />
                     <Form.Control.Feedback type="invalid">
                       {errors?.dakshinaPriceGlobal}
                    </Form.Control.Feedback>
                    </Col>
                  </Form.Group> 
                </Col>
                
              </Row>
              <Row>
                 <Col md={12}>
                    <Form.Group as={Row} className="mb-3" controlId="selectedOptions">
                      <Col md={2}>
                        <Form.Label className=''>Select Chadhawa</Form.Label>

                      </Col>
                      <Col md={10}>
                        <Select
                          isMulti
                          autoComplete='new-password'
                          // isInvalid={!!errors?.selectedOptions}
                          options={options}
                          onChange={handleChangeChadhawaSamagri}
                          value={options?.filter(option => selectedOptions.includes(option.value))}
                          // className={chadhawaError?.selectedOptions ? 'is-invalid' : ''}
                        />
                        
                      </Col>
                     
                        
                        {/* <Form.Control.Feedback type="invalid">
                           {chadhawaError?.selectedOptions}
                         </Form.Control.Feedback> */}
                      </Form.Group>
                  </Col>
              </Row>






            {/* <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Whatsapp Template Image
              </Form.Label>
              <label id="labimg">
               
              {whatsappcropimg?<img 
                // crossOrigin="anonymous" 
              className="imgs" 
              src={whatsappcropimg}
              onClick={()=>setwhatsappCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={ showWhatsappTemplateImage ? showWhatsappTemplateImage:whatsappTemplateImagePreview ?whatsappTemplateImagePreview: upimg}
              width="100%" alt="" />}

                <Form.Control
                // type="file"
                className="form-control "
                onClick={(e)=>{setShowCropwhatsapp(true);handleErrors(e)}}
               name="whatsappTemplateImage"
               isInvalid={!!errors?.whatsappTemplateImage}

                size="40"
                accept="image/png, image/gif, image/jpeg"
                id="inputds"
              />
                 <Form.Control.Feedback type="invalid">
                    {errors?.whatsappTemplateImage}
                  </Form.Control.Feedback>
              </label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId= "">
              <Form.Label className="required" column sm="4">
                Whatsapp Template Content
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  as="textarea"
                  style={{ height: 150 }}
                  name="whatsappTemplateContent"
                  placeholder="Whatsapp Template Content..."
                  defaultValue={whatsappTemplateContent}
                   isInvalid={!!errors?.whatsappTemplateContent}
                  className="form-control "
                  onChange={(e) => {setWhatsappTemplateContent(e.target.value);handleErrors(e)}}
                />
                  <Form.Control.Feedback type="invalid">
                    {errors?.whatsappTemplateContent}
                  </Form.Control.Feedback>
              </Col>
            </Form.Group> */}
          </Form>
          <Row className="justify-content-center d-flex">
        {loader? <Button
          variant="primary"
          className="as_btn"
          
        >
          Processing...
        </Button>: <Button
        variant="primary"
        className="as_btn"
        onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}
      >
        {`${selectedId ? 'Edit Puja' : 'Add puja'}`}
      </Button>}
          {/* <Button variant="secondary"
          className="as_btn"
          onClick={handleClose}>
            Reset
          </Button> */}
          </Row>
          </div>
    </div>

      <ToastContainer />
      {/* <PosterCrop 
    showcropposter={showcropposter}
    setShowCropposter={setShowCropposter}
    setPosterCropImg={setPosterCropImg}
    postercropimg={postercropimg}
    cposterfileimage={cposterfileimage}
    setFileCposterimage={setFileCposterimage}/> */}

    <BannerCrop 
    showcropbanner={showcropbanner}
    setShowCropbanner={setShowCropbanner}
    setBannerCropImg={setBannerCropImg}
    bannercropimg={bannercropimg}
    cbannerfileimage={cbannerfileimage}
    setFileCbannerimage={setFileCbannerimage}/>

    <Mandirdetailcrop
    showcropmandir={showcropmandir}
    setShowCropmandir={setShowCropmandir}
    setmandirCropImg={setmandirCropImg}
    mandircropimg={mandircropimg}
    cmandirfileimage={cmandirfileimage}
    setFileCmandirimage={setFileCmandirimage}
    />
    <Whatsappcrop
    showcropwhatsapp={showcropwhatsapp}
    setShowCropwhatsapp={setShowCropwhatsapp}
    setwhatsappCropImg={setwhatsappCropImg}
    whatsappcropimg={whatsappcropimg}
    cwhatsappfileimage={cwhatsappfileimage}
    setFileCwhatsappimage={setFileCwhatsappimage}
    />
   <Pujacrop
    showcroppuja={showcroppuja}
    setShowCroppuja={setShowCroppuja}
    setpujaCropImg={setpujaCropImg}
    pujacropimg={pujacropimg}
    cpujafileimage={cpujafileimage}
    setFileCpujaimage={setFileCpujaimage}
              />

        <PujaImgGalleyModel
          id='ImgGalleyModel'
          show={showImageGalleyModel}
          onHide={() => setShowImageGalleyModel(false)}
          modalHeading={'ImgGalley'}
          size='lg'
          handleSetImageUrl={handleSetImageUrl}
          fieldKey={modalFieldKey}
          setClearSel={setClearSel}
          clearSel = {clearSel}
        />

        
   
    </>
  );
};

export default AddEditPoojaForm;
