import React, { createRef, useEffect, useState, useRef ,useContext ,useMemo} from "react";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bimg from "../imgs/default.jpg";
import Cookies from "universal-cookie";
import APIServicenew from "../utils/APIGeneralService";
import CropModal from "../components/Shared/CropModal";
import { formateDate ,getCookies,setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa';
import Select from 'react-select';


export const AddQuestion = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [name, setName] = useState();
  const [dateofbirth, setDateofbirth] = useState("00:00");
  const [mobile, setMoblie] = useState();
  const [place, setPlace] = useState();
  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [hours, setHours] = useState();
  const [minute, setMinute] = useState();
  const [source, setSource] = useState();
  const [problem, setProblem] = useState();
  const [gender, setGender] = useState();
  const [postType, setPostType] = useState();
  const navigate = useNavigate();
  const[loader,setLoader]=useState(false)
  const today =new Date()

  let nameRef = createRef();
  let mobileRef = createRef();
  let sourceRef = createRef();
  // let problemRef = createRef();
  let dobRef = createRef();
  let locationRef = useRef();
  let hourRef = createRef();
  let minuteRef = createRef();
  // let postTypeRef = createRef();
  let phoneCodeRef = createRef();
  const  {refreshToken} = useContext(UserContext);

  const [validated, setValidated] = useState(false);
  const token = getCookies("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBY6JZhuRymDmtxBlKJuSHLMmYJxDwF_4Y";
  const headersNew = { Authorization: `Bearer ${token}` };

  const onImageChange = (event) => {
    setFile(event);
    // setFile1(event.target.files[0])
    setField("mediaFile", event);
    // if (event.target.files && event.target.files[0]) {
    //   setFile(URL.createObjectURL(event.target.files[0]));
    //   // setFile1(event.target.files[0])
    //   setField("mediaFile", event.target.files[0]);
    // }
  };
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [caartifileimage,setFileCaartiimage]=useState()

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }

useEffect(()=>{
  if(caartifileimage){
    onImageChange(caartifileimage)
  }
},[cropimg,caartifileimage])

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  useEffect(() => {
    setField("location", place);
  }, [place]);

  const [countryList,setcountryList]=useState([])

  useEffect(() => {
    fetchCountryList()
  }, []);


  const fetchCountryList=async()=>{
    let response = await APIServices.get(`country/getPhoneCode`, headersNew);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setcountryList(apiData?.data)
      }
    }
  }

  const allOptions = useMemo(
    () =>
      countryList?.map((item) => ({
        // value: item.phoneCode,
        value: `${item.countryCode}_${item.phoneCode}`,
        label: (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center",  width: '70px'  }}>
            {item.phoneCode}({item.countryCode})
          </div>
        ),
        phoneCode: item.phoneCode,
        countryCode: item.countryCode,
      })) || [],
    [countryList]
  );

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected
        ? "#4caf50"
        : state.isFocused
        ? "#f0f0f0"
        : "white",
      // maxHeight: '80px', // Adjust this as needed for height
      overflowY: "auto", // Only the menu will handle scrolling
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0, // Remove any padding that might cause overflow
    }),
    option: (provided, state) => ({
      ...provided,
      // height: 25, // Adjust the height of each option
      display: "flex",
      alignItems: "center",
    }),
  };

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (allOptions.length > 0) {
      setSelectedOption(allOptions[0]);
      setField("phoneCode", allOptions[0]?.phoneCode);
      setField("countryCode",allOptions[0]?.countryCode)

    }
  }, [allOptions]);

  const handleChange = (option) => {
    setSelectedOption(option);
    setField("phoneCode", option.phoneCode);
    setField("countryCode",option.countryCode)


  }

  const findFormErrors = () => {
    const {
      name,
      mobile,
      // location,
      // hours,
      // minutes,
      source,
      // postType,
      // problem,
    } = form;

    const newErrors = {};

     if (!name || name === "") newErrors.name = "Please enter name.";
     else if (name.length > 15) newErrors.name = "Name is too long.";  

     if (!mobile || mobile === "")
       newErrors.mobile = "Please enter contact number.";
     else if (mobile.length > 10 || mobile.length < 10)
       newErrors.mobile = "Contact number cannot be less then 10 digits and more then 10 digits.";

    // if (!location || location === undefined)
    //   newErrors.location = "Please enter birth place!";

    // if ( !hours || hours === "")
    //   newErrors.hours = "Hours must be between 0 and 23!";

    // if (!minutes || minutes === "")
    //   newErrors.minutes = "Minutes must be between 0 and 59!";

    if (!source || source === "") newErrors.source = " Need to select source.";

    // if (!postType || postType === "")
    //   newErrors.postType = " Need to select post Type!";

    // if (!problem || problem === "")
    //   newErrors.problem = " Need to select problem!";

    return newErrors;
  };

  const clearfield = () => {
    setFile("");
    // nameRef.current.value = "";
    // mobileRef.current.value = "";
    // sourceRef.current.value = "";
    // problemRef.current.value = "";
    // dobRef.current.value = "";
    // hourRef.current.value = "";
    // minuteRef.current.value = "";
    // locationRef.current.value = "";
    // postTypeRef.current.value = "";
    setForm({});
    setLoader(false)
    setTimeout(() => {
    window.location.reload(false);
    }, 3000);

  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    const datainfos = {
      DOB: form?.dateofbirth??'',
      birthHour: form?.hours??'',
      birthMinute: form?.minutes??'',
      birthPlace: form?.location?.formatted_address??'',
      contactNumber: form?.mobile,
      gender: form?.gender,
      lat: form?.location?.geometry.location.lat()??'',
      lng: form?.location?.geometry.location.lng()??'',
      name: form?.name,
      // problem: form?.problem,
      source: form?.source,
      tzone: 5,
      phoneCode:form?.phoneCode??'+91',
      // postType: form?.postType,
      // mediaFile: form?.mediaFile,
      countryCode:form?.countryCode??allOptions[0]?.countryCode
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoader(true)
     
      const res = await APIServices.post(
        "admin/post/create_user",
        datainfos,
        headers
      );
      if (res.data?.status === true) {
        if (res.data && res.data.status) {
          setCropImg()
          setField("mediaFile", null);
          setTimeout(() => {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            clearfield();
          }, 3000);
         
          setLoader(false)
          if (res.data?.status === true) {
           
          }
        }
      }else if(res?.data?.status == 4){
       logout()
      }else{
        console.log("error");
      }
      setLoader(false)
    }
  };
 
  return (
    <>
    <div className="notifiction_area" style={{
      backgroundImage: 'url("/images/why_choose_us.jpg")'
        }}>
         <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           {/* <h4 className="hd-list-f left-q" > Add Customer</h4> */}
              <h4 className='hd-list-f left-q'>
                <FaArrowLeft
                  className='hd-cr '
                  style={{ marginRight: '15px' }}
                  size={25}
                  onClick={() => navigate(-1)}
                />
                <span style={{ textTransform: 'capitalize' }}>Add Customer</span>
              </h4>
           </div>
        </div>
            <div className="container">
            <div className="notifiction_form box-shadow br-6 p-20">
      
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
            <Col className="my-2 col-12 col-sm-6 col-md-4">
                <Form.Group as={Col} size="md" controlId="validationCustom01">
                  <Form.Label class="required">Name</Form.Label>
                  <Form.Control
                    required
                    name="name"
                    type="text"
                    placeholder="Name"
                    ref={nameRef}
                    value={form?.name}
                    isInvalid={!!errors.name}
                    onChange={(e) => setField("name", e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="my-2 col-12 col-sm-6 col-md-4">
              <Form.Label class="required">Contact Number</Form.Label>
              <Row className='code-num'>

              {/* <Form.Group className='phn-code' size='md' controlId=''>
                 <Form.Select
                   aria-label='Default select example'
                   required
                   className='hd-cr'
                   style={{ height: '40px', border: '1px solid #b3b3b3' }}
                   name='phoneCode'
                   ref={phoneCodeRef}
                   onChange={e => {
                    setField("phoneCode", e.target.value)
            }}
          >
            {/* <option value='+91'>+91</option>
            <option value='+1'>+1</option> */}
                    {/* {countryList && countryList?.length>0 && countryList.map((item)=>{
                          return <option value={item?.phoneCode}>{item?.phoneCode}({item?.countryCode})</option>
                    })}
          </Form.Select>
        </Form.Group> */} 
            <div className='phn-code'>
          <Select
                                          aria-label="phone-code"
                                          options={allOptions}
                                          value={selectedOption}
                                          onChange={handleChange}
                                          styles={customStyles}
                                          // menuIsOpen={true}
                                          isSearchable={false} // Disables typing
                                        />
                                        {/* <input
                                          type="hidden"
                                          {...register("phoneCode", {
                                            required: true,
                                          })}
                                        /> */}
                                        </div>
                <Form.Group as={Col} size="md"className="phn-num" controlId="validationCustom01">
                  <Form.Control
                    required
                    name="mobile"
                    type="text"
                    placeholder="Contact Number"
                    ref={mobileRef}
                    onChange={(e) => setField("mobile", e.target.value)}
                    onKeyPress={(evnt) => {
                      // Allow only numeric characters
                      if (!/^\d$/.test(evnt.key)) {
                        evnt.preventDefault();
                      }
                    }}
                    isInvalid={!!errors.mobile}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile}
                  </Form.Control.Feedback>
                </Form.Group>
                </Row>
              </Col>
          
              <Col className="my-2 col-12 col-sm-6 col-md-4">
                <Form.Group
                  as={Col}
                  size="md"
                  className=""
                  controlId="validationCustom01"
                >
                  <Form.Label class="required">Source</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    required
                    className="hd-cr"
                    ref={sourceRef}
                    onChange={(e) => setField("source", e.target.value)}
                    isInvalid={!!errors.source}
                  >
                    <option value="">Choose</option>
                    {/* <option value="app">App</option> */}
                    {/* <option value="admin">Admin</option> */}
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                    <option value="puja">Puja</option>
                    <option value="web">Web</option>
                    <option value="whatsapp">Whatsapp</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.source}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
           </Row>
           <Row>
              <Col className="my-2 col-12 col-sm-6 col-md-4">
                <Form.Group as={Col} size="md" controlId="">
                  <Form.Label class="">Date Of Birth</Form.Label>
                  <Form.Control
                    className="hd-cr"
                    name="dob"
                    type="date"
                    placeholder="dob"
                    // min="1900-01-1"
                    max={formateDate(today)}
                    ref={dobRef}
                    value={form?.dateofbirth}
                    onChange={(e) => setField("dateofbirth", e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col className="my-2">
                <Form.Group as={Col} size="md" className="">
                  <Form.Label class="">Hours</Form.Label>
                  <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  ref={hourRef}
                    onChange={(e) => setField("hours", e.target.value)}
                    // isInvalid={!!errors.hours}
                >
                <option value="">Choose</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>

                </Form.Select>
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.hours}
                  </Form.Control.Feedback> */}
                </Form.Group>
              </Col>

              <Col className="my-2 col-12 col-sm-6 col-md-4">
                <Form.Group as={Col} size="md" className="">
                  <Form.Label class="">Minutes</Form.Label>
                  <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  ref={minuteRef}
                    onChange={(e) => setField("minutes", e.target.value)}
                    // isInvalid={!!errors.minutes}
                >
                <option value="">Choose</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>

                </Form.Select>

                  {/* <Form.Control.Feedback type="invalid">
                    {errors.minutes}
                  </Form.Control.Feedback> */}
                </Form.Group>
              </Col>
            
            </Row>
            <Row>
            <Col className="my-2 col-12 col-sm-6 col-md-4" >
                <Form.Group as={Col} size="md" controlId="validationCustom02">
                  <Form.Label class=""> Birth Place</Form.Label>
                  <ReactGoogleAutocomplete
                    required
                    name="location"
                    type="text"
                    placeholder="Location"
                    className="form-control"
                    // className={`form-control ${
                    //   form.location === undefined
                    //     ? errors.location !== undefined
                    //       ? "is-invalid"
                    //       : ""
                    //     : ""
                    // }`}
                    ref={locationRef}
                    // isInvalid={!!errors.location}
                    apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                    onPlaceSelected={(place) => setPlace(place)}
                  />
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.location}
                  </Form.Control.Feedback> */}
                </Form.Group>
              </Col>
               <Col className="my-2 col-12 col-sm-6 col-md-4">
                <Form.Label class="">Gender</Form.Label>
                <Form.Group as={Col} size="md" style={{ marginTop: "8px" }}>
                  <input
                    class="form-check-input"
                    value="female"
                    defaultChecked
                    type="radio"
                    onChange={(e) => setField("gender", e.target.value)}
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    style={{ marginRight: "10px" }}
                  />
                  <Form.Label class="form-check-label" for="flexRadioDefault1">
                    Female
                  </Form.Label>
                  <input
                    class="form-check-input"
                    value="male"
                    type="radio"
                    onChange={(e) => setField("gender", e.target.value)}
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                  />
                  <Form.Label class="form-check-label" for="flexRadioDefault2">
                    Male
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-center d-flex">
              {/* <Col className="my-8"> */}
              {loader?<Button variant="primary"className="as_btn" >
              Processing...
            </Button>:<Button variant="primary"className="as_btn" type="submit">
            Add Customer
          </Button>}
             
              {/* </Col> */}
            </Row>
          </Form>
          <ToastContainer />
          <CropModal 
          showcrop={showcrop}
          setShowCrop={setShowCrop}
          setCropImg={setCropImg}
          cropimg={cropimg}
          caartifileimage={caartifileimage}
          setFileCaartiimage={setFileCaartiimage}/>
      </div>
      </div>
      </div>
    </>
  );
};
