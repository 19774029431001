import { useState, useEffect } from "react";
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce, useFilters } from "react-table";

//Global filter component for table data
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  // return (
  //   <input
  //     type="search"
  //     id="gsearch"
  //     name="gsearch"
  //     value={value || ""}
  //     className="form-control"
  //     placeholder="Search"
  //     onChange={(e) => {
  //       setValue(e.target.value);
  //       onChange(e.target.value);
  //     }}
  //     autoComplete="off"
  //   />
  // );
};

const DynamicDataTableWithCount = (props) => {
  const {
    columns,
    data,
    notShowPage,
    initialState = { pageSize: 40, ...props.initialState },
    tableType = "default",
    filterTable = null,
    fileName = "kadusis-table",
    totalCount = 0,
    fetchData,
    isLoading = false,
    styleClass="gita_quote_table",
    type
  } = props;

  // Use the state and functions returned from useTable to build UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState,
      manualPagination: true,
      pageCount: totalCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
    );
    
    useEffect(() => {
      fetchData(pageIndex * pageSize, pageSize, pageIndex);
    }, [ pageIndex, pageSize]);
    
    // Create custom pagination for dashboard table
  const GetPaginations = () => {
    let paginationArray = [],
      paginationNumberShow = 5;
    const pageCenterCount = Math.floor(paginationNumberShow / 2);

    // Check if pagination count is less than <paginationNumberShow>
    if (pageCount <= paginationNumberShow) {
      return pageOptions.map((data) => (
        <li className={`page-item ${data === pageIndex ? "active" : ""}`} key={data}>
          <button className={`page-link`} onClick={() => gotoPage(data)}>
            {data + 1}
          </button>
        </li>
      ));
    } // Check for number placed into center
    else if (pageIndex > 1 && pageIndex < pageCount - pageCenterCount && pageIndex - pageCenterCount >= 0) {
      paginationArray = Array(paginationNumberShow)
        .fill(pageIndex - pageCenterCount)
        .map((n, i) => n + i);

      return paginationArray.map((data) => (
        <li className={`page-item ${data === pageIndex ? "active" : ""}`} key={data}>
          <button className={`page-link`} onClick={() => gotoPage(data)}>
            {data + 1}
          </button>
        </li>
      ));
    } // Check if number of pages at last
    else if (pageIndex >= pageCount - pageCenterCount) {
      paginationArray = Array(paginationNumberShow)
        .fill(1)
        .map((n, i) => n + i)
        .sort(function (a, b) {
          return b - a;
        })
        .map((data) => pageCount - data);

      return paginationArray.map((data) => (
        <li className={`page-item ${data === pageIndex ? "active" : ""}`} key={data}>
          <button className={`page-link`} onClick={() => gotoPage(data)}>
            {data + 1}
          </button>
        </li>
      ));
    } else {
      // Print the starting 5 pagination
      paginationArray = Array(paginationNumberShow)
        .fill(0)
        .map((n, i) => n + i);
      return paginationArray.map((data) => (
        <li className={`page-item ${data === pageIndex ? "active" : ""}`} key={data}>
          <button className={`page-link`} onClick={() => gotoPage(data)}>
            {data + 1}
          </button>
        </li>
      ));
    }
  };

    // const callCellRenderer = (cell) => {

    //     let header = cell.column.Header;
        
    //     // console.warn("_________________cell.column.Header____ : ",cell.row.original.status)
        
    //     if(props?.shouldUpdate) {
    //         if (header === 'Action') {
    //             if(!props?.canAction) {
    //                 return (
    //                     <h5>
    //                         <input type="button" class={`_${cell.row.original.status?"":"in"}active`} value={cell.row.original.status?"Active":"Inactive"}/>
    //                     </h5>
    //                 );
    //             }
    //         }
    //         else if ( header === 'Edit' || header === 'Delete' ) {
    //             if( !props?.[`can${header}`] ) {
    //                 return (
    //                     <h5>
    //                         <input type="button" disabled="true" class={ header == "Edit" ? "Et-btn" : header == "Delete" ? "Dt-btn" : "" } value={ header } />
    //                     </h5>
    //                 );
    //             }
    //         }
    //     }

    //     return (cell.render("Cell"))
    // }

    return (
        <>
            <div className={styleClass}> 
                <div>
                    <div className="admin_grid_header d-flex align-items-center justify-content-between ">
                        <h2></h2>
                        <div className="d-flex align-items-center">
                            <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} /> 
                        </div>
                    </div>
                    <div className="admin_grid_table">
                        <div className="table-responsive  mb-2 overflow_scroll">
                            <table className="table w-1920" {...getTableProps()}>
                                <thead>
                                    { headerGroups.map((headerGroup) => (
                                        <tr { ...headerGroup.getHeaderGroupProps() }>
                                            { headerGroup.headers.map((column) => (
                                                <th { ...column.getHeaderProps(column.getSortByToggleProps()) }>
                                                    {column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                { isLoading ? (
                                    <tbody className="">

                                        {/* <tr>
                                        <td colSpan={headerGroups[0].headers.length}>
                                            <Loader loaderType="pre_loader" />
                                        </td>
                                        </tr> */}
                                    
                                    </tbody>
                                    ) : (
                                    <tbody {...getTableBodyProps()}>
                                        { page.map((row, i) => {
                                            prepareRow(row);
                                            // Check if it is job page
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                                    })}
                                                </tr>
                                            );
                                        })}

                                        {/* {rows.length === 0 && (
                                        <tr className="not_found">
                                            <td colSpan={columns.length} className="blue_color fs-4 fw-semibold text-center">
                                            No data found
                                            </td>
                                        </tr>
                                        )} */}

                                    </tbody>
                                )}
                            </table>
                        </div>
                        <div className="admin_pagination align-items-center justify-content-between mt-3 pb-4">

                            {/* <p>
                                Showing {pageIndex + 1} from {pageOptions.length} data
                            </p> */}

                            { notShowPage === true ? "" :
                                <ul className="pagination">
                                    <li className="page-item last">
                                        <button className={`page-link ${!canPreviousPage ? "disabled" : ""}`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                            {'<<'}
                                        </button>
                                    </li>
                                    <li className="page-item Previous">
                                        <button className={`page-link ${!canPreviousPage ? "disabled" : ""}`} onClick={() => previousPage()} disabled={!canPreviousPage}>
                                            Previous
                                        </button>
                                    </li>

                                    <GetPaginations />

                                    <li className="page-item next">
                                        <button className={`page-link ${!canNextPage ? "disabled" : ""}`} onClick={() => nextPage()} disabled={!canNextPage}>
                                            Next
                                        </button>
                                    </li>
                                    <li className="page-item last">
                                        <button className={`page-link ${!canNextPage ? "disabled" : ""}`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                            {'>>'}
                                        </button>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DynamicDataTableWithCount;
