import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { FaEdit, FaTrash, FaBell, FaArrowLeft, FaSistrix } from 'react-icons/fa';
import Papa from 'papaparse';
import APIServicenew from '../../utils/APIGeneralService';
import { UserContext } from '../../context/UserContextProvider';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import BookingModal from '../../components/SharedPooja/BookingModal';
import PujaBookingStatus from '../../components/SharedPooja/PujaBookingStatus';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import Select, { components } from 'react-select';
import { formateDate, setCookies } from '../../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import bimg from '../../imgs/banner_background.png';

const PanditOfPujaDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const panditId = searchParams.get('panditId');
  // const panditName = searchParams.get('panditName');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const { refreshToken } = useContext(UserContext);
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const [panditName,setPanditName] = useState('')
  const [puja, setPuja] = useState([]);
  const [singleDataModal,setSingleDataModal] = useState(false)
  const [singleData,setSingleData] = useState()


  useEffect(() => {
    fetchPujaData();
    getPanditData()
  }, []);


  const getPanditData = async () => {
    if (panditId) {
      let res = await APIServices.get(`pandit/getPanditById/${panditId}`, headers);
      if (res?.status === 200) {
        let apiData = res?.data;
        if (apiData?.status) {
          setSingleData(apiData?.data);
        }
      }
    }
  };

  const fetchPujaData = async () => {
    let response = await APIServices.get(`astroPooja/pujalisting`, headers);
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setPuja(apiData.Listdata);
      }
    }
  };

  const Input = props => {
    const { autoComplete = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoComplete} />;
  };

  const [selectedPujaSec, setSelectedPujaSec] = useState({ current: '' });
  const pujaOptions = [
    { value: '', label: 'All' },
    ...puja.map(item => ({
      value: item._id,
      label: `${item.title} (${moment(item.pujaDate).format('DD MMM, YYYY hh:mm A')})`,
    })),
  ];

  const handleChangeSec = selectedOption => {
    if (selectedOption) {
      setSelectedPujaSec({ current: selectedOption.value });
      handlePujaChangeSec(selectedOption);
      //   setOffset(0);
    } else {
      console.log('No option selected');
    }
  };

  const handlePujaChangeSec = selectedOption => {
    const value = selectedOption.value;

    // selectedPuja.current = e.target.value;
    setCookies('skip', 0);
  };

  //   Date

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRange, setSelectedRange] = useState('');

  const handleDateRange = range => {
    const now = new Date();
    let start, end;

    if (range === 'disabled') {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange('');
      return;
    }

    switch (range) {
      case 'today':
        start = end = now;
        break;
      case 'yesterday':
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case 'thisWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case 'last7Days':
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'lastMonth':
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'customDate':
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Puja Name',
      Cell: e => {
        return <span>{e.row.original.title ?? '_'}</span>;
      },
    },

    {
      Header: 'Pandit Price',
      Cell: e => {
        return <span>{e.row.original.pandit?.panditPrice ? `₹${e.row.original.pandit?.panditPrice}` : '_'}</span>;
      },
    },
    {
      Header: 'Total Pandit Price',
      Cell: e => {
        // return <span>₹{e.row.original?.totalPanditPrice ?? '_'}</span>;
        return <span>{e.row.original?.totalPanditPrice ? `₹${e.row.original?.totalPanditPrice}` : '_'}</span>;
      },
    },
    {
      Header: 'Total Expenses Price',
      Cell: e => {
        // return <span>₹{e.row.original.totalExpencesePrice ?? '_'}</span>;
        return <span>{e.row.original?.totalExpencesePrice ? `₹${e.row.original?.totalExpencesePrice}` : '_'}</span>;
      },
    },
    {
      Header: 'Total Paid Booking',
      Cell: e => {
        return <span>{e.row.original.totalBookingPaidCount ?? '_'}</span>;
      },
    },
    {
      Header: 'Total Puja Booking Price',
      Cell: e => {
        // return <span>₹{e.row.original.totalPriceOfPujaBookings ?? '_'}</span>;
        return <span>{e.row.original?.totalPriceOfPujaBookings ? `₹${e.row.original?.totalPriceOfPujaBookings}` : '_'}</span>;
      },
    },
    {
      Header: 'Puja Date',
      Cell: e => {
        return (
          <>
            <span>
              {e.row.original.pujaDate ? moment(e.row.original.pujaDate).format('DD MMM, YYYY hh:mm A') : '_'}
            </span>
          </>
        );
      },
    },
    {
      Header: 'Submitted Date',
      Cell: e => {
        return (
          <>
            <span>
              {e.row.original.createdAt ? moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A') : '_'}
            </span>
          </>
        );
      },
    },
  ]);

  const handleReset = () => {
    setSelectedPujaSec({ current: '' });
    setStartDate('');
    setEndDate('');
    setSelectedRange('');
    setFlag(true);
  };

  useEffect(() => {
    getDetails(skip, limit, pageIndex);
  }, [flag]);

  const handleFilterClick = () => {
    if (selectedPujaSec?.current || startDate || endDate) {
      getDetails(skip, limit, 0, startDate, endDate, selectedPujaSec?.current);
    }
  };

  const getDetails = useCallback(
    async (skip, limit, index) => {
      try {
        const response = await APIServices.get(
          `/pandit/getAllPujaByPanditId/${panditId}/${skip}/${limit}?pujaId=${selectedPujaSec?.current}&startDate=${
            startDate ? formateDate(startDate) : ''
          }&endDate=${endDate ? formateDate(endDate) : ''}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response?.data?.totalCount);
          setTableData(response?.data?.data);
          setPanditName(response?.data?.data[0]?.pandit?.panditName)
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, startDate,endDate,selectedPujaSec?.current]
  );



  return (
    <div>
      <div className='main aarti_section'>
        <>
          <div className='gita_quote_heading'>
            <div className='container d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q'>
                <FaArrowLeft
                  className='hd-cr '
                  style={{ marginRight: '15px' }}
                  size={25}
                  onClick={() => navigate(-1)}
                />
                <span style={{ textTransform: 'capitalize' }}>{panditName}</span>
              </h4>
              <div className='d-flex align-items-center justify-content-between'>
                { singleData?.paymentMode ? (
                  <Button
                      className='by-btn as_btn mt-0'
                      variant='primary'
                      onClick={() => {
                        setSingleDataModal(true);
                      }}
                    > Bank Details
                    </Button>
                ):""
                }
                  <h4>Total Count ({allDataCount})</h4>

              </div>
            </div>
          </div>
        </>
        <div className='user-list-table'>
          <div className='feed_area box-shadow br-6 p-20 my-3 font_16'>
            <div className='chat_search_box user-search'>
              <div className='chat-search-field'>
                <Form>
                  <Form.Group as={Row} className='' controlId=''>
                    <Col md={3}>
                      <Form.Label>Puja Name</Form.Label>
                      <Select
                        components={{ Input }}
                        autoComplete='new-password'
                        options={pujaOptions}
                        value={
                          selectedPujaSec.current === ''
                            ? ''
                            : pujaOptions.find(option => option.value === selectedPujaSec.current)
                        }
                        onChange={handleChangeSec}
                        classNamePrefix='react-select'
                        // className='fw-bold text-capitalize custom-datepicker'
                      />
                    </Col>
                    {/* <Col md={3}>
                      <Form.Label className=''>Search By Created Date</Form.Label>
                      <div className='date-range-wrapper'>
                        <div className='select-icon-wrapper'>
                          <select
                            className='select-range'
                            value={selectedRange}
                            onChange={e => {
                              handleDateRange(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option value='disabled'>Select Date Range</option>
                            <option value='today'>Today</option>
                            <option value='yesterday'>Yesterday</option>
                            <option value='thisWeek'>This Week</option>
                            <option value='last7Days'>Last 7 Days</option>
                            <option value='thisMonth'>This Month</option>
                            <option value='lastMonth'>Last Month</option>
                            <option value='customDate'>Custom Date</option>
                          </select>
                          <img src='/images/down-arrow.png' className='select-icons' />
                        </div>

                        {selectedRange === 'customDate' && (
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={update => {
                              const [start, end] = update;
                              setStartDate(start);
                              setEndDate(end);
                              setSkip(0);
                            }}
                            // isClearable={true}
                            placeholderText='Select custom date range'
                            dateFormat='yyyy-MM-dd'
                          />
                        )}
                      </div>
                    </Col> */}
                    <Col md={3} className='mt-3'>
                      <Button
                        variant='secondary'
                        className='as_btn ms-0'
                        onClick={() => {
                          handleFilterClick();
                          setSkip(0);
                        }}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='secondary'
                        className='as_btn reset-btn'
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={getDetails}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className='no_records'>Records not Available</h2>
        )}

         {/* view details */}
         <Modal
            centered
            scrollable
            show={singleDataModal}
            size='lg'
            // size={`${pujaData?.pujaExpencese === null} ? 'xl : 'lg`}
            aria-labelledby='example-modal-sizes-title-sm'
            backdrop='static'
            onHide={() => {
              setSingleDataModal(false)
            }}
          >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title>Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {singleData?.paymentMode === "upi" ? (
            <>
                <div className="row mb-2">
                  <div className="col-6 fw-semibold">Payment Mode:</div>
                  <div className="col-6">UPI</div>
                </div>
                <div className="row mb-2">
                  <div className="col-6 fw-semibold">UPI Number:</div>
                  <div className="col-6">{singleData?.upiNumber}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-6 fw-semibold">Account holder name:</div>
                  <div className="col-6 text-capitalize">{singleData?.upiHolderName}</div>
                </div>
            </>
          ) : ""}
           {singleData?.paymentMode === "bank_details" ? (
            <>
              <div className="row mb-2">
                  <div className="col-6 fw-semibold">Payment Mode:</div>
                  <div className="col-6">Bank Details</div>
                </div>
              <div className="row mb-2">
                <div className="col-6 fw-semibold">A/C number:</div>
                <div className="col-6">{singleData?.accNumber}</div>
              </div>
              <div className="row mb-2">
                <div className="col-6 fw-semibold">IFSC code:</div>
                <div className="col-6">{singleData?.ifscCode}</div>
              </div>
              <div className="row mb-2">
                <div className="col-6 fw-semibold">Account holder name:</div>
                <div className="col-6 text-capitalize">{singleData?.accHolderName}</div>
              </div>
              <div className="row mb-2">
                <div className="col-6 fw-semibold">Bank name:</div>
                <div className="col-6">{singleData?.bankName}</div>
              </div>
            </>
           ):""}
         
        </Modal.Body>
        <Modal.Footer>
         
          <Button
            variant='secondary'
            className='cancle_btn'
            onClick={() => {
              setSingleDataModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </div>
  );
};

export default PanditOfPujaDetails;
