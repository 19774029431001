import React, { createRef, useEffect, useState } from "react";
import { Col, Container, Row, Button, InputGroup, FormControl, Spinner, Table , Form  } from "react-bootstrap";
import Cookies from 'universal-cookie';
import { Pencil, XOctagon ,XLg} from "react-bootstrap-icons";
import { Fa } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useToasts } from 'react-toast-notifications';
import APIServicenew from "../utils/APIGeneralService";
import { getCookies } from "../utils/Helper";
import usePermissionData from "../components/Customhook/custompermission";
import Notauthorized from "../components/Notauthorized";

export const Label = () => {
  document.title = 'Label';
  const permissioncontext =usePermissionData("label")

  const defaultState={
    name : '',
    colourCode : '',
    
  };
  const { addToast } = useToasts();
  const [loading, setLoading] = useState()
  const [newData ,setNewData] = useState();
  const [state, setState] = useState(defaultState);
  const [data, setData] = useState([]);
  const [validated, setValidated] = useState(false);
  const[loader,setLoader]=useState(false)
  const [allDataCount , setAllDataCount] = useState()
  const cookies = new Cookies();

  let token = cookies.get("accessToken");
  const headers = { Authorization: `Bearer ${token}`}

  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL)
  const fetchData = async () => {
    setLoading(true)
    let response = await APIServices.get(`chat/getChatFilters`, headers)
    if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status) {
            setData(apiData?.data)
            setAllDataCount(apiData?.data.length)
            setLoading(false)
        }
    }
}

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let payload =  {} ;
    payload.name = state.name;
    payload.colourCode = state.colourCode;

    if (form.checkValidity() === false) {
      event.stopPropagation();
     setValidated(true);

  }else{
    setLoader(true)
    if( newData?._id){
      const res = await APIServices.put(`chat/update/${newData._id}`,  payload, headers );
      if (res && res.status === 200) {
        await fetchData()
        let apiData = res?.data;
        if (apiData?.status === true) {
            toast.success(apiData?.message);
            setLoader(false)
            setNewData("")
            setState(defaultState)
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }

      }
      
    }else{
    const res = await APIServices.post("chat/addFilter",  payload, headers );
    if (res && res.status === 200) {
      await fetchData()
      let apiData = res?.data;
      if (apiData?.status === true) {
          toast.success(apiData?.message);
          setLoader(false)
          setNewData("")
          setState(defaultState)
      }else if(apiData?.status === false){
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
  }
  }
}

const handleActiveClick = async (status, id) => {
  var datas = status === 1 ? 2 : 1;
  const formData = new FormData();
  formData.append('status', datas);
  await APIServices.put(`chat/updateStatus/${id}`, formData, headers)
    .then(res => {
     fetchData()
    })
    .catch(err => {
      console.log(err);
    });
};
useEffect(() => {
  fetchData()
  setLoader(false)
}, []);

  const renderName = () => {
    return (
      <>
        <Form.Group as={Col} size="md" controlId="validationCustom01">
          <Form.Label  class="required">Name</Form.Label>
          <Form.Control
            required
            name="name"
            type="text"
            value={state.name}
            placeholder="Name"
            onChange={(e) => setState({...state, name: e.target.value})}

          />
        <Form.Control.Feedback type='invalid'>Please enter name.</Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }
  const renderColorCode = () => {
    return (
      <>
        <Form.Group as={Col} size="md">
          <Form.Label  class="">Colour Code</Form.Label>
          <Form.Control
          required
          type="color"
          name="color"
          id="exampleColorInput"
          title="Choose your color"
          class="form-control form-control-color"
          value={state.colourCode}
          onChange={(e) => setState({...state, colourCode: e.target.value})}
          />
         
        </Form.Group>
      </>
    )
  }

  return (
    <>
    {
      permissioncontext.view?
<div className="notifiction_area" style={{
          backgroundImage: 'url("/images/why_choose_us.jpg")'
        }}>
         <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
            <h4 className="hd-list-f left-q" > 
              <Row>
                {newData?._id?<Col>
                    <h5>Edit Label
                      {/* <span style={{marginLeft:"6px"}}>
                    <XLg className="me-2"
                    size={20}
                    onClick={(e) => {
                      setNewData("")
                      setState(defaultState)
                    }}
                    />

                    </span> */}
                    </h5>
                    
                  </Col> :

                  <Col>
                    <h5> Labels <small>({allDataCount})</small></h5>
                  </Col>}
                </Row>
            </h4>
           </div>
           </div>
              <div className="container-fluid my-3">
                <div className="notifiction_form bg-white mt-0 box-shadow br-6 p-20">
          
          
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mx-0">
              <Col className="mb-2">
                {renderName()}
              </Col>
              
              <Col className="mb-2">
                {renderColorCode()}
              </Col>
            </Row>
            <Row>
            {newData?._id?<Col className="">{loader?<Button variant="primary" className="as_btn"  >Processing...</Button>:
            <>
            <Button disabled={!permissioncontext.edit} variant="primary" className="as_btn"  type="submit" >Submit </Button>
            <Button variant="primary" onClick={(e) => {
                      setNewData("")
                      setState(defaultState)
                    }} className="as_btn cancle_btn"  type="submit" >Cancel </Button>
            

            </>
            }
              
              </Col> :
              <Col className="">{
                loader?<Button variant="primary" className="as_btn" >Processing...</Button>:<Button disabled={!permissioncontext.add} variant="primary" className="as_btn"  type="submit">Submit</Button>
              }
              
              </Col>}
            </Row>
            </Form>
                </div>
              </div>
                  
                <ToastContainer />
                <div className="table-responsive admin_grid_table ">
                        <Table className="w-1920">
                            <thead>
                                <tr>
                                    <th> No. </th>
                                    <th> Name </th>
                                    <th> Colour </th>
                                    <th> Status </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                        {data?.map((item ,i)=>
                            <tbody>
                                    <tr key={item?._id}>
                                    <td>{i+1}</td>
                                    <td>{item?.name}</td>
                                    <td style={{ color: item?.colourCode }}>{item?.colourCode}</td>
                                    <td>{item?.status === 1 ?
                                    <input
                                    type='button'
                                    className={!permissioncontext.edit?'_active disabled-btn':'_active'}
                                    disabled={!permissioncontext.edit}
                                    value='Active'
                                    onClick={() => handleActiveClick(item.status,item._id )
                                    }
                                    />:<input
                                    type='button'
                                    className={!permissioncontext.edit?'_inactive disabled-btn':'_inactive'}
                                    disabled={!permissioncontext.edit}
                                    value='Inactive'
                                    onClick={() => handleActiveClick(item.status,item._id )
                                    }
                                  />
                                  }</td>
                                    <td><Pencil
                                          className={permissioncontext.edit?"me-2":"me-2 disabled-btn"}
                                          size={20}
                                          onClick={(e) =>{
                                            if(permissioncontext.edit){
                                            setNewData(item)
                                            setState(item)}}
                                          }
                                    /></td>
                                </tr>
                                
                            </tbody>
                        )}
                        </Table>
                </div>
           
      </div>:
      <Notauthorized />
    }
    
      
    </>
  );
}



export default Label;