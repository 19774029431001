import { Row, Col, Button, Container, Modal, Form } from 'react-bootstrap';
import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { FaEdit, FaTrash, FaMinus, FaPlus } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import Select from 'react-select';

const PujaExpensesModal = ({ show,dropdownData,titleDropdown, handleClose, pujaData, pujaId, adminUser,fetchPujaDetails, selectedBooking ,totalBookingPrice ,totalBookingPaidCount}) => {
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputFieldSecEn, setInputFieldSecEn] = useState([{ name: '', price: '' }]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(null);

  const totalPanditPrice =  Number(totalBookingPaidCount) * Number(pujaData?.pujaPandit?.price)
  const [showConfirm, setShowConfirm] = useState(false);
  const [expensesBtn,setExpensesBtn] = useState(false)

  const handleCloseConfirm = () => setShowConfirm(false);
  const handleShowConfirm = () => setShowConfirm(true);

  const addInputFieldEn = () => {
    setInputFieldSecEn([...inputFieldSecEn, { name: '', price: '' }]);
  };

  const removeInputField = index => {
    const values = [...inputFieldSecEn];
    values.splice(index, 1);
    setInputFieldSecEn(values);
  };

  const handleChangeSec = (index, evnt) => {
    const { name, value } = evnt.target;

    let key;
    key = evnt?.target?.name;
    if (!!errors?.fieldSelectionEn) {
      errors?.fieldSelectionEn?.forEach((field, index) => {
        if (errors.fieldSelectionEn[index][key] != null || errors.fieldSelectionEn[index][key] != '') {
          errors.fieldSelectionEn[index][key] = null;
        }
      });
    }
    const list = [...inputFieldSecEn];
    list[index][name] = value;
    setInputFieldSecEn(list);
  };
  useEffect(() => {
    const total = inputFieldSecEn.reduce((acc, curr) => acc + (parseFloat(curr.price) || 0), 0);
    const totalExpense = total + Number(price);
    setTotalPrice(totalExpense);
  }, [inputFieldSecEn,price,pujaData]);

  // select pandit by id --

  // const [dropdownData, setDropdownData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    // titleDropdown();
    setPrice(totalPanditPrice)
  }, [totalPanditPrice]);

  // const titleDropdown = async () => {
  //   const response = await APIServices.get(`pandit/getPanditList`, headers);
  //   if (response?.status === 200) {
  //     setDropdownData(response?.data?.Listdata);
  //   }
  // };

  const handleSelectChange = selected => {
    setSelectedOption(selected);

    if (selected?.value) {
      const updatedErrors = { ...errors };
      delete updatedErrors.selectedOption;
      setErrors(updatedErrors);
    }
  };

  const options = dropdownData.map(item => ({
    value: item._id,
    label: item.name,
  }));

  const validateInputs = () => {
    const newErrors = {};
    // let isLocalCantentValid = false;

    // const inputFieldsErrorsSecEn = [];
    // inputFieldSecEn?.forEach((field, index) => {
    //   let errorMsgs = {
    //     name: '',
    //     price: '',
    //   };

    //   Object.keys(field).forEach(key => {
    //     if (field[key] === '' || field[key] === 'undefined') {
    //       isLocalCantentValid = true;
    //       let errMsg = `Please add  ${key === 'name' ? 'name' : key === 'price' ? 'price' : key}.`;
    //       errorMsgs[key] = errMsg;
    //     }
    //   });
    //   inputFieldsErrorsSecEn.push(errorMsgs);
    // });

    // if (isLocalCantentValid && inputFieldsErrorsSecEn.length > 0) newErrors.fieldSelectionEn = inputFieldsErrorsSecEn;
    if (!pujaData?.pujaPandit?.panditId) {
      if (!selectedOption?.value || selectedOption?.value === '') {
        newErrors.selectedOption = 'Please select pandit.';
      }
    }

    // if (!pujaData?.pujaPandit?.panditId) {
    if (pujaData?.pujaPandit?.panditId) {

      if (!price || price === '') {
        newErrors.price = 'Please enter Price.';
      }
      if (parseFloat(price) <= 0) {
        newErrors.price = 'Please enter a valid Price greater than 0.';
      }
    }

    return newErrors;
  };

  const handleSubmitData = async e => {
    e.preventDefault();

    const newErrors = validateInputs();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
    handleShowConfirm()
  }
  const handleSubmit = async e => {
    e.preventDefault();


    setLoading(true);
    const formData = new FormData();

    formData.append('totalPrice', totalPrice);
    formData.append('pujaId', pujaId);
    formData.append('userId', adminUser);
    if (pujaData?.pujaPandit?.panditId) {
      formData.append('panditId', pujaData?.pujaPandit?.panditId);
    } else {
      formData.append('panditId', selectedOption?.value);
    }

    // if (pujaData?.pujaPandit?.price) {
    //   formData.append('panditPrice', pujaData?.pujaPandit?.price);
    // } else {
      formData.append('panditPrice', price);
    // }

    if (pujaData?.pujaPandit?.name) {
      formData.append('panditName', pujaData?.pujaPandit?.name);
    } else {
      formData.append('panditName', selectedOption?.label);
    }

    formData.append('expenceseItem', JSON.stringify(inputFieldSecEn));

    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    try {
      const response = await APIServices.post('pandit/addPujaExpencese', formData, headers);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false);
        handleReset();
        handleClose()
        fetchPujaDetails()
        // alert("successfull")
        handleCloseConfirm()
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false); 
    }
  };

  const handleReset = () => {
    setInputFieldSecEn([{ name: '', price: '' }]);
    setPrice(totalPanditPrice);
    setSelectedOption('');
    setExpensesBtn(false)
  };

  return (
    <div>
      <Modal
        centered
        scrollable
        show={show}
        // size='xl'
        size={`${pujaData?.pujaExpencese === null} ? 'xl : 'lg`}
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={() => {
          handleClose();
          handleReset();
          setErrors({});
        }}
      >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title>{(pujaData?.pujaExpencese === null || pujaData?.pujaExpencese?.panditPrice === null) ? 'Add' : 'View'} Expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(!pujaData?.pujaExpencese || pujaData?.pujaExpencese === null || pujaData?.pujaExpencese?.panditPrice === null) ? (
            <Form className='add_blog_form p-20 br-6' 
            // onSubmit={e => handleSubmit(e)}
            >
              <Row>
                  <Col sm={6} className={pujaData?.pujaPandit?.name ? "" : "required"}>Pandit</Col>
                  <Col sm={6} className={pujaData?.pujaPandit?.price ? "" : "required"}>Pandit Price</Col>

                {pujaData?.pujaPandit?.panditId ? (
                  <Col sm={6} className='mt-3'>
                    <Form.Group as={Row} controlId='formGlobalPrice'>
                      {/* <Form.Label column sm='3'>
                        Pandit
                      </Form.Label> */}
                      <Col sm='12'>
                        <Form.Control
                          type='text'
                          placeholder='Name ...'
                          readOnly
                          // name='price'
                          value={pujaData?.pujaPandit?.name}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col sm={6} className='mt-3'>
                    <Form.Group as={Row} controlId='formGlobalPrice'>
                      {/* <Form.Label className='required' column sm='3'>
                        Select Pandit
                      </Form.Label> */}
                      <Col sm='12'>
                        <Select
                          classNamePrefix='react-select'
                          options={options}
                          value={selectedOption}
                          onChange={handleSelectChange}
                          placeholder='Search by Pandit...'
                          isClearable
                        />
                        {!!errors?.selectedOption && <p className='errorVal'>{errors?.selectedOption}</p>}
                      </Col>
                    </Form.Group>
                  </Col>
                )}

                {/* {pujaData?.pujaPandit?.panditId ? (
                  <>
                    <Col sm={6} className='mt-3'>
                      <Form.Group as={Row} controlId='formGlobalPrice'>
                        <Col sm='12'>
                          <Form.Control
                            type='number'
                            placeholder='Price ...'
                            // readOnly
                            // name='price'
                            value={pujaData?.pujaPandit?.price}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </>
                ) : (
                  <> */}

                  
                    <Col sm={6} className='mt-3'>
                      <Form.Group as={Row} controlId='formGlobalPriceNew'>
                        {/* <Form.Label className='required' column sm='3'>
                          Pandit Price
                        </Form.Label> */}
                        <Col sm='12'>
                          <Form.Control
                            type='number'
                            placeholder='Price ...'
                            name='price'
                            value={price}
                            isInvalid={!!errors?.price}
                            onKeyPress={evnt => {
                              if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                                evnt.preventDefault();
                              }
                            }}
                            onChange={e => {
                              setPrice(e.target.value);
                              setErrors(prevErrors => ({ ...prevErrors, price: '' }));
                            }}
                          />
                          <Form.Control.Feedback type='invalid'>{errors?.price}</Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  {/* </>
                )} */}
    
                            {!expensesBtn && 
                                <Col sm={12}>
                                  <div className='mt-3'>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'end',
                                        // cursor: 'pointer',
                                        color: 'green',
                                      }}
                                    >
                                        <button className='Et-btn add-btn1 btn-success1' type='button' onClick={()=> setExpensesBtn(true)}>
                                          Add Expenses
                                          <FaPlus className='hd-cr' title='Add Section' />
                                        </button>
                                    </div>
                                  </div>
                                </Col>
                            }
                      {expensesBtn && (
                        <>
                            {inputFieldSecEn.map((field, index) => {
                              const { name, price } = field;
                              return (
                                <>
                                  <div key={index} className='mt-3'>
                                    <Row>
                                      <Col sm={6} className=''>Expences Title</Col>
                                      <Col sm={6} className=''>Price</Col>
                                      <Col sm={6} className='mt-3'>
                                        <Form.Group as={Row} controlId={`formTitle-${index}`}>
                                          {/* <Form.Label className='required' column sm='3'>
                                            Name
                                          </Form.Label> */}
                                          <Col sm='12'>
                                            <Form.Control
                                              type='text'
                                              name='name'
                                              placeholder='name...'
                                              value={name}
                                              onChange={evnt => handleChangeSec(index, evnt)}
                                              isInvalid={!!errors?.fieldSelectionEn?.[index]?.name}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                              {errors?.fieldSelectionEn?.[index]?.name}
                                            </Form.Control.Feedback>
                                          </Col>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6} className='mt-3'>
                                        <Form.Group as={Row} controlId={`formGlobalDescription-${index}`}>
                                          {/* <Form.Label className='required' column sm='3'>
                                            Price
                                          </Form.Label> */}
                                          <Col sm='12'>
                                            <Form.Control
                                              type='number'
                                              placeholder='Price ...'
                                              name='price'
                                              value={price}
                                              onKeyPress={evnt => {
                                                if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                                                  evnt.preventDefault();
                                                }
                                              }}
                                              onChange={evnt => handleChangeSec(index, evnt)}
                                              isInvalid={!!errors?.fieldSelectionEn?.[index]?.price}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                              {errors?.fieldSelectionEn?.[index]?.price}
                                            </Form.Control.Feedback>
                                          </Col>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    

                                    <Row>
                                      {inputFieldSecEn.length !== 1 ? (
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                            color: 'red',
                                            // cursor: 'pointer',
                                          }}
                                        >
                                          <button className='Dt-btn mt-2 add-btn1' type='button'
                                          onClick={() => removeInputField(index)}
                                          >
                                            Remove
                                            <FaMinus className='hd-cr fw-bold' title='Remove Section' />
                                          </button>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </Row>
                                  </div>
                                </>
                              );
                            })}
                        </>

                      )}
                      {expensesBtn && (
                        <Col sm={12}>
                          <div className='mt-3'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                                // cursor: 'pointer',
                                color: 'green',
                              }}
                            >
                              <button className='Et-btn add-btn1 btn-success1' type='button' onClick={addInputFieldEn}>
                                Add Expenses
                                <FaPlus className='hd-cr' title='Add Section' />
                              </button>
                            </div>
                          </div>
                        </Col>
                      )}

                <Col as={Row} className='mt-3'>
                  <Col sm='3'>Total Price</Col>
                  <Col sm='4' className=''>{totalPrice === 0 ? 0 : `₹${totalPrice}.00`}
                  
                  </Col>
                </Col>

                <div className='text-center'>
                  {/* <Button variant='primary' type='submit' className='as_btn'>
                    {loading ? 'Loading...' : 'Add'}
                  </Button> */}
                </div>
              </Row>
            </Form>
          ) : (
            <>
            <div className=''>
                  <ol className='puja-expenses-wrapper'>
                    <li className='d-flex justify-content-between'>
                      <p className='my-1 fw-semibold'>Expenses</p>
                      <p className='mx-4  fw-semibold'>Price</p>
                    </li>
                    <hr/>
                    <li className='d-flex justify-content-between'>
                        <p className='my-1 fw-semibold'>{pujaData?.pujaExpencese?.panditName} (Pandit Name)</p>
                        <p className='mx-4'>₹{pujaData?.pujaExpencese?.panditPrice}.00</p>
                    </li>
                    {pujaData &&
                      pujaData?.pujaExpencese?.expenceseItem?.length>0 && pujaData?.pujaExpencese?.expenceseItem?.map((data, ind) => {
                        return (
                          <>
                            <li className='d-flex justify-content-between' key={data?._id}>
                              <div className='d-flex align-items-center'>
                                  { data?.name !=="" ? (ind + 1)+"." :""}
                                  {/* <p className='my-1 ms-1'>{data?.name}</p> */}
                                  <p className='my-1 ms-1'>{data?.name !=="" ? data?.name : ""}</p>
                                </div>
                              <p className='mx-4'>{data?.price !=="" ? ("₹"+(data?.price)+".00") : ""}</p>
                            </li>
                          </>
                        );
                      })}
                      
                      <hr/>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-semibold my-1'>Expenses Total Price</p>
                        <p className='mx-4'>₹{pujaData?.pujaExpencese?.totalPrice}.00</p>
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-semibold my-1'>Bookings Total Price</p>
                        <p className='mx-4'>₹{totalBookingPrice}.00</p>
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-between'>
                      <p className='fw-semibold my-1'>
                        {Number(totalBookingPrice) - Number(pujaData?.pujaExpencese?.totalPrice) >= 0 
                          ? "Total Puja Profit" 
                          : "Total Puja Loss"}
                      </p>
                      <p className='mx-4'>
                        ₹{Math.abs(Number(totalBookingPrice) - Number(pujaData?.pujaExpencese?.totalPrice))}.00
                      </p>
                    </div>

                  </ol>
            </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {pujaData?.pujaExpencese?.expenceseItem ? "" : (
                  <Button variant='primary' type='submit' onClick={handleSubmitData} className='as_btn'>
                    {loading ? 'Loading...' : 'Add'}
                  </Button>
          )}
          <Button
            variant='secondary'
            className='cancle_btn'
            onClick={() => {
              handleClose();
              handleReset();
              setErrors({});
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirm} onHide={handleCloseConfirm} centered aria-labelledby='submit-modal' className='submit_modal'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p> Are you sure you want to submit expenses?</p>
          <div className='d-flex justify-content-end gap-2'>    
            <Button variant="primary"  className='as_btn' onClick={handleSubmit}>
                Yes
              </Button>
              <Button variant="secondary"  className='as_btn cancle_btn' onClick={handleCloseConfirm}>
                Close
              </Button>
          </div>  
        </Modal.Body>
        <Modal.Footer className='justify-content-start'>
          <p className='submit-error'>Once submitted it will not be editable.</p>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PujaExpensesModal;
