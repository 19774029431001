import { useEffect, useState, useContext } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContextProvider';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash, FaArrowLeft } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../utils/APIGeneralService';
import { getBaseURL, setCookies } from '../utils/Helper';


const PujaMethodMaster = () => {
  document.title = 'Puja Method Master';
  const location = useLocation()
  const  {permissioncontext}  = location.state || {};
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [titleHi ,setTitleHi] = useState('');
  const [contentHi, setContentHi] = useState('');
  const [sortOrder ,setSortOrder] = useState();
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataCount, setAllDataCount] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  useEffect(() => {
    fetchData();
  }, [offset]);

  async function fetchData() {
    setLoading(true);
    let response = await APIServices.get(`pujaMethod/getAll/${offset}/${perPage}`, headers);
    let data = response?.response?.data;
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata);
        setAllDataCount(apiData.totalCount);
        setLoading(false);
      }
    } else {
      if (data.status == 4) {
        logout();
      }
    }
  }
  const handleShow = async item => {
    setShowModal(true);
    setTitle(item?.title);
    setSelectedId(item?._id);
    setContent(item?.content);
    setTitleHi(item?.titleHi);
    setContentHi(item?.contentHi);
    setSortOrder(item?.sortOrder);
  };
  const handleClose = () => {
    setErrors('');
    setSelectedId();
    setContent('');
    setTitle('');
    setContentHi('');
    setTitleHi('');
    setSortOrder()
    // setFileCaartiimage("")
    setShowModal(false);
  };

  const handlePageClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setOffset(newOffset);
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!title || title === '') newErrors.title = 'Please enter title in english.';

    if (!content && content === '') newErrors.content = 'Please enter content in english.';

    if (!titleHi || titleHi === '') newErrors.titleHi = 'Please enter title in hindi.';

    if (!contentHi && (contentHi === ''||contentHi === null)) newErrors.contentHi = 'Please enter content in hindi.';

    return newErrors;
  };

  async function handleCreate() {
    let payload = new FormData();
    payload.append('title', title);

    payload.append('content', content);

    payload.append('titleHi', titleHi);

    payload.append('contentHi', contentHi);

    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let response = await APIServices.post(`pujaMethod/add`, payload, headers);
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowModal(false);
          toast.success(apiData?.message);
          setLoader(false);
          setTitle('');
          setContent('');
          setTitleHi('')
          setContentHi('')
          fetchData();
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false);
        }
      }
      setLoader(false);
    }
  }

  async function handleEdit() {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('content', content);

    formData.append('titleHi', titleHi);

    formData.append('contentHi', contentHi);

    formData.append('sortOrder', sortOrder);
    
    const headers = { Authorization: `Bearer ${token}` };
    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let response = await APIServices.put(`pujaMethod/editOne/${selectedId}`, formData, headers);
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          setShowModal(false);
          setSelectedId()
          setTitle('');
          setContent('');
          setTitleHi('')
          setContentHi('')
          setSortOrder()
          fetchData();
          setLoader(false);
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false);
        }
      }
      setLoader(false);
    }
  }

  const handleCloseDt = () => setShowDt(false);
  const handleShowDt = id => {
    setShowDt(true);
    setSelectedId(id);
  };
  const handledelete = async () => {
    let res = await APIServices.delete(`pujaMethod/findByIdAndremove/${selectedId}`, headers);
    if (res?.status === 200) {
      setShowDt(false);
      setSelectedId()
      setOffset(0);
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData();
      }
    }
  };

  function handleChange(e, field) {
    let key, value;
    key = e?.target?.name;
    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
  }

  return (
    <>
      <div className='main aarti_section'>
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>
              <FaArrowLeft className='hd-cr ' style={{ marginRight: '15px' }} size={25} onClick={() => navigate(-1)} />
              Puja Process <small>({allDataCount})</small>
            </h4>

            <div className='_btn'>
              <Button
                className='by-btn as_btn me-0'
                variant='primary'
                disabled={!permissioncontext.add}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add Method
              </Button>
              {/* &nbsp;&nbsp; */}
            </div>
          </div>
        </div>
        <div className='aarti_inner pt-4'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                {loading ? (
                  <div className='center'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <>
                    <div className='row'>
                      {allData?.map(item => (
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                          <div className='card mb-3 ' style={{height: '15rem'}} key={item?._id}>
                            <div className='card-header'></div>

                            <div class='card-body'>
                              <h5 class='card-title font_18'>{item?.title}</h5>

                              <p class='card-text font_14'>
                              {item?.content}
                              </p>
                            </div>
                            <p style={{ textAlign: 'center' }} className='description font-500 mb-2'>
                              <span className='calicon'>
                                <i className='ri-calendar-line'></i>
                              </span>
                              {new Date(item.createdAt).toDateString()}{'  '}{'  '}
                            <FaEdit
                              className={permissioncontext.edit?'me-2  ms-4 hd-cr':'me-2  ms-4 hd-cr disabled-btn'}
                              size={20}
                              onClick={e => {
                                if(permissioncontext.edit){

                                  handleShow(item);
                                }
                              }}
                            />
                            <FaTrash
                              className={permissioncontext.delete?'dt-btn hd-cr ':'dt-btn hd-cr disabled-btn'}
                              size={20}
                              onClick={e => {
                                if(permissioncontext.delete){
                                  handleShowDt(item?._id);
                                }
                              }}
                            />
                            <span class="badge badge-info ms-1">{item?.sortOrder}</span>
                            </p>
                           
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <div className='pagination'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next>'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(allDataCount / perPage)}
                    previousLabel='< Previous'
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={offset / perPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
      centered
      scrollable
        show={showModal}
        backdrop='static'
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title> {`${selectedId ? 'Edit' : 'Add'} Method`} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
                Title
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='title'
                  defaultValue={title}
                  isInvalid={!!errors?.title}
                  className='form-control '
                  onChange={e => {
                    setTitle(e.target.value);
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
                Content
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  as='textarea'
                  style={{ height: 150 }}
                  onChange={e => {
                    setContent(e.target.value);
                    handleChange(e);
                  }}
                  defaultValue={content}
                  isInvalid={!!errors?.content}
                  name='content'
                  className='form-control'
                  placeholder='Content...'
                />
                <Form.Control.Feedback type='invalid'>{errors?.content}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
                Title (Hindi)
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='titleHi'
                  defaultValue={titleHi}
                  isInvalid={!!errors?.titleHi}
                  className='form-control '
                  onChange={e => {
                    setTitleHi(e.target.value);
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.titleHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
                Content (Hindi)
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  as='textarea'
                  style={{ height: 150 }}
                  onChange={e => {
                    setContentHi(e.target.value);
                    handleChange(e);
                  }}
                  defaultValue={contentHi}
                  isInvalid={!!errors?.contentHi}
                  name='contentHi'
                  className='form-control'
                  placeholder='Content...'
                />
                <Form.Control.Feedback type='invalid'>{errors?.contentHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            {selectedId?<>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='' column sm='3'>
                Sort Order
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='number'
                  placeholder=''
                  name='sortOrder'
                  defaultValue={sortOrder}
                  className='form-control '
                  onChange={e => {
                    setSortOrder(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            </>:""}
          </Form>
        </Modal.Body>
        <Modal.Footer>
  
            <Button
              variant='primary'
              className='as_btn'
              onClick={e => `${selectedId ? handleEdit() : handleCreate(e)}`}
            >
              {`${selectedId ? 'Edit' : 'Add'}`}
            </Button>
          
          <Button
            variant='secondary'
            className='as_btn reset-btn'
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
      scrollable
      centered
        show={showdt}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default PujaMethodMaster;
