import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../components/Shared/Sidebar';
import bimg from '../../imgs/banner_background.png';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
import {
  formateDateAndTime,
  SlugifyStringWithDash,
  capitalizeFirstLetter,
  setCookies,
  formateDate,
} from '../../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../../context/UserContextProvider';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import usePermissionData from '../../components/Customhook/custompermission';
import Notauthorized from '../../components/Notauthorized';

const AstroPooja = () => {
  document.title = 'Puja';
  const permissioncontext =usePermissionData("puja")
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [showdt, setShowDt] = useState(false);
  const [showct, setShowCt] = useState(false);
  const [type, setType] = useState('english');
  const [status, setStatus] = useState('active');
  const [contentType, setContentType] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [len, setLen] = useState();
  const [titlect, setTitleCt] = useState('');
  const [slugct, setSlugCt] = useState('');
  const [cloneId, setCloneId] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [startDate, setStartDate] = useState('');
  const [flag, setFlag] = useState(false);
  const today = new Date();
  const Navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get('astroUserId');
  const { refreshToken } = useContext(UserContext);
  const [allDataCount, setAllDataCount] = useState();
  const [endDate, setEndDate] = useState('');
  const [pujaDate, setPujaDate] = useState('');
  const [selectedRange, setSelectedRange] = useState('');
  const [expensesData,setExpensesData] = useState()
  const [expensesModal,setExpensesModal] = useState(false)
  const [totalBookingPrice ,setTotalBookingPrice] = useState(null)

  const handleDateRange = range => {
    const now = new Date();
    let start, end;

    if (range === 'disabled') {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange('');
      return;
    }

    switch (range) {
      case 'today':
        start = end = now;
        break;
      case 'yesterday':
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case 'thisWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case 'last7Days':
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'lastMonth':
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'customDate':
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };
  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    Navigate('/');
  };

  const handleCloseDt = () => setShowDt(false);
  const handleCloseCt = () => setShowCt(false);

  const handleShow = async item => {
    if (item.contentLangHi === true) {
      Navigate(`/addEditPoojaForm?pujaId=${item?.pujaId}&language=${type}`);
    } else if (item.contentLangEn === true) {
      Navigate(`/addEditPoojaForm?pujaId=${item?._id}&language=${type}`);
    } else {
      Navigate(`/addEditPoojaForm`);
    }
  };

  const handleShowClone = async (item, show) => {
    if (show === 'showHi') {
      Navigate(`/addEditPoojaForm?pujaId=${item?._id}&language=hindi`);
    } else {
      Navigate(`/addEditPoojaForm?pujaId=${item?.pujaId}&language=english`);
    }
  };

  // const handleStartDate = date => {
  //   const formattedDate = moment(date).format('MM-DD-YYYY');
  //   setStartDate(formattedDate);
  // };

  // const handleEndDate = date => {
  //   const formattedDate = moment(date).format('MM-DD-YYYY');
  //   setEndDate(formattedDate);
  // };

  // select pandit by id --

  const [dropdownData, setDropdownData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    titleDropdown();
  }, []);

  const titleDropdown = async () => {
    const response = await APIServices.get(`pandit/getPanditList`, headers);
    if (response?.status === 200) {
      setDropdownData(response?.data?.Listdata);
    }
  };

  const getPujaBookingTotalPrice = async (id) => {
    let pujaBookingTotal = await APIServices.get(`astroPooja/pujaBookingTotalPrice/${id}`, headers);
    if (pujaBookingTotal?.status === 200) {
      if (pujaBookingTotal?.data?.totalPriceOfPujaBookings) {
        setTotalBookingPrice(pujaBookingTotal?.data?.totalPriceOfPujaBookings)
      }else{
        console.log("Somthing went wrong");
        
      }
    }
  };
  const handleSelectChange = selected => {
    setSelectedOption(selected);
  };

  const options = dropdownData.map(item => ({
    value: item._id,
    label: item.name,
  }));

  const handlePujaDate = date => {
    const formattedDate = moment(date).format('MM-DD-YYYY');
    setPujaDate(formattedDate);
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Puja Image',
      Cell: e => {
        return (
          <img
            src={`${e.row.original.posterImage ? e.row.original.posterImage : bimg}`}
            alt='img'
            className='quotes_table_preview_image'
          />
        );
      },
    },
    {
      Header: 'Puja Name',
      Cell: e => {
        return <span>{e.row.original.title ?? '_'}</span>;
      },
    },
    {
      Header: 'Address',
      Cell: e => {
        return <span>{e.row.original.address ?? '_'}</span>;
      },
    },
    {
      Header: 'Puja Date',
      Cell: e => {
        return (
          <>
            <span>
              {e.row.original.pujaDate ? moment(e.row.original.pujaDate).format('DD MMM, YYYY hh:mm A') : '_'}
            </span>
          </>
        );
      },
    },
    {
      Header: 'Manage Puja',
      Cell: e => {
        return (
          <>
            <h5>
              <input
                type='button'
                disabled={!permissioncontext.view}
                className={!permissioncontext.view?"Et-btn  disabled-btn":'Et-btn '}
                value='Manage Puja'
                onClick={() => {
                  e.row.original.contentLangHi === true
                    ? Navigate(`/shared-puja?pujaId=${e.row.original?.pujaId}`, {
                      state: { permissioncontext },
                    })
                    : Navigate(`/shared-puja?pujaId=${e.row.original?._id}`,{
                      state: { permissioncontext },
                    });
                }}
              />
            </h5>
          </>
        );
      },
    },
    {
      Header: 'Expenses',
      Cell: e => {
        return (
          <>
          {(e.row.original?.pujaExpencese || e.row.original?.pujaPandit )? (
             <h5>
                <input
                  type='button'
                  disabled={!permissioncontext.view}
                  className={!permissioncontext.view?"Et-btn notes-btn  disabled-btn":'Et-btn notes-btn '}
                  value='View Expense'
                  onClick={() => {
                    setExpensesData(e.row.original);
                    setExpensesModal(true);
                    getPujaBookingTotalPrice(e.row.original._id)
                  }}
                />
          </h5>
          ):"_"}
          </>
        );
      },
    },
    {
      Header: 'Action',
      Cell: e => {
        if (e.row.original.status === true) {
          return (
            <h5>
              <input type='button' className={!permissioncontext.edit?"_active  disabled-btn":'_active '} disabled={!permissioncontext.edit} value='Active' onClick={() => handleclick(e.row.original)} />
            </h5>
          );
        } else {
          return (
            <h5>
              <input
                type='button'
                disabled
                className={"_inactive  disabled-btn"}
                value='Inactive'
                onClick={() => handleclick(e.row.original)}
              />
            </h5>
          );
        }
      },
    },
    // {
    //   Header: 'Pin',
    //   Cell: (e) => {
    //     if (e.row.original.isPin === true) {
    //     return  (<span><BsPinFill
    //         size={20}
    //         title="Pin"
    //         onClick={() => {
    //         handlePin(e.row.original);
    //     }}
    //         /></span>)
    //     }else{
    //     return ( <span> <BsPin
    //         size={20}
    //         title="Pin"
    //         onClick={() => {
    //          handlePin(e.row.original);
    //    }}
    //      />
    //       </span>
    //     )
    //   }
    // }
    // },
    {
      Header: ` Create Clone `,
      Cell: e => {
        if (e.row.original.status === false && e.row.original.contentLangEn === true) {
          return (
            <span>
              <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-edit'>Clone</Tooltip>}>
                <FaCopy
                  className={permissioncontext.add?'me-1 hd-cr':'me-1 hd-cr disabled-btn'}
                  title='Clone'
                  size={20}
                  onClick={() => {
                    if(permissioncontext.add){

                      handleCloneCt(e.row.original?._id);
                    }
                  }}
                />
              </OverlayTrigger>
            </span>
          );
        } else {
          return <span className='ms-4'>{'_'}</span>;
        }
      },
    },
    {
      Header: `Clone`,
      Cell: e => {
        if (
          e.row.original.contentLangEn === true &&
          e.row.original.status === true &&
          e.row.original.isClone === false
        ) {
          return (
            <span>
              <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-edit'>Clone</Tooltip>}>
                <FaCopy
                  title='Hindi Clone'
                  className={permissioncontext.edit?'me-1 hd-cr':'me-1 hd-cr disabled-btn'}
                  style={{ color: 'red' }}
                  size={20}
                  onClick={() => {
                    if(permissioncontext.edit){
                    handleCloneHindi(e.row.original?._id);
                    }
                  }}
                />
              </OverlayTrigger>
            </span>
          );
        } else {
          return (
            <h5>
              <input
                type='button'
                className={permissioncontext.add?'Et-btn':'Et-btn disabled-btn'}
                style={{ backgroundColor: '#059fff' }}
                value={`Edit ${e.row.original.contentLangEn === true ? '(Hindi)' : '(English)'}`}
                onClick={() => {
                  if(permissioncontext.add){

                    handleShowClone(e.row.original, e.row.original.contentLangEn === true ? 'showHi' : 'showEn');
                  }
                }}
              />
            </h5>
          );
        }
      },
    },

    {
      Header: 'Edit',
      Cell: e => {
        if (e.row.original._id) {
          return (
            <h5>
              <input
                type='button'
                className={permissioncontext.edit?'Et-btn edit-btn1':'Et-btn edit-btn1 disabled-btn'}
                value='Edit'
                onClick={() => {
                  if(permissioncontext.edit){
                  handleShow(e.row.original);
                  }
                }}
              />
            </h5>
          );
        }
      },
    },
    {
      Header: 'Delete',
      Cell: e => {
        if (e.row.original._id) {
          return (
            <h5>
              <input type='button' className={permissioncontext.delete?'Dt-btn':'Dt-btn disabled-btn'} disabled={!permissioncontext.delete} value='Delete' onClick={() => handleShowDt(e.row.original)} />
            </h5>
          );
        }
      },
    },

    {
      Header: 'Created Date',
      Cell: e => {
        return <span>{moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A')}</span>;
      },
    },
    {
      Header: 'Updated Date',
      Cell: e => {
        return <span>{e.row.original ? moment(e.row.original?.updatedAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>;
      },
    },
  ]);
  const handleShowDt = item => {
    if (item?.contentLangEn === true) {
      setContentType('english');
    } else if (item?.contentLangHi === true) {
      setContentType('hindi');
    }
    setSelectedId(item?._id);
    setShowDt(true);
  };

  const fetchData = useCallback(
    async (offset, perPage, index) => {
      setLoading(true);
      try {
        let response;
        let selectedOptionId = selectedOption?.value === undefined ? '' : selectedOption?.value;
        if (type === 'english') {
          response = await APIServices.get(
            `astroPooja/getAll/${offset}/${perPage}?status=${status}&panditId=${selectedOptionId}&title=${name}&address=${address}&pujaDate=${pujaDate}&startDate=${
              startDate ? formateDate(startDate) : ''
            }&endDate=${endDate ? formateDate(endDate) : ''}`,
            headers
          );
        } else if (type === 'hindi') {
          response = await APIServices.get(
            `astroPooja/getAll/hi/${offset}/${perPage}?status=${status}&panditId=${selectedOptionId}&title=${name}&address=${address}&pujaDate=${pujaDate}&startDate=${
              startDate ? formateDate(startDate) : ''
            }&endDate=${endDate ? formateDate(endDate) : ''}`,
            headers
          );
        }
        let data = response?.response?.data;

        if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status) {
            setData(apiData?.Listdata);
            setPageCount(Math.ceil(apiData.totalCount / perPage));
            setAllDataCount(apiData.totalCount);
            setFlag(false);
            setLoading(false);
          }
        } else {
          if (data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setOffset(offset);
      setPerPage(perPage);
      setPageIndex(index);
    },
    [pageIndex, offset, status, type, name, address, pujaDate, startDate, endDate,selectedOption?.value]
  );

  const handlePageClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setOffset(newOffset);
  };

  async function updateFile(showToast) {
    let res = await APIServices.get(`astroPooja/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
          toast.success(apiData?.message);
        }
        fetchData(offset, perPage, pageIndex, status);
      }
    }
  }

  const handledelete = async () => {
    let formData = { languageType: contentType };
    let res = await APIServices.delete(`astroPooja/findByIdAndremove/${selectedId}`, headers, formData);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        setShowDt(false);
        updateFile();
      }
    }
  };

  const handleclick = async item => {
    const formData = new FormData();
    var datas = item?.status === true ? false : true;
    let id = item?._id;
    formData.append('status', datas);

    if (item?.contentLangEn === true) {
      formData.append('languageType', 'english');
    } else if (item?.contentLangHi === true) {
      formData.append('languageType', 'hindi');
    }

    await APIServices.put(`astroPooja/updateStatus/${id}`, formData, headers)
      .then(res => {
        updateFile();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handlePin = async item => {
    const formData = new FormData();
    var datas = item?.isPin === true ? false : true;
    let id = item?._id;
    formData.append('isPin', datas);

    if (item?.contentLangEn === true) {
      formData.append('languageType', 'english');
    } else if (item?.contentLangHi === true) {
      formData.append('languageType', 'hindi');
    }
    await APIServices.put(`astroPooja/updatePin/${id}`, formData, headers)
      .then(res => {
        updateFile();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleCloneCt = id => {
    setTitleCt('');
    setSlugCt('');
    setCloneId(id);
    setShowCt(true);
  };

  const handleClone = async () => {
    if (titlect === '') {
      toast.error('Please fill the field !!');
    } else {
      let payload = {
        title: titlect,
        slug: slugct,
        userId: astroUserId,
      };
      const res = await APIServices.post(`astroPooja/cloneById/${cloneId}`, payload, headers);
      if (!res || (res && res?.status !== 200)) {
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const data = res?.data;
      if (data && data.status && data.message) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowCt(false);
        updateFile();
      }
    }
  };

  const handleCloneHindi = async id => {
    const res = await APIServices.post(`astroPooja/hi/cloneById/${id}`, { userId: astroUserId }, headers);
    if (!res || (res && res.status !== 200)) {
      toast.error('Something went wrong. Try Again!!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const data = res?.data;
    if (data && data.status === false) {
      toast.warn(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (data && data.status && data.message) {
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      updateFile();
    }
  };
  const resetData = () => {
    setPageIndex(0);
    setType('english');
    setStatus('active');
    setPujaDate('');
    setAddress('');
    setOffset(0);
    setName('');
    setStartDate('');
    setEndDate('');
    setSelectedRange('');
    setSelectedOption('')
    setFlag(true);
  };

  useEffect(() => {
    fetchData(offset, perPage, pageIndex);
  }, [flag]);

  const handleFilterClick = () => {
    if (name || startDate || endDate || perPage || offset || pageIndex || type || status || pujaDate || address || selectedOption?.value) {
      fetchData(offset, perPage, 0, type, status, name, pujaDate, address, startDate, endDate,selectedOption?.value);
    }
  };

  return (
    <>
      <>
      {
        permissioncontext.view?
<div className='main aarti_section'>
          <>
            <div className='gita_quote_heading'>
              <div className='container'>
                <div className='overflow-x-auto'>
                  <div className='counter-wrapper d-flex align-items-center justify-content-between'>
                    <h4 className='hd-list-f left-q'>
                      {' '}
                      Puja <small>({allDataCount})</small>
                    </h4>
                    <div className='_btn'>
                      <Button
                        className='by-btn as_btn'
                        variant='primary'
                        disabled={!permissioncontext.add}
                        onClick={e => {
                          Navigate(`/chadhawaMethod`, {
                            state: { permissioncontext },
                          })
                        }}
                      >
                        Chadhawa Process
                      </Button>
                      <Button
                        className='by-btn as_btn'
                        variant='primary'
                        disabled={!permissioncontext.add}
                        onClick={e => {
                          Navigate(`/pujaPlanProcess`, {
                            state: { permissioncontext },
                          })
                        }}
                      >
                        Plan Process
                      </Button>
                      <Button
                        className='by-btn as_btn'
                        variant='primary'
                        disabled={!permissioncontext.add}
                        onClick={e => {
                          Navigate(`/pujaMethodMaster`, {
                            state: { permissioncontext },
                          })
                        }}
                      >
                        Puja Process
                      </Button>
                      <Button
                        className='by-btn as_btn'
                        variant='primary'
                        disabled={!permissioncontext.add}
                        onClick={e => {
                          Navigate(`/pujaPlanSelectionImgMaster`, {
                            state: { permissioncontext },
                          })
                        }}
                      >
                        Plan Image Selector
                      </Button>
                      <Button
                        className='by-btn as_btn'
                        variant='primary'
                        disabled={!permissioncontext.add}
                        onClick={e => {
                          Navigate(`/pujaTestimonial`, {
                            state: { permissioncontext },
                          })
                        }}
                      >
                        Testimonials
                      </Button>
                      <Button className='by-btn as_btn' disabled={!permissioncontext.add} variant='primary' onClick={() => updateFile(true)}>
                        Update File
                      </Button>
                      <Button
                        style={{ backgroundColor: 'green' }}
                        className='by-btn as_btn me-0'
                        variant='primary'
                        disabled={!permissioncontext.add}
                        onClick={handleShow}
                      >
                        <FaPlus style={{ marginRight: '5px' }} size={13} />
                        Puja
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='aarti_inner'>
              {/* <div className="container">
                <div className="row"> */}
              {/* <div className="col-md-4 col-lg-3">
                    <Sidebar />
                  </div> */}
              {/* <div className="col-md-8 col-lg-12"> */}
              {/* {loading ? (
                      <div className="center">
                        <Spinner animation="border" />
                      </div>
                    ) : ( */}
              <>
                {/* <div className="row">
                          <div className="container">
                            <div className="d-flex align-items-center" style={{ justifyContent: "flex-end" }}>
                              <h4 className="hd-list-f left-q"></h4>
                              <Form.Select
                                aria-label="categories"
                                className=" w-25 astroWatch-select-hover hd-cr right-q"
                                value={status}
                                onChange={(e) => { setStatus(e.target.value); setOffset(0) }}
                              >
                                <option value="all">All</option>
                                <option value="active" >Active</option>
                                <option value="inActive">InActive</option>
                                <option value="globel">Globel</option>

                              </Form.Select>
                              <Form.Select
                                aria-label="categories"
                                className=" w-25 astroWatch-select-hover hd-cr"
                                value={type}
                                onChange={(e) => { setType(e.target.value); setOffset(0) }}
                              >
                                <option value="english">English</option>
                                <option value="hindi">Hindi</option>

                              </Form.Select>
                            </div>
                          </div>
                        </div> */}

                {/* <div className="row mt-3">
                          {data?.map((item) => (
                            <div className="col-md-6 col-lg-4">
                              <div className="card mb-4" key={item?._id}>
                                <div className="card-header">
                                  <img

                                    src={
                                      item?.posterImage
                                        ? `${item?.posterImage}`
                                        : bimg
                                    }
                                    className="card-img-top"
                                    alt="img"
                                  />
                                  <div className="icon">
                                    <FaEdit
                                      className="me-1 hd-cr"
                                      size={20}
                                      title={"Edit"}
                                      onClick={(e) => {
                                        handleShow(item);
                                      }}
                                    />
                
                                    {type === "english" && item.status === true ? <>
                                      <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-edit'>Clone</Tooltip>}>
                                        <FaCopy
                                          className="me-1 hd-cr"
                                          title="Clone"
                                          size={20}
                                          onClick={(e) => {
                                            handleCloneCt(item?._id);
                                          }}
                                        />
                                      </OverlayTrigger>

                                      <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-edit'>Clone</Tooltip>}>
                                        <FaCopy
                                          className="me-1 hd-cr"
                                          title="Hindi Clone"
                                          style={{ color: "red" }}
                                          size={20}
                                          onClick={(e) => {
                                            handleCloneHindi(item?._id);
                                          }}
                                        />

                                      </OverlayTrigger>
                                    </> : ""}

                                    {item?.isPin === true ?
                                      <BsPinFill

                                        size={20}
                                        title="Pin"
                                        onClick={(e) => {
                                          handlePin(item);
                                        }}
                                      /> :
                                      <BsPin

                                        size={20}
                                        title="Pin"
                                        onClick={(e) => {
                                          handlePin(item);
                                        }}
                                      />
                                    }

                                    <FaTrash
                                      className="dt-btn me-1 hd-cr"
                                      size={20}
                                      title="Delete"
                                      onClick={(e) => {
                                        handleShowDt(item);
                                      }}
                                    />
                                    {item.status === true ? (
                                      <input
                                        type="button"
                                        className="_active me-1"
                                        style={{ fontSize: "8px", borderRadius: "15px" }}
                                        value="Active"
                                        onClick={() =>
                                          handleclick(item)
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="button"
                                        className="_inactive"
                                        style={{ fontSize: "8px", borderRadius: "15px" }}
                                        value="Inactive"
                                        onClick={() =>
                                          handleclick(item)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="card-body">
                                  <h5 className="card-title heading_18 mb-2" style={{ minHeight: "45px" }}>{item.title?.slice(0, 45)}</h5>
                                  <p className="card-text description">{item.content}

                                  </p>
                                  <p className="cal-dat description" style={{ minHeight: "40px" }}>{item.address}</p>
                                  <p className="cal-dat description">
                                    <span className="calicon">
                                      <i className="ri-calendar-line"></i>
                                    </span>
                                    {item.date}
                                  </p>
                                </div>
                                <div class="card-footer text-center">
                                  <Button
                                    className="btn-more as_btn"
                                    variant="primary"
                                    onClick={() => {
                                      item.contentLangHi === true ? Navigate(`/shared-puja?pujaId=${item?.pujaId}`) :
                                        Navigate(`/shared-puja?pujaId=${item?._id}`);

                                    }}
                                  >
                                    Manage Puja
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>  */}

                <div className='user-list-table'>
                  <div class='feed_area box-shadow br-6 p-20 my-3'>
                    <div class='chat_search_box lh-20 py-0 user-search'>
                      <div className='chat-search-field'>
                        <Form>
                          <Form.Group as={Row} className='mb-0 mb-lg-0' controlId=''>
                            <Col lg={3} md={6} className='mb-3'>
                                <Form.Label class='label-f-w-cd'>
                                  Select Pandit
                                </Form.Label>
                                  <Select
                                   className=' mt-2'
                                    classNamePrefix='react-select'
                                    options={options}
                                    value={selectedOption}
                                    onChange={handleSelectChange}
                                    placeholder='Search by Pandit...'
                                    isClearable
                                  />
                            </Col>
                            <Col lg={3} md={6} className='mb-3'>
                              <Form.Label class='label-f-w-cd '>Puja Name</Form.Label>

                              <Form.Control
                                className=' mt-2 form-ch'
                                name='name'
                                type='search'
                                placeholder='Search By Puja Name'
                                value={name}
                                onChange={e => {
                                  setName(e.target.value);
                                  setOffset(0);
                                }}
                              />
                            </Col>
                            <Col lg={3} md={6} className='mb-3'>
                              <Form.Label class='label-f-w-cd '>Address</Form.Label>

                              <Form.Control
                                className=' mt-2 form-ch'
                                name='address'
                                type='search'
                                placeholder='Search By Address'
                                value={address}
                                onChange={e => {
                                  setAddress(e.target.value);
                                  setOffset(0);
                                }}
                              />
                            </Col>
                            <Col lg={3} md={6} className='mb-3'>
                              <Form.Label class='label-f-w-cd'>Puja Date</Form.Label>
                              <DatePicker
                                placeholderText={'Select a puja date'}
                                className='form-control  form-ch fil-date-pick mt-2'
                                name='date'
                                selected={pujaDate ? new Date(pujaDate) : null}
                                onChange={date => {
                                  handlePujaDate(date);
                                  setOffset(0);
                                }}
                                dateFormat='yyyy-MM-dd'
                              />
                              {/* <Form.Control
                      className=" mt-2 form-ch"
                      name="pujadate"
                      type="date"
                      placeholder="pujadate"
                      value={moment(pujaDate).format('YYYY-MM-DD')}
                      onChange={(e) => {setPujaDate( moment(e.target.value).format('MM-DD-YYYY'));setOffset(0)}}
                    /> */}
                            </Col>
                            
                          </Form.Group>

                          <Form.Group as={Row} className='align-items-end' controlId=''>

                          <Col lg={3} md={6} className='mb-3'>
                              <Form.Label class='label-f-w-cd'>Language</Form.Label>

                              <Form.Select
                                aria-label='label-selected-menu'
                                className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                                value={type}
                                onChange={e => {
                                  setType(e.target.value);
                                  setOffset(0);
                                }}
                              >
                                <option value='english'>English</option>
                                <option value='hindi'>Hindi</option>
                              </Form.Select>
                            </Col>

                            <Col lg={3} md={6} className='mb-3'>
                              <Form.Label class='label-f-w-cd'> Status</Form.Label>

                              <Form.Select
                                aria-label='label-selected-menu'
                                className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                                value={status}
                                onChange={e => {
                                  setStatus(e.target.value);
                                  setOffset(0);
                                  setPageIndex(0);
                                }}
                              >
                                <option value=''>All</option>
                                <option value='active'>Active</option>
                                <option value='inActive'>InActive</option>
                                <option value='globel'>Globel</option>
                              </Form.Select>
                            </Col>

                            <Col lg={3} md={6} className='mb-3'>
                              <Form.Label class='label-f-w-cd'>Search By List Date</Form.Label>
                              <div className='date-range-wrapper mt-2'>
                                <div className='select-icon-wrapper'>
                                  <select
                                    className='select-range'
                                    value={selectedRange}
                                    onChange={e => handleDateRange(e.target.value)}
                                  >
                                    <option value=''>Select Date Range</option>
                                    <option value='today'>Today</option>
                                    <option value='yesterday'>Yesterday</option>
                                    <option value='thisWeek'>This Week</option>
                                    <option value='last7Days'>Last 7 Days</option>
                                    <option value='thisMonth'>This Month</option>
                                    <option value='lastMonth'>Last Month</option>
                                    <option value='customDate'>Custom Date</option>
                                  </select>
                                  <img src='/images/down-arrow.png' className='select-icons' />
                                </div>

                                {selectedRange === 'customDate' && (
                                  <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={new Date()}
                                    onChange={update => {
                                      const [start, end] = update;
                                      setStartDate(start);
                                      setEndDate(end);
                                    }}
                                    // isClearable={true}
                                    placeholderText='Select custom date range'
                                    dateFormat='yyyy-MM-dd'
                                  />
                                )}
                              </div>
                            </Col>

                            {/* <Col lg={3} md={6}>
                                    <Form.Label class='label-f-w-cd'>From Date</Form.Label>
                                    <DatePicker
                                      placeholderText={'From date'}
                                      className='form-control form-ch fil-date-pick mt-2'
                                      name='date'
                                      selected={startDate ? new Date(startDate) : null}
                                      onChange={date => {
                                        handleStartDate(date);
                                        setOffset(0);
                                      }}
                                      dateFormat='yyyy-MM-dd'
                                    />
                                  </Col> */}

                            {/* <Col lg={3} md={6}>
                                    <Form.Label class='label-f-w-cd'>To Date</Form.Label>
                                    <DatePicker
                                      placeholderText={'To date'}
                                      className='form-control form-ch fil-date-pick mt-2'
                                      name='date'
                                      selected={endDate ? new Date(endDate) : null}
                                      onChange={date => {
                                        handleEndDate(date);
                                        setOffset(0);
                                      }}
                                      dateFormat='yyyy-MM-dd'
                                    />
                                  </Col> */}

                            {/* <Col md={2}>
                <Form.Label class="label-f-w-cd  mt-2 "></Form.Label>

               <Form.Control
                 className="hd-cr mt-2 form-ch"
                 name="date"
                 type="date"
                 placeholder="date"
                 value={moment(endDate).format('YYYY-MM-DD')}
                 onChange={(e) => {setEndDate( moment(e.target.value).format('MM-DD-YYYY'));setOffset(0)}}
               />
               </Col> */}
                            <Col lg={3} md={6} className='mb-3'>
                              <Button
                                variant='secondary'
                                className='as_btn ms-0'
                                onClick={() => {
                                  handleFilterClick();
                                  setOffset(0);
                                }}
                              >
                                Filter
                              </Button>
                              <Button
                                variant='secondary'
                                className='as_btn reset-btn'
                                onClick={() => {
                                  resetData();
                                }}
                              >
                                Reset
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                {data && data.length > 0 ? (
                  <DynamicDataTableWithCount
                    columns={columns}
                    data={data}
                    totalCount={pageCount}
                    initialState={{
                      pageIndex: pageIndex,
                      pageSize: perPage,
                    }}
                    fetchData={fetchData}
                    forcePage={offset / perPage}
                  />
                ) : (
                  <h2 className='no_records'>Records not Available</h2>
                )}
              </>
              {/* )} */}
              {/* <div className="pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        // pageCount={pageCount}
                        pageCount={Math.ceil(allDataCount / perPage)}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={offset / perPage}

                      />
                    </div> */}
              {/* </div> */}
              {/* </div>
              </div> */}
            </div>
          </>
        </div>:
        <Notauthorized />
      }
        
      </>

      <Modal
        centered
        show={showdt}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        scrollable
        show={showct}
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseCt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Create title and slug for clone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='4'>
                Title
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='title'
                  defaultValue={titlect}
                  className='form-control '
                  onChange={e => {
                    setTitleCt(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label column sm='4'>
                Slug
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Slug...'
                  name='slug'
                  value={slugct}
                  className='form-control '
                  onChange={e => {
                    setSlugCt(e.target.value);
                  }}
                  onBlur={e => setSlugCt(SlugifyStringWithDash(e.target.value))}
                />
                <small>
                  If you enter slug it will create new puja with same content.<br></br> If not then cloned existing and
                  inactive this one.
                </small>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' className='as_btn' onClick={handleClone}>
            create
          </Button>
          <Button variant='secondary' className='as_btn reset-btn' onClick={handleCloseCt}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* expensses modal */}

      <Modal
        centered
        scrollable
        show={expensesModal}
        // size='xl'
        // size={`${pujaData?.pujaExpencese === null} ? 'xl : 'lg`}
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
         onHide={() => {
           setExpensesModal(false);
         }}
      >
        <Modal.Header className='modal-header-hd' closeButton>
           <Modal.Title>Puja Expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className=''>
                  <ol className='puja-expenses-wrapper'>
                    <li className='d-flex justify-content-between'>
                      <p className='my-1 fw-semibold'>Expenses</p>
                      <p className='mx-4  fw-semibold'>Price</p>
                    </li>
                    <hr/>
                    <li className='d-flex justify-content-between'>
                        <p className='my-1 fw-semibold'>{expensesData?.pujaExpencese?.panditName || expensesData?.pujaPandit?.name} (Pandit Name)</p>
                        <p className='mx-4'>₹{expensesData?.pujaExpencese?.panditPrice || expensesData?.pujaPandit?.price}.00</p>
                    </li>
                    {expensesData &&
                      expensesData?.pujaExpencese?.expenceseItem?.map((data, ind) => {
                        return (
                          <>
                            <li className='d-flex justify-content-between' key={data?._id}>
                              <div className='d-flex align-items-center'>
                                  {ind + 1}.
                                  <p className='my-1 ms-1'>{data?.name}</p>
                                </div>
                              <p className='mx-4'>₹{data?.price}.00</p>
                            </li>
                          </>
                        );
                      })}
                      
                      <hr/>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-semibold my-1'>Expenses Total Price</p>
                        {/* <p className='mx-4'> ₹{expensesData?.pujaExpencese?.totalPrice}.00</p> */}
                        <p className="mx-4">
                          ₹{expensesData?.pujaExpencese?.totalPrice 
                            ? `${expensesData.pujaExpencese.totalPrice}.00` 
                            : `${expensesData?.pujaPandit?.price}.00`}
                        </p>
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-semibold my-1'>Bookings Total Price</p>
                        <p className='mx-4'>₹{totalBookingPrice}.00</p>
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-between'>
                      <p className='fw-semibold my-1'>
                        {Number(totalBookingPrice) - Number(expensesData?.pujaExpencese?.totalPrice??expensesData?.pujaPandit?.price ) >= 0 
                          ? "Total Puja Profit" 
                          : "Total Puja Loss"}
                      </p>
                      <p className='mx-4'>
                        ₹{Math.abs(Number(totalBookingPrice) - Number(expensesData?.pujaExpencese?.totalPrice??expensesData?.pujaPandit?.price))}.00
                      </p>
                    </div>
                  </ol>
            </div>
        </Modal.Body>
        <Modal.Footer>
          
          <Button
            variant='secondary'
            className='reset-btn'
             onClick={() => {
               setExpensesModal(false);
             }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AstroPooja;
